import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IPatientRepository } from './Ipatient.repository';
import { ApiResponse, Patient, PatientRequest, SearchOptions, SearchResult } from './models';

@Injectable()
export class PatientRepository implements IPatientRepository {

  constructor(private httpClient: HttpClient) { }

  getPatient(patientGuid: string): Observable<ApiResponse<Patient>> {
    const params = new HttpParams().set('id', patientGuid);
    return this.httpClient.get<ApiResponse<Patient>>(environment.domainPatientAPI + "/api/patients/getpatient", { params: params });
  }

  deletePatient(patientGuid: string): Observable<ApiResponse<any>> {
    const params = new HttpParams().set('id', patientGuid);
    return this.httpClient.post<ApiResponse<any>>(environment.domainPatientAPI + "/api/patients/DeletePatient", null, { params: params });
  }

  savePatient(patientRequest: PatientRequest): Observable<ApiResponse<Patient>> {
    return this.httpClient.post<ApiResponse<Patient>>(environment.domainPatientAPI + "/api/patients/createpatient", patientRequest);
  }

  updatePatient(patient: Patient): Observable<ApiResponse<any>> {
    return this.httpClient.post<ApiResponse<Patient>>(environment.domainPatientAPI + "/api/patients/updatepatient", patient);
  }

  search(options: SearchOptions): Observable<ApiResponse<SearchResult>> {
    return this.httpClient.post<ApiResponse<SearchResult>>(environment.domainPatientAPI + "/api/patients/search", options);
  }
}
