<div class="drag-area" data-testid="SUBSYS2_ATTACHUPLOADER_BOX">
	<div class="wrapper-actions-panel">
		<i class="material-icons" title="Copy and paste">file_copy</i>
		<i class="material-icons ml-3 mr-3" title="Upload local file">image_search</i>
		<i class="material-icons" title="Drag and Drop">photo_size_select_large</i>
	</div>
	<div *ngIf="patientGuid; else elseBlock">
		<form action="" method="POST" *ngIf="isMsgVisible">
			<h2 data-testid="SUBSYS2_ATTACHUPLOADER_ENABLED_TXT">{{ labels.ATTACHMENT_UPLOADER_COMPONENT_DRAG_MSG }}</h2>
			<input data-testid="SUBSYS2_ATTACHUPLOADER_FILE_BTN" #fileInput type="file" multiple (change)="chooseFile($event)"
				[accept]="validMIMETypes" [disabled]="isUploadDisabled" [class.disabled]="isUploadDisabled">
			<button type="submit" [class.disabled]="isUploadDisabled">
				{{ labels.ATTACHMENT_UPLOADER_COMPONENT_CHOOSE_MSG }}
			</button>
		</form>
	</div>
	<ng-template #elseBlock>
		<h2 data-testid="SUBSYS2_ATTACHUPLOADER_DISABLED_TXT">
			{{ labels.ATTACHMENT_UPLOADER_COMPONENT_DISABLED_MSG }}
		</h2>
	</ng-template>
</div>