import { BridgeActionsBase, BridgeBase, CalibrationMode, RingCalibrationData, ViewType } from '@ortho-next/nextray-core';
import { RingModel, RingTypeEnum } from '@ortho-next/nextray-core/Models/RingModel';

export interface BridgeActions extends BridgeActionsBase {
	/**
	 * Sets the line as calibration tools during calibration workflow.
	 */
	calibByLine: never;
	/**
	 * Sets the circle as calibration tools during calibration workflow.
	 */
	calibByCircle: never;
	/**
	 * Sets the ellipse as calibration tools during calibration workflow.
	 */
	calibByEllipse: never;
	/**
	 * Ends calibration workflow.
	 */
	calibrate: number;
	/**
	 * Starts calibration workflow.
	 */
	startCalibration: never;
	/**
	 * Set circle calibration points.
	 */
	setCalibrationCircleFromPoints: { x: number, y: number }[];
	/**
	 * Set ring.
	 */
	setRing: { ringModel: RingModel, oppositeRingType: RingTypeEnum };
	/**
	 * Set ring calibration data (patient garage only).
	 */
	setRingCalibrationData: RingCalibrationData;
}

/**
 * Manages the communications between three.js and angular part.
 */
export class Bridge extends BridgeBase {
	//override
	public mapEvent<K extends keyof BridgeActions>(type: K, args: BridgeActions[K]): void {
		switch (type) {
			case "calibByLine":
				return this._context.handle("setCalibrationMode", CalibrationMode.line);
			case "calibByCircle":
				return this._context.handle("setCalibrationMode", CalibrationMode.circle);
			case "calibByEllipse":
				return this._context.handle("setCalibrationMode", CalibrationMode.ellipse);
			case "calibrate":
				return this._context.handle("calibrate", args as number);
			case "startCalibration":
				return this._context.handle("startCalibration", ViewType.AP);
			case "setRing":
				return this._context.handle("setRing", args as { ringModel: RingModel, oppositeRingType: RingTypeEnum });
			case "setRingCalibrationData":
				return this._context.handle("setRingCalibrationData", args as RingCalibrationData);
			case "setCalibrationCircleFromPoints":
				return this._context.handle("setCalibrationCircleFromPoints", args as { x: number, y: number }[]);
		}
		return super.mapEvent(type, args);
	}
}
