<div class="panel panel-attachment">

	<h2>{{labels.ATTACHMENT_COMPONENT_TITLE}}</h2>

	<attachment-uploader [patientGuid]="patientGuid" [isUploadDisabled]="isDisabled"></attachment-uploader>

	<label id="attachment-title">{{labels.ATTACHMENT_COMPONENT_SUBTITLE}}</label>

	<div data-testid="SUBSYS2_ATTACHLIST_BOX" class="attachment-list" cdkDropList id="listThumbnail"
		[cdkDropListConnectedTo]="dropListConnected" [cdkDropListData]="attachmentList"
		(cdkDropListDropped)="dropAttachment($event)" [cdkDropListDisabled]="isDragDisabled || isFooterVisible">

		<div class="thumbnail" *ngFor="let loadingImage of loadingList">
			<img class="logo-loading" [src]="loaderUrl" alt="loading" data-testid="SUBSYS2_ATTACHLIST_THUMB_LOADER_IMG" />
		</div>

		<div data-testid="SUBSYS2_ATTACHLIST_THUMB_BOX" class="thumbnail" *ngFor="let img of attachmentList; let i = index"
			cdkDrag [cdkDragData]="img" [class.disabled]="!img.compatible"
			[ngStyle]="{'background-image': 'url(' + img.url + ')'}">
			<div class="custom-control custom-checkbox" *ngIf="!img.preventDelete && !isDisabled">
				<input data-testid="SUBSYS2_ATTACHLIST_THUMB_SELECT_INPUT" type="checkbox" id="checkbox-attachment-{{ i }}"
					name="checkbox-attachment" class="custom-control-input" [(ngModel)]="img.selected"
					(change)="refreshNumberOfSelected()">
				<label class="custom-control-label" for="checkbox-attachment-{{ i }}"></label>
			</div>
			<!-- NOTA: dopo aver draggato l'immagine all'interno del box la classe "lock", devi aggiungere "active" -->
			<span data-testid="SUBSYS2_ATTACHLIST_THUMB_LOCK_ICON" class="material-icons lock"
				[ngClass]="{'active': img.preventDelete }">lock</span>
			<div data-testid="SUBSYS2_ATTACHLIST_THUMB_EDIT_BTN" class="icon-edit" data-toggle="modal"
				*ngIf="!img.preventDelete && !isDisabled" (click)="openEditWarning(img.id)">
				<i class="material-icons">edit</i>
			</div>
			<div data-testid="SUBSYS2_ATTACHLIST_THUMB_PREVIEW_BTN" class="icon-zoom" data-toggle="modal"
				(click)="openPreview(img.id)">
				<i class="material-icons">search</i>
			</div>

			<div class="thumbnail-overlay" [class.d-flex]="!img.compatible"></div>

			<div data-testid="SUBSYS2_ATTACHLIST_THUMB_INFO_BOX" class="thumbnail-info">
				<div>
					<div>{{img?.boneType | boneTypeLabel}}</div>
					<div>{{img?.boneSide}}</div>
					<div>{{img?.boneView | boneViewLabel:img?.boneType }}</div>
					<div class="small-info" *ngIf="img?.proxRingDesc">{{img?.proxRingDesc }}</div>
					<div class="small-info" *ngIf="img?.distalRingDesc">{{img?.distalRingDesc }}</div>
				</div>
			</div>

			<img class="img-attachment-dragged" [src]="img.url" *cdkDragPreview alt="dragged attachment" />
		</div>
	</div>

	<div data-testid="SUBSYS2_ATTACHLIST_FOOTER_BOX" id="attachment-footer" class="action-container"
		*ngIf="isFooterVisible">
		<div class="left-side">
			<span>{{ numberOfSelected }} {{labels.ATTACHMENT_COMPONENT_NUMBER_SELECTED}}</span>
			<br>
			<span class="help-text">{{labels.ATTACHMENT_COMPONENT_ACTION_MSG}}</span>
		</div>
		<div class="right-side">
			<a data-testid="SUBSYS2_ATTACHLIST_DELETE_BTN" href="" onclick="return false;" class="action"
				(click)="openDeleteWarning()">
				{{labels.ATTACHMENT_COMPONENT_DELETE_BTN}}
				<i class="material-icons">delete_outline</i>
			</a>
		</div>
	</div>

</div>