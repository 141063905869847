import { Component } from '@angular/core';
import { LanguageService } from '../../core';
import { CanvasService } from '../../services';
import { BaseComponent } from '../../shared';



/**
 * Component for image editing tools on image garage modal.
 */
@Component({
	selector: 'image-editing-tools',
	templateUrl: './image-editing-tools.component.html'
})
export class ImageEditingToolsComponent extends BaseComponent {

	constructor(
		private langSrv: LanguageService,
		private canvasSrv: CanvasService
	) {
		super(langSrv);
	}

	/**
	 * Start image calibration workflow.
	 */
	calibrate(): void {
		this.canvasSrv.isCalibrationVisible = true;
	}

}
