import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LanguageService } from '../../core';
import { ListService } from '../../services';
import { BaseComponent } from '../../shared';


/**
* Pagination component that handles result pages in patient and plan list
*/
@Component({
  selector: 'pagination',
  templateUrl: './pagination.component.html',
  styles: []
})
export class PaginationComponent extends BaseComponent implements OnInit, OnDestroy {

  page: number;
  pageSize: number;
  collectionSize: number;
  totalPages: number;

  searchResultSubscription$: Subscription;

  constructor(private langSrv: LanguageService, private listSrv: ListService) {
    super(langSrv);
  }

  ngOnInit() {
    this.searchResultSubscription$ = this.listSrv.getSearchResult().subscribe(list => {
      this.collectionSize = list ? list.count : 0;
      this.totalPages = this.pageSize ? Math.ceil(this.collectionSize / this.pageSize) : 1;
    });
    this.initPagination();
  }

  private initPagination() {
    this.page = this.listSrv.getPageNumber() + 1;
    this.pageSize = this.listSrv.getPageSize();
  }

  /**
  * Update list according the page number selected
  */
  changePageNumber(page: number) {
    this.page = page;
    this.listSrv.changePageNumber(page - 1);
  }

  /**
  * Update list according the page size selected
  */
  changePageSize() {
    this.page = 1;
    this.listSrv.changePageSize(this.pageSize);
  }


  ngOnDestroy() {
    if (this.searchResultSubscription$) {
      this.searchResultSubscription$.unsubscribe();
    }
  }

}
