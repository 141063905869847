import { ActionsBase, ContextBase, RingCalibrationData, State, StateTransitionData } from '@ortho-next/nextray-core';
import { RingModel, RingTypeEnum } from '@ortho-next/nextray-core/Models/RingModel';
import { Loading } from './Loading';

export enum StateTypes { // important: sorted by flow
	loading,
	new,
	calibrated,
	deformityAnalysis,
	EOC,
	templating,
	RPM,
	tlhex,
	tlhexSchedule
}

export interface Actions extends ActionsBase {
	setRing: { ringModel: RingModel, oppositeRingType: RingTypeEnum },
	/**
	 * Set ring calibration data (patient garage only).
	 */
	setRingCalibrationData: RingCalibrationData;
}

/**
 * Manages the communications between three.js and angular part.
 */
export class Context extends ContextBase {

	protected changeState(state: StateTransitionData): State {
		return state.type == StateTypes.loading ? new Loading(this) : this._state;
	}

	constructor() {
		super();
		this._state = new Loading(this);
	}

	public handle<K extends keyof Actions>(event: K, args?: Actions[K]): void {
		super.handle(event, args);
	}

	//override fix def
	public addEventListener<K extends keyof Actions>(type: K, listener: (event: any) => void): void {
		super.addEventListener(type, listener);
	}
	public hasEventListener<K extends keyof Actions>(type: K, listener: (event: any) => void): boolean {
		return super.hasEventListener(type, listener);
	}
	public removeEventListener<K extends keyof Actions>(type: K, listener: (event: any) => void): void {
		super.removeEventListener(type, listener);
	}
	public dispatchEvent<K extends keyof Actions>(event: { type: K } & { args: Actions[K] }): void {
		super.dispatchEvent(event);
	}
}
