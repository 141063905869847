/**
* Patient base model
*/
export interface PatientBase {
  id: string;
  gender: GenderEnum;
  number: string;
  initials: string;
  age?: number;
}

export enum GenderEnum {
  MALE = "M",
  FEMALE = "F",
  OTHER = "O"
}
