import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs';
import { environment } from '../../environments/environment';
import { LanguageService, UserLastSignIn } from '../core';
import { UserService } from '../services';
import { BaseComponent } from '../shared';


/**
* Component for dashboard page.
*/
@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html'
})
export class DashboardComponent extends BaseComponent implements OnInit {

	isArchiveVisible: boolean;
	isCalendarVisible: boolean;
	signin: UserLastSignIn;

	constructor(
		private langSrv: LanguageService,
		private router: Router,
		private userSrv: UserService
	) {
		super(langSrv);
	}

	ngOnInit(): void {
		this.userSrv.getCurrentUser().pipe(
			map(u => u.isMigratedTLHEX)
		).subscribe(res => this.isArchiveVisible = res);
		this.userSrv.getLastSignIn().subscribe({
			next: signin => this.signin = signin,
			error: (err: Error) => console.error(err.message)
		});
		this.userSrv.isTlhex().subscribe(res => this.isCalendarVisible = res);
	}

	/**
	* Navigate to patient creation page.
	*/
	createPatient(): void {
		this.router.navigateByUrl('/patient/new');
	}

	/**
	* Navigate to patient list.
	*/
	searchPatient(): void {
		this.router.navigateByUrl('/list/patients');
	}

	/**
	* Navigate to plan list.
	*/
	searchPlan(): void {
		this.router.navigateByUrl('/list/plans');
	}

	/**
	* Navigate to archived plan list.
	*/
	archive(): void {
		window.open(environment.tlhexArchiveSite, '_blank');
	}

}
