import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AnatomicalSideEnum, ApiResponse, ApiResponseStatusCode, BoneTypeEnum, convertToLocalDate, Plan, PlanRepository, PlanRequest, PlanTypeEnum, ProductFilter, revertJsonDate } from '../core';
import { AnatomicalSiteEnum, PlanForm } from '../models';


/**
 * This service handles plan data
 */
@Injectable()
export class PlanService {

	constructor(private planRepo: PlanRepository) { }


	/**
	* Map plan request from plan form
	*/
	createPlanRequest(form: PlanForm): PlanRequest {
		return {
			patientGuid: form.patientGuid,
			number: form.planId,
			type: form.planType,
			side: form.side,
			boneType: form.boneType,
			referenceType: form.referenceType,
			isPostOperative: form.postOperative,
			surgeryDate: form.surgeryDate,
			notes: form.notes ? encodeURI(form.notes) : null,
			apImageGuid: form.apImageGuid,
			ltImageGuid: form.ltImageGuid,
			hasIWrench: form.hasIwrench
		};
	}

	/**
	* Map plan form from plan data
	*/
	createPlanForm(plan: Plan): PlanForm {
		return {
			patientGuid: plan.patientId,
			planId: plan.number,
			anatomicalSite: getAnatomicalSite(plan.side, plan.boneType),
			boneType: plan.boneType,
			side: plan.side,
			planType: plan.type,
			referenceType: plan.referenceType,
			postOperative: plan.isPostOperative,
			surgeryDate: revertJsonDate(plan.surgeryDate),
			surgeryDateFormat: convertToLocalDate(revertJsonDate(plan.surgeryDate)),
			notes: plan.notes ? decodeURI(plan.notes) : null,
			apImageGuid: plan.apImageGuid,
			ltImageGuid: plan.ltImageGuid,
			hasIwrench: plan.hasIWrench
		};
	}

	/**
	* Map plan model from plan form
	*/
	updatePlanModel(plan: Plan, planForm: PlanForm): Plan {
		return {
			...plan, ...{
				number: planForm.planId,
				boneType: planForm.boneType,
				side: planForm.side,
				type: planForm.planType,
				referenceType: planForm.referenceType,
				isPostOperative: planForm.postOperative,
				surgeryDate: planForm.surgeryDate,
				notes: planForm.notes ? encodeURI(planForm.notes) : null,
				apImageGuid: planForm.apImageGuid,
				ltImageGuid: planForm.ltImageGuid,
				modelStatus: plan.modelStatus,
				hasIWrench: planForm.hasIwrench
			}
		};
	}

	/**
	* Get plan by plan guid
	* @param {string} planGuid The plan guid
	*/
	getPlan(planGuid: string): Observable<Plan> {
		return this.planRepo.getPlan(planGuid).pipe(
			map(res => this.handlePlanResponse(res))
		);
	}

	/**
	* Save plan by plan request
	*/
	savePlan(request: PlanRequest): Observable<Plan> {
		return this.planRepo.savePlan(request).pipe(
			map(res => this.handlePlanResponse(res)),
		);
	}

	/**
	* Save plan by plan form
	*/
	savePlanForm(planForm: PlanForm): Observable<Plan> {
		const planRequest: PlanRequest = this.createPlanRequest(planForm);
		return this.savePlan(planRequest);
	}

	/**
	* Update plan with plan form data
	*/
	updatePlan(plan: Plan, planForm: PlanForm): Observable<any> {
		const planToUpdate: Plan = this.updatePlanModel(plan, planForm);
		return this.planRepo.updatePlan(planToUpdate).pipe(
			map(res => this.handlePlanResponse(res)),
		);
	}

	/**
	* Delete plans by image GUID
	* @param {string} imageId Image GUID
	*/
	deletePlansByImageId(imageId: string): Observable<ApiResponse<any>> {
		return this.planRepo.deletePlansByImage(imageId);
	}

	/**
	* Delete plan by plan guid
	*/
	deletePlan(planGuid: string): Observable<any> {
		return this.planRepo.deletePlan(planGuid);
	}

	/**
	 * Get available products according to plan data
	 * @param planType Plan type
	 * @param boneType Bone type
	 * @param hasLatImage Flag for lateral image
	 * @param isPostOperative Flag for post operative plan
	 */
	getAvailableProducts(planType: PlanTypeEnum, boneType: BoneTypeEnum, hasAPImage: boolean, hasLatImage: boolean, isPostOperative: boolean): Observable<string[]> {
		const filter: ProductFilter = {
			planType: planType,
			boneType: boneType,
			hasAP: hasAPImage,
			hasLateral: hasLatImage,
			isPostOperative: isPostOperative
		}
		return this.planRepo.getAvailableProducts(filter).pipe(
			map(res => this.handleApiResponse(res))
		);
	}

	private handlePlanResponse(response: ApiResponse<Plan>) {
		switch (response.statusCode) {
			case ApiResponseStatusCode.Success: return response.result;
			case ApiResponseStatusCode.CaseNumberNotUnique: throw new Error("PlanNumberNotUnique");
			default: throw new Error("Generic error");
		}
	}

	private handleApiResponse<T>(response: ApiResponse<T>) {
		if (response.statusCode == ApiResponseStatusCode.Success) {
			return response.result;
		} else {
			throw new Error("Generic error");
		}
	}
}

/**
* Get anatomical site from anatomical side and bone type
*/
export function getAnatomicalSite(side: AnatomicalSideEnum, boneType: BoneTypeEnum): AnatomicalSiteEnum {
	if (side == AnatomicalSideEnum.Right) {
		switch (boneType) {
			case BoneTypeEnum.LongLeg: return AnatomicalSiteEnum.RightLongLeg;
			case BoneTypeEnum.Femur: return AnatomicalSiteEnum.RightFemur;
			case BoneTypeEnum.Tibia: return AnatomicalSiteEnum.RightTibia;
			case BoneTypeEnum.Ankle: return AnatomicalSiteEnum.RightAnkle;
			case BoneTypeEnum.Forefoot: return AnatomicalSiteEnum.RightForefoot;
			case BoneTypeEnum.Hindfoot: return AnatomicalSiteEnum.RightHindfoot;
			default: return null;
		}
	}
	if (side == AnatomicalSideEnum.Left) {
		switch (boneType) {
			case BoneTypeEnum.LongLeg: return AnatomicalSiteEnum.LeftLongLeg;
			case BoneTypeEnum.Femur: return AnatomicalSiteEnum.LeftFemur;
			case BoneTypeEnum.Tibia: return AnatomicalSiteEnum.LeftTibia;
			case BoneTypeEnum.Ankle: return AnatomicalSiteEnum.LeftAnkle;
			case BoneTypeEnum.Forefoot: return AnatomicalSiteEnum.LeftForefoot;
			case BoneTypeEnum.Hindfoot: return AnatomicalSiteEnum.LeftHindfoot;
			default: return null;
		}
	}
	return null;
}

/**
* Get anatomical side from anatomical site
*/
export function getAnatomicalSide(anatomaicalSite: AnatomicalSiteEnum): AnatomicalSideEnum {
	if (RIGHTS.includes(anatomaicalSite)) {
		return AnatomicalSideEnum.Right;
	} else if (LEFTS.includes(anatomaicalSite)) {
		return AnatomicalSideEnum.Left;
	}
	return null;
}

/**
* Get bone type from anatomical site
*/
export function getBoneType(anatomaicalSite: AnatomicalSiteEnum): BoneTypeEnum {
	if (LONGLEGS.includes(anatomaicalSite)) {
		return BoneTypeEnum.LongLeg;
	} else if (FEMURS.includes(anatomaicalSite)) {
		return BoneTypeEnum.Femur;
	} else if (TIBIAS.includes(anatomaicalSite)) {
		return BoneTypeEnum.Tibia;
	} else if (ANKLES.includes(anatomaicalSite)) {
		return BoneTypeEnum.Ankle;
	} else if (FOREFOOTS.includes(anatomaicalSite)) {
		return BoneTypeEnum.Forefoot;
	} else if (HINDFOOTS.includes(anatomaicalSite)) {
		return BoneTypeEnum.Hindfoot;
	}
	return null;
}

const RIGHTS = [
	AnatomicalSiteEnum.RightLongLeg,
	AnatomicalSiteEnum.RightFemur,
	AnatomicalSiteEnum.RightTibia,
	AnatomicalSiteEnum.RightAnkle,
	AnatomicalSiteEnum.RightForefoot,
	AnatomicalSiteEnum.RightHindfoot
];
const LEFTS = [
	AnatomicalSiteEnum.LeftLongLeg,
	AnatomicalSiteEnum.LeftFemur,
	AnatomicalSiteEnum.LeftTibia,
	AnatomicalSiteEnum.LeftAnkle,
	AnatomicalSiteEnum.LeftForefoot,
	AnatomicalSiteEnum.LeftHindfoot
];
const LONGLEGS = [AnatomicalSiteEnum.RightLongLeg, AnatomicalSiteEnum.LeftLongLeg];
const FEMURS = [AnatomicalSiteEnum.RightFemur, AnatomicalSiteEnum.LeftFemur];
const TIBIAS = [AnatomicalSiteEnum.RightTibia, AnatomicalSiteEnum.LeftTibia];
const ANKLES = [AnatomicalSiteEnum.RightAnkle, AnatomicalSiteEnum.LeftAnkle];
const FOREFOOTS = [AnatomicalSiteEnum.RightForefoot, AnatomicalSiteEnum.LeftForefoot];
const HINDFOOTS = [AnatomicalSiteEnum.RightHindfoot, AnatomicalSiteEnum.LeftHindfoot];