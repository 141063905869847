import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IPlanRepository } from './Iplan.repository';
import { ApiResponse, Plan, PlanRequest, ProductFilter } from './models';

@Injectable()
export class PlanRepository implements IPlanRepository {

	constructor(private httpClient: HttpClient) { }

	savePlan(planRequest: PlanRequest): Observable<ApiResponse<Plan>> {
		return this.httpClient.post<ApiResponse<Plan>>(environment.domainPatientAPI + "/api/cases/createcase", planRequest);
	}

	getPlan(planGuid: string): Observable<ApiResponse<Plan>> {
		const params = new HttpParams().set('id', planGuid);
		return this.httpClient.get<ApiResponse<Plan>>(environment.domainPatientAPI + "/api/cases/getcase", { params: params });
	}

	updatePlan(plan: Plan): Observable<ApiResponse<any>> {
		return this.httpClient.post<ApiResponse<Plan>>(environment.domainPatientAPI + "/api/cases/updatecase", plan);
	}

	deletePlan(planGuid: string): Observable<ApiResponse<any>> {
		const params = new HttpParams().set('id', planGuid);
		return this.httpClient.post<ApiResponse<any>>(environment.domainPatientAPI + "/api/cases/DeleteCase", null, { params: params });
	}

	deletePlansByImage(imageGuid: string): Observable<ApiResponse<any>> {
		const params = new HttpParams().set('imageGuid', imageGuid);
		return this.httpClient.post<ApiResponse<any>>(environment.domainPatientAPI + "/api/cases/deletecases", null, { params: params });
	}

	getAvailableProducts(filter: ProductFilter): Observable<ApiResponse<string[]>> {
		return this.httpClient.post<ApiResponse<string[]>>(environment.domainPatientAPI + "/api/cases/GetAvailableProducts", filter);
	}

}
