import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../environments/environment';
import { LanguageService } from '../../core';
import { Guide, GuideService, UserService } from '../../services';
import { BaseComponent, HelpComponent } from '../../shared';


/**
 * Horizontal bar on top
 */
@Component({
	selector: 'navbar',
	templateUrl: './navbar.component.html'
})
export class NavbarComponent extends BaseComponent implements OnInit {

	firstName: string;
	lastName: string;
	isPlatformDemo: boolean;
	isMyhexplan: boolean;

	guideList: Guide[] = [];

	readonly customerCareEmail = environment.customerCareEmail;
	readonly myhexplanUrl = environment.myhexplanSite;
	readonly eifuSiteUrl = environment.eifuSite;

	constructor(
		private langSrv: LanguageService,
		private userSrv: UserService,
		private guideSrv: GuideService,
		private modalSrv: NgbModal
	) {
		super(langSrv);
	}

	ngOnInit() {
		this.userSrv.hasToAcceptLegalDocs().subscribe(hasToAcceptLegalDocs => hasToAcceptLegalDocs && (window.location.href = environment.legalTermsAcceptSite));
		this.userSrv.getUserName().subscribe(name => { this.firstName = name.firstName; this.lastName = name.lastName });
		this.userSrv.isPlatformDemo().subscribe(res => this.isPlatformDemo = res);
		this.userSrv.isMyhexplan().subscribe(res => this.isMyhexplan = res);
	}

	/**
	 * Get Guide list when open dropdown the first time.
	 */
	getGuideList(open: boolean): void {
		if (!!open && (!this.guideList || this.guideList.length === 0)) {
			this.guideSrv.getGuideList().subscribe(list => this.guideList = list);
		}
	}

	/**
	 * Open guide page.
	 */
	openGuide(path: string): void {
		const guideUrl = this.guideSrv.getGuideUrl(path);
		window.open(guideUrl, '_blank');
	}

	/**
	 * Open modal of help info.
	 */
	openHelpModal(): void {
		if (!this.modalSrv.hasOpenModals()) {
			this.modalSrv.open(HelpComponent, { backdropClass: 'wrapper-modal-help', windowClass: 'wrapper-modal-help' });
		}
	}

}
