import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { ApiResponse, ApiResponseStatusCode, CloneCase, ExpressionOperator, IntegrationRepository, PatientRepository, SearchFields, SearchItems, SearchOptions } from '../core';
import { ReceivedCloneForm } from '../models';


/**
 * This Service handles received plan cloning.
 */
@Injectable()
export class ReceivedService {

	private _currentPatientList$: BehaviorSubject<SearchItems[]> = new BehaviorSubject<SearchItems[]>([]);
	private _initialPatientList: any[] = [];

	constructor(
		private patRepo: PatientRepository,
		private integrationRepo: IntegrationRepository
	) { }

	/**
	* Get current patient list for received clone form.
	*/
	get patientList(): Observable<SearchItems[]> {
		return this._currentPatientList$.asObservable();
	}

	/**
	* Load initial patient list for received clone form.
	*/
	loadPatientList(): void {
		const options: SearchOptions = {
			top: 0,
			page: 0,
			patientOnly: true,
			deleted: false,
			sortOrder: { name: SearchFields.patientId, desc: false },
			filters: [{ name: SearchFields.patIsReceived, operator: ExpressionOperator.Ne, value: true }]
		}
		this.patRepo.search(options).pipe(
			map(res => res.result),
			filter(res => !!res),
			map(res => res.items)
		).subscribe(list => {
			this._initialPatientList = list;
			this._currentPatientList$.next([...this._initialPatientList]);
		});
	}

	/**
	* Search for the patient on text changes
	*/
	filterPatients = (text$: Observable<string>) =>
		text$.pipe(
			debounceTime(200),
			distinctUntilChanged(),
			map(term => this._initialPatientList.filter(v => v.patientNumber.toLowerCase().includes(term.toLocaleLowerCase())))
		);

	/**
	* Get cloned plan list by original plan guid.
	*/
	getClonedList(originalPlanGuid: string): Observable<SearchItems[]> {
		if (!originalPlanGuid) return of([]);
		const options: SearchOptions = {
			top: 0,
			page: 0,
			patientOnly: false,
			deleted: false,
			sortOrder: { name: SearchFields.patNumber, desc: false },
			filters: [
				{ name: SearchFields.originalCaseGuid, operator: ExpressionOperator.Eq, value: originalPlanGuid },
				{ name: SearchFields.planIsReceived, operator: ExpressionOperator.Ne, value: true }
			]
		}
		return this.patRepo.search(options).pipe(
			map(res => res.result),
			filter(res => !!res),
			map(res => res.items),
		);
	}

	/**
	* Clone received plan.
	* @param {CloneCase} planToClone plan data to clone
	* @returns Operation success
	*/
	private clonePlan(planToClone: CloneCase): Observable<void> {
		return this.integrationRepo.cloneSendCase(planToClone).pipe(
			map(res => this.handleCloneResponse(res))
		);
	}

	/**
	* Clone received plan by form.
	* @param {ReceivedCloneForm} cloneForm Form of plan to clone
	* @returns Operation success
	*/
	sendCloneForm(cloneForm: ReceivedCloneForm): Observable<void> {
		const planToClone: CloneCase = this.clonePlanMapper(cloneForm);
		if (planToClone) {
			return this.clonePlan(planToClone);
		}
		return throwError(() => new Error('Plan to clone is null'));
	}

	private clonePlanMapper = (cloneForm: ReceivedCloneForm): CloneCase => {
		if (!cloneForm) return null;
		return {
			patientId: cloneForm.existingPatient ? cloneForm.existingPatient.patientId : null,
			patientNumber: !cloneForm.existingPatient ? cloneForm.patientId : null,
			patientInitials: !cloneForm.existingPatient ? cloneForm.patientInitials : null,
			caseId: cloneForm.planGuid,
			caseNumber: cloneForm.planId,
			caseNote: cloneForm.planNotes
		}
	}

	private handleCloneResponse(response: ApiResponse<void>) {
		switch (response.statusCode) {
			case ApiResponseStatusCode.Success: return response.result;
			case ApiResponseStatusCode.PatientNumberNotUnique: throw new Error('PatientIdNotUnique');
			case ApiResponseStatusCode.CaseNumberNotUnique: throw new Error('PlanIdNotUnique');
			default: throw new Error("Generic error");
		}
	}

}
