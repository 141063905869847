<div class="">

	<div class="row">

		<div class="col-lg-6 d-flex">
			<span data-testid="SUBSYS2_RECEIVEDLIST_RESULTS_TXT" class="results">
				{{ collectionSize }} {{ labels.RECEIVED_TABLE_COMPONENT_RESULTS }}
			</span>
		</div>

	</div>

</div>

<div class="panel panel-thead">

	<div class="row no-gutters">

		<div data-testid="SUBSYS2_RECEIVEDLIST_HEADER_USERNAME" class="col"
			[ngClass]="{'active': activeColumn === fields.originalUserName }">
			<sortable-header [name]="fields.originalUserName" (sort)="onSort($event)">
				{{labels.RECEIVED_TABLE_COMPONENT_ORIGINAL_USERNAME}}
			</sortable-header>
		</div>
		<div data-testid="SUBSYS2_RECEIVEDLIST_HEADER_PLANID" class="col"
			[ngClass]="{'active': activeColumn === fields.planNumber }">
			<sortable-header [name]="fields.planNumber" (sort)="onSort($event)">
				{{labels.RECEIVED_TABLE_COMPONENT_PLAN_ID}}
			</sortable-header>
		</div>
		<div data-testid="SUBSYS2_RECEIVEDLIST_HEADER_CREATEDATE" class="col"
			[ngClass]="{'active': activeColumn === fields.planCreatedDate }">
			<sortable-header [name]="fields.planCreatedDate" (sort)="onSort($event)">
				{{labels.RECEIVED_TABLE_COMPONENT_PLAN_CREATION_DATE}}
			</sortable-header>
		</div>
		<div data-testid="SUBSYS2_RECEIVEDLIST_HEADER_ACTIONS" class="col no-link">
			{{labels.RECEIVED_TABLE_COMPONENT_ACTIONS}}
		</div>

	</div>

</div>

<!-- Single line -->
<div class="panel panel-tr" *ngFor="let plan of receivedList">

	<div class="row no-gutters">

		<div data-testid="SUBSYS2_RECEIVEDLIST_CELL_USERNAME" class="col">
			{{ plan.originalUserName }}
		</div>
		<div data-testid="SUBSYS2_RECEIVEDLIST_CELL_PLANID" class="col">
			{{ plan.planNumber | replaceLongLeg }}
		</div>
		<div data-testid="SUBSYS2_RECEIVEDLIST_CELL_CREATEDATE" class="col">
			{{ plan.caseCreationDate | convertDate }}
		</div>
		<div data-testid="SUBSYS2_RECEIVEDLIST_CELL_ACTIONS" class="col">
			<received-action [item]="plan"></received-action>
		</div>

	</div>

</div>


<!-- Pagination -->
<pagination></pagination>