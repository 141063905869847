import { Component } from '@angular/core';
import { ToastService } from './toast.service';


/**
* Toast component to display feedback messages
*/
@Component({
	selector: 'app-toast',
	templateUrl: './toast.component.html',
	host: { '[class.ngb-toasts]': 'true' }
})
export class ToastComponent {

	constructor(public toastService: ToastService) { }

}
