import { range, required } from '@rxweb/reactive-form-validators';
import { RingSize } from '../core';



/**
* Image calibration form model
*/
export class ImageCalibrationForm {

	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_CALIBRATION_TOOL" })
	calibrationTool: 'line' | 'circle' | 'rings';

	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_CALIBRATION_VALUE" })
	@range({ minimumNumber: 1, maximumNumber: 350, messageKey: "VALIDATION_MESSAGE_RANGE_CALIBRATION_VALUE" })
	calibrationValue: number;

	@required({ conditionalExpression: (x: ImageCalibrationForm) => x.calibrationTool == 'rings' })
	proximalRing?: RingSize;

	@required({ conditionalExpression: (x: ImageCalibrationForm) => x.calibrationTool == 'rings' })
	distalRing?: RingSize;
}
