<div class="panel panel-plan">
	<h2>{{ labels.PLAN_FORM_COMPONENT_FORM_TITLE }}</h2>

	<div class="row">
		<div class="col">

			<form [formGroup]="form" *ngIf="form">

				<div class="form-group">
					<label data-testid="SUBSYS2_PLANFORM_PLANID_LBL" for="PlanId">
						{{ labels.PLAN_FORM_COMPONENT_PLAN_ID }}&nbsp; *
					</label>
					<input data-testid="SUBSYS2_PLANFORM_PLANID_INPUT" type="text" class="form-control col-6 col-xl-12"
						id="PlanId" placeholder="{{ labels.PLAN_FORM_COMPONENT_PLAN_ID }}" formControlName="planId"
						(input)="resetPlanIdNotUnique()" (change)="trimPlanId()"
						[ngClass]="{'is-valid': formCtrl.planId.valid && formCtrl.planId.touched, 'is-invalid': formCtrl.planId.invalid && formCtrl.planId.touched}" />
					<div class="invalid-feedback" *ngIf="formCtrl.planId.invalid">
						{{ formCtrl.planId["errorMessage"] }}
					</div>
				</div>

				<div class="row">
					<div class="col-4 col-xl-12">
						<div class="form-group"
							[ngClass]="{'is-valid': formCtrl.postOperative.valid && formCtrl.postOperative.touched, 'is-invalid': formCtrl.postOperative.invalid && formCtrl.postOperative.touched}">
							<label data-testid="SUBSYS2_PLANFORM_PLANSTEP_LBL" for="postOperative">
								{{ labels.PLAN_FORM_COMPONENT_STEP }}&nbsp; *
							</label>
							<div class="custom-control custom-radio custom-control-inline">
								<input data-testid="SUBSYS2_PLANFORM_PREOP_RADIO" type="radio" id="stepPreOperative"
									name="postOperative" [value]="false" [attr.disabled]="isCheckUp ? true : null"
									class="custom-control-input" formControlName="postOperative" (change)="updateProductList()"
									[ngClass]="{'is-valid': formCtrl.postOperative.valid && formCtrl.postOperative.touched, 'is-invalid': formCtrl.postOperative.invalid && formCtrl.postOperative.touched}" />
								<label class="custom-control-label" for="stepPreOperative">
									{{ labels.PLAN_FORM_COMPONENT_STEP_PREOPERATIVE }} </label>
							</div>
							<div class="custom-control custom-radio custom-control-inline">
								<input data-testid="SUBSYS2_PLANFORM_POSTOP_RADIO" type="radio" id="stepPostOperative"
									name="postOperative" [value]="true" [attr.disabled]="isCheckUp ? true : null"
									class="custom-control-input" formControlName="postOperative" (change)="updateProductList()"
									[ngClass]="{'is-valid': formCtrl.postOperative.valid && formCtrl.postOperative.touched, 'is-invalid': formCtrl.postOperative.invalid && formCtrl.postOperative.touched}" />
								<label class="custom-control-label" for="stepPostOperative">
									{{ labels.PLAN_FORM_COMPONENT_STEP_POSTOPERATIVE }} </label>
							</div>
							<div class="invalid-feedback" *ngIf="formCtrl.postOperative.invalid">
								{{ formCtrl.postOperative["errorMessage"] }}</div>
						</div>
					</div>
					<div class="col-4 col-xl-12">
						<div class="form-group"
							[ngClass]="{'is-valid': formCtrl.planType.valid && formCtrl.planType.touched, 'is-invalid': formCtrl.planType.invalid && formCtrl.planType.touched}">
							<label data-testid="SUBSYS2_PLANFORM_PLANTYPE_LBL" for="planType">
								{{ labels.PLAN_FORM_COMPONENT_PLAN_TYPE }}
								&nbsp; *</label>
							<div class="custom-control custom-radio custom-control-inline">
								<input data-testid="SUBSYS2_PLANFORM_DEFORMITY_RADIO" type="radio" id="planType_DEF" name="planType"
									value="{{planTypeEnum.Deformity}}" [attr.disabled]="isCheckUp ? true : null"
									class="custom-control-input" formControlName="planType" (change)="updateProductList()"
									[ngClass]="{'is-valid': formCtrl.planType.valid && formCtrl.planType.touched, 'is-invalid': formCtrl.planType.invalid && formCtrl.planType.touched}" />
								<label class="custom-control-label" for="planType_DEF">
									{{ labels.PLAN_FORM_COMPONENT_TYPE_DEFORMITY }} </label>
							</div>
							<div class="custom-control custom-radio custom-control-inline">
								<input data-testid="SUBSYS2_PLANFORM_FRACTURE_RADIO" type="radio" id="planType_FRA" name="planType"
									value="{{planTypeEnum.Fracture}}" [attr.disabled]="isCheckUp ? true : null"
									class="custom-control-input" formControlName="planType" (change)="updateProductList()"
									[ngClass]="{'is-valid': formCtrl.planType.valid && formCtrl.planType.touched, 'is-invalid': formCtrl.planType.invalid && formCtrl.planType.touched}" />
								<label class="custom-control-label" for="planType_FRA">
									{{ labels.PLAN_FORM_COMPONENT_TYPE_FRACTURE }} </label>
							</div>
							<div class="invalid-feedback" *ngIf="formCtrl.planType.invalid">
								{{formCtrl.planType["errorMessage"]}}</div>
						</div>
					</div>
					<div class="col-4 col-xl-12">
						<div class="form-group"
							[ngClass]="{'is-valid': formCtrl.referenceType.valid && formCtrl.referenceType.touched, 'is-invalid': formCtrl.referenceType.invalid && formCtrl.referenceType.touched}">
							<label data-testid="SUBSYS2_PLANFORM_REFERENCETYPE_LBL" for="referenceType">
								{{ labels.PLAN_FORM_COMPONENT_REFERENCE_TYPE }} &nbsp; *</label>
							<div class="custom-control custom-radio custom-control-inline">
								<input data-testid="SUBSYS2_PLANFORM_PROXIMAL_RADIO" type="radio" id="referenceType_PROX"
									name="referenceType" value="{{referenceTypeEnum.Proximal}}" [attr.disabled]="isCheckUp ? true : null"
									class="custom-control-input" formControlName="referenceType"
									[ngClass]="{'is-valid': formCtrl.referenceType.valid && formCtrl.referenceType.touched, 'is-invalid': formCtrl.referenceType.invalid && formCtrl.referenceType.touched}" />
								<label class="custom-control-label" for="referenceType_PROX">
									{{ labels.PLAN_FORM_COMPONENT_REFERENCE_PROXIMAL }} </label>
							</div>
							<div class="custom-control custom-radio custom-control-inline">
								<input data-testid="SUBSYS2_PLANFORM_DISTAL_RADIO" type="radio" id="referenceType_DIST"
									name="referenceType" value="{{referenceTypeEnum.Distal}}" [attr.disabled]="isCheckUp ? true : null"
									class="custom-control-input" formControlName="referenceType"
									[ngClass]="{'is-valid': formCtrl.referenceType.valid && formCtrl.referenceType.touched, 'is-invalid': formCtrl.referenceType.invalid && formCtrl.referenceType.touched}" />
								<label class="custom-control-label" for="referenceType_DIST">
									{{ labels.PLAN_FORM_COMPONENT_REFERENCE_DISTAL }} </label>
							</div>
							<div class="invalid-feedback" *ngIf="formCtrl.referenceType.invalid">
								{{ formCtrl.referenceType["errorMessage"] }}</div>
						</div>
					</div>
				</div>

				<div class="form-group">
					<label data-testid="SUBSYS2_PLANFORM_SURGERYDATE_LBL" for="PlanSurgeryDate">
						{{ labels.PLAN_FORM_COMPONENT_SURGERY_DATE }}
					</label>
					<div class="input-group wrapper-calendar col-4 col-xl-12 p-0" #surgeryDateTarget
						[ngClass]="{'is-valid': formCtrl.surgeryDate.valid && formCtrl.surgeryDate.touched, 'is-invalid': formCtrl.surgeryDate.invalid && formCtrl.surgeryDate.touched }">
						<i data-testid="SUBSYS2_PLANFORM_SURGERYDATE_TOGGLE_BTN"
							class="material-icons input-group-append icon-calendar" (click)="surgeryDatePicker.toggle()">today</i>
						<input data-testid="SUBSYS2_PLANFORM_SURGERYDATE_INPUT" type="text" autocomplete="nope" class="form-control"
							id="PlanSurgeryDate" placeholder="{{ labels.PLAN_FORM_COMPONENT_SURGERY_DATE }}"
							[ngModel]="formValue.surgeryDateFormat" [ngModelOptions]="{standalone: true}"
							[ngClass]="{'is-valid': formCtrl.surgeryDate.valid && formCtrl.surgeryDate.touched, 'is-invalid': formCtrl.surgeryDate.invalid && formCtrl.surgeryDate.touched }"
							readonly style="color: #fff" />
						<i data-testid="SUBSYS2_PLANFORM_SURGERYDATE_CLEAR_BTN"
							class="material-icons input-group-append align-self-center md-18" (click)="clearSurgeryDate()">clear</i>
					</div>
					<div class="invalid-feedback" *ngIf="formCtrl.surgeryDate.invalid">
						{{ formCtrl.surgeryDate["errorMessage"] }}
					</div>
				</div>
				<datepicker data-testid="SUBSYS2_PLANFORM_SURGERYDATE_DATEPICKER" #surgeryDatePicker
					[value]="formValue.surgeryDate" (dateChanged)="updateSurgeryDate($event)" [positionTarget]="surgeryDateTarget"
					placement="bottom-right">
				</datepicker>

				<div class="form-group">
					<label data-testid="SUBSYS2_PLANFORM_NOTES_LBL" for="Notes">
						{{ labels.PLAN_FORM_COMPONENT_NOTES }}
					</label>
					<input data-testid="SUBSYS2_PLANFORM_NOTES_INPUT" type="text" class="form-control col-6 col-xl-12" id="Notes"
						placeholder="{{ labels.PLAN_FORM_COMPONENT_NOTES }}" formControlName="notes"
						[ngClass]="{'is-valid': formCtrl.notes.valid && formCtrl.notes.touched, 'is-invalid': formCtrl.notes.invalid && formCtrl.notes.touched}" />
					<div class="invalid-feedback" *ngIf="formCtrl.notes.invalid">
						{{ formCtrl.notes["errorMessage"] }}
					</div>
				</div>

			</form>

		</div>
	</div>

</div>