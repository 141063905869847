import { AfterViewInit, Component, ElementRef, Input, KeyValueDiffer, KeyValueDiffers } from '@angular/core';
import { Subject } from 'rxjs';

declare global {
	interface Window {
		jQuery: any;
	}
	interface JQuery {
		maphilight(options?: any): any;
		imageMapResize(): any;
	}
}

@Component({
	selector: 'maphilight',
	templateUrl: './maphilight.component.html'
})
export class MaphilightComponent implements AfterViewInit {
	@Input() config: any;

	private _configDiffer: KeyValueDiffer<any, any>;
	private _el: HTMLElement;
	private _img: HTMLImageElement;
	private _map: HTMLMapElement;
	events = {
		imgLoaded: new Subject<any>(),
		updateMaphilight: new Subject<any>(),
	}

	constructor(
		private elRef: ElementRef,
		differs: KeyValueDiffers
	) {
		this._el = this.elRef.nativeElement;
		this._configDiffer = differs.find({}).create();
	}

	ngAfterViewInit() {
		this._img = this._el.querySelector('img');
		this._map = this._el.querySelector('map');
		const id = this._el.getAttribute('id')
		this._img.setAttribute('usemap', '#' + id + ".map");
		this._map.setAttribute('id', id + ".map");
		this._map.setAttribute('name', id + ".map");

		// Wait until image is loaded. Otherwise the $(img).height() in maphilight.js may return 0,
		// which causes mouseover effect to break.
		// See also  https://stackoverflow.com/questions/16084374/jquery-width-and-height-return-0-for-img-element
		this._img.onload = () => {
			this.events.imgLoaded.next({});
			this._updateMaphilight();
		}
	}

	ngDoCheck() {
		const changes = this._configDiffer.diff(this.config)
		if (changes) {
			this._updateMaphilight()
		}
	}

	private _updateMaphilight() {
		if (!this._img) return;
		this.events.updateMaphilight.next(null);
		window.jQuery(this._map).imageMapResize();
		window.jQuery(this._img).maphilight(this._configToApply());
	};

	private _configToApply() {
		const config = Object.assign({}, this.config)
		if (config.strokeColor && config.strokeColor[0] == '#')
			config.strokeColor = config.strokeColor.substr(1)
		if (config.fillColor && config.fillColor[0] == '#')
			config.fillColor = config.fillColor.substr(1)
		if (config.shadowColor && config.shadowColor[0] == '#')
			config.shadowColor = config.shadowColor.substr(1)
		return config
	}

	/**
	 * Highlight area by id.
	 */
	highlight(id: string, status: boolean): void {
		const el = window.jQuery(document.getElementById(id));
		const data = el.mouseout().data('maphilight') || {};
		if (data.alwaysOn != status) {
			data.alwaysOn = status;
			el.data('maphilight', data).trigger('alwaysOn.maphilight');
		}
	}
}