<ngb-toast *ngFor="let toast of toastService.toasts"
           [class]="toast.className"
           [autohide]="true"
           [delay]="toast.delay ||5000"
           (hide)="toastService.remove(toast)">
  <div class="toast-text">
    <i class="material-icons" *ngIf="toast.className == 'bg-success'">done</i>
    <i class="material-icons" *ngIf="toast.className == 'bg-danger'">priority_high</i>
    <i class="material-icons" *ngIf="toast.className == 'bg-warning'">priority_high</i>
    {{toast.message}}
  </div>
</ngb-toast>
