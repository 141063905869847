<ngb-datepicker data-testid="SUBSYS2_DASHBOARD_CALENDAR_DATEPICKER" class="dashboard-calendar w-100"
	[dayTemplate]="customDay" [outsideDays]="'hidden'" [showWeekNumbers]="true" (navigate)="changeMonth($event)"
	(dateSelect)="changeDate($event)"></ngb-datepicker>

<ng-template #customDay let-date let-currentMonth="currentMonth" let-selected="selected" let-disabled="disabled"
	let-focused="focused">
	<div data-testid="SUBSYS2_DASHBOARD_CALENDAR_DAY" class="custom-day p-0" [class.selected]="focused">
		<div class="btn-light" [class.focused]="focused" [class.bg-primary]="focused"
			[class.text-muted]="date.month !== currentMonth">
			<strong>{{ date.day }}</strong>
		</div>
		<calendar-events [events]="eventsMap.get(ngbDateKey(date))"></calendar-events>
	</div>
</ng-template>