<!-- Modal -->
<div class="modal-content">
	<form [formGroup]="sendForm">
		<div class="modal-header">
			<h5 data-testid="SUBSYS2_SENDMODAL_TITLE_TXT" class="modal-title">
				{{ labels.PLAN_SEND_MODAL_COMPONENT_TITLE }}
			</h5>
			<!--Serve per mettere il focus sul modale ed evitare l'autofocus sul primo bottone-->
			<button type="button"
				style="border-color: transparent; background-color: transparent; outline: none; cursor: inherit;"></button>
		</div>
		<div class="modal-body">

			<div class="panel alert mb-3">
				<div class="state-icon"><i class="material-icons md-18">report_problem</i></div>
				<p data-testid="SUBSYS2_SENDMODAL_LIABILITY_TXT">{{ labels.PLAN_SEND_MODAL_COMPONENT_MESSAGE }}</p>
			</div>

			<div class="row">
				<div class="col-7">
					<div class="form-group pb-4">
						<label data-testid="SUBSYS2_SENDMODAL_PLANID_LBL" for="PlanId" class="d-inline-block">
							{{ labels.PLAN_SEND_MODAL_COMPONENT_PLAN_ID }}
						</label>
						<span data-testid="SUBSYS2_SENDMODAL_PLANID_TXT" class="ml-4">{{ planId }}</span>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-12">
					<div class="form-group">
						<label data-testid="SUBSYS2_SENDMODAL_USERNAME_LBL" for="UserName">
							{{ labels.PLAN_SEND_MODAL_COMPONENT_USERNAME }} * &nbsp;
							<span style="text-transform: none">{{ labels.PLAN_SEND_MODAL_COMPONENT_USERNAME_DESC }} </span>
						</label>
						<input data-testid="SUBSYS2_SENDMODAL_USERNAME_INPUT" type="text" autocomplete="nope"
							class="form-control col-7" id="UserName" formControlName="userName"
							placeholder="{{ labels.PLAN_SEND_MODAL_COMPONENT_USERNAME }}" (input)="resetUserNameValidation()"
							(change)="trimUserName()"
							[ngClass]="{'is-valid': sendForm.controls.userName.valid && sendForm.controls.userName.touched, 'is-invalid': sendForm.controls.userName.invalid && sendForm.controls.userName.touched}" />
						<div class="invalid-feedback" *ngIf="sendForm.controls.userName.invalid">
							{{sendForm.controls.userName["errorMessage"]}}</div>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-12">
					<div class="form-group">
						<label data-testid="SUBSYS2_SENDMODAL_NOTES_LBL" for="Notes">
							{{labels.PLAN_SEND_MODAL_COMPONENT_NOTES}}
						</label>
						<input data-testid="SUBSYS2_SENDMODAL_NOTES_INPUT" type="text" autocomplete="nope" class="form-control"
							id="Notes" formControlName="notes" placeholder="{{ labels.RECEIVED_CLONE_MODAL_COMPONENT_PLAN_NOTES }}"
							[ngClass]="{'is-valid': sendForm.controls.notes.valid && sendForm.controls.notes.touched, 'is-invalid': sendForm.controls.notes.invalid && sendForm.controls.notes.touched}" />
						<div class="invalid-feedback" *ngIf="sendForm.controls.notes.invalid">
							{{sendForm.controls.notes["errorMessage"]}}</div>
					</div>
				</div>
			</div>

		</div>
		<div class="modal-footer">
			<button data-testid="SUBSYS2_SENDMODAL_CANCEL_BTN" type="button" class="btn btn-secondary"
				(click)="activeModal.dismiss()">
				{{ labels.PLAN_SEND_MODAL_COMPONENT_CANCEL_BUTTON }}
			</button>
			<button data-testid="SUBSYS2_SENDMODAL_CONFIRM_BTN" type="button" class="btn btn-primary"
				[disabled]="!sendForm.valid || isSaving" (click)="confirm()">
				{{ labels.PLAN_SEND_MODAL_COMPONENT_CONFIRM_BUTTON }}
			</button>
		</div>
	</form>
</div>