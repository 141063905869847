<div class="box-menu-settings active">

	<div class="row-box pt-4">
		<h4 data-testid="SUBSYS2_CATEGORIZATION_TITLE_TXT">{{labels.IMAGE_CATEGORIZATION_FORM_COMPONENT_TITLE }}</h4>
	</div>

	<div class="divider-box"></div>

	<form [formGroup]="form" class="categorization-column">

		<div class="row-box mt-4">
			<div class="form-group pb-4">
				<label data-testid="SUBSYS2_CATEGORIZATION_BONE_LBL" for="boneType" class="mb-0">
					{{ labels.IMAGE_CATEGORIZATION_FORM_COMPONENT_BONE_TYPE }} &nbsp; *
				</label>
				<select data-testid="SUBSYS2_CATEGORIZATION_BONE_SELECT" class="form-control custom-select col-8" id="boneType"
					(change)="checkBoneCategorizValues()" autocomplete="nope" formControlName="boneType"
					[ngClass]="{'is-valid': form?.controls.boneType.valid && form?.controls.boneType.touched, 'is-invalid': form?.controls.boneType.invalid && form?.controls.boneType.touched}">
					<option [ngValue]="null">{{ labels.IMAGE_CATEGORIZATION_FORM_COMPONENT_BONE_TYPE_SELECT }}</option>
					<option *ngFor="let boneType of boneTypeList" [ngValue]="boneType">{{ boneType | boneTypeLabel }}</option>
				</select>
				<div class="invalid-feedback" *ngIf="form?.controls.boneType.invalid">
					{{ form?.controls.boneType["errorMessage"] }}
				</div>
			</div>
		</div>

		<div class="row-box">
			<div class="form-group pb-4"
				[ngClass]="{'is-valid': form?.controls.boneView.valid && form?.controls.boneView.touched, 'is-invalid': form?.controls.boneView.invalid && form?.controls.boneView.touched}">
				<label data-testid="SUBSYS2_CATEGORIZATION_VIEW_LBL" for="boneView" class="mb-0">
					{{ labels.IMAGE_CATEGORIZATION_FORM_COMPONENT_BONE_VIEW }} &nbsp; *
				</label>
				<div class="custom-control custom-radio custom-control-inline">
					<input data-testid="SUBSYS2_CATEGORIZATION_VIEW_AP_INPUT" type="radio" id="boneView_AP" name="boneView"
						(change)="checkBoneCategorizValues()" [value]="boneViewEnum.AP" class="custom-control-input"
						formControlName="boneView"
						[ngClass]="{'is-valid': form?.controls.boneView.valid && form?.controls.boneView.touched, 'is-invalid': form?.controls.boneView.invalid && form?.controls.boneView.touched}" />
					<label data-testid="SUBSYS2_CATEGORIZATION_VIEW_AP_LBL" class="custom-control-label" for="boneView_AP">
						{{apViewLabel}}
					</label>
				</div>
				<div class="custom-control custom-radio custom-control-inline">
					<input data-testid="SUBSYS2_CATEGORIZATION_VIEW_LT_INPUT" type="radio" id="boneView_LT" name="boneView"
						(change)="checkBoneCategorizValues()" [value]="boneViewEnum.LAT" class="custom-control-input"
						formControlName="boneView"
						[ngClass]="{'is-valid': form?.controls.boneView.valid && form?.controls.boneView.touched, 'is-invalid': form?.controls.boneView.invalid && form?.controls.boneView.touched}" />
					<label data-testid="SUBSYS2_CATEGORIZATION_VIEW_LT_LBL" class="custom-control-label" for="boneView_LT">
						{{labels.IMAGE_CATEGORIZATION_FORM_COMPONENT_BONE_VIEW_LT}} </label>
				</div>
				<div class="invalid-feedback" *ngIf="form?.controls.boneView.invalid">
					{{ form?.controls.boneView["errorMessage"] }}
				</div>
			</div>
		</div>

		<div class="row-box">
			<div class="form-group pb-4"
				[ngClass]="{'is-valid': form?.controls.boneSide.valid && form?.controls.boneSide.touched, 'is-invalid': form?.controls.boneSide.invalid && form?.controls.boneSide.touched}">
				<label data-testid="SUBSYS2_CATEGORIZATION_SIDE_LBL" for="boneSide" class="mb-0">
					{{ labels.IMAGE_CATEGORIZATION_FORM_COMPONENT_BONE_SIDE }} &nbsp; *
				</label>
				<div class="custom-control custom-radio custom-control-inline">
					<input data-testid="SUBSYS2_CATEGORIZATION_SIDE_LEFT_INPUT" type="radio" id="boneSide_L" name="boneSide"
						(change)="checkBoneCategorizValues()" [value]="boneSideEnum.Left" class="custom-control-input"
						formControlName="boneSide"
						[ngClass]="{'is-valid': form?.controls.boneSide.valid && form?.controls.boneSide.touched, 'is-invalid': form?.controls.boneSide.invalid && form?.controls.boneSide.touched}" />
					<label data-testid="SUBSYS2_CATEGORIZATION_SIDE_LEFT_LBL" class="custom-control-label" for="boneSide_L">
						{{labels.IMAGE_CATEGORIZATION_FORM_COMPONENT_BONE_SIDE_LEFT}} </label>
				</div>
				<div class="custom-control custom-radio custom-control-inline">
					<input data-testid="SUBSYS2_CATEGORIZATION_SIDE_RIGHT_INPUT" type="radio" id="boneSide_R" name="boneSide"
						(change)="checkBoneCategorizValues()" [value]="boneSideEnum.Right" class="custom-control-input"
						formControlName="boneSide"
						[ngClass]="{'is-valid': form?.controls.boneSide.valid && form?.controls.boneSide.touched, 'is-invalid': form?.controls.boneSide.invalid && form?.controls.boneSide.touched}" />
					<label data-testid="SUBSYS2_CATEGORIZATION_SIDE_RIGHT_LBL" class="custom-control-label" for="boneSide_R">
						{{labels.IMAGE_CATEGORIZATION_FORM_COMPONENT_BONE_SIDE_RIGHT}} </label>
				</div>
				<div class="invalid-feedback" *ngIf="form?.controls.boneSide.invalid">
					{{ form?.controls.boneSide["errorMessage"] }}
				</div>
			</div>
		</div>

		<div class="row-box" *ngIf="showOrientBox && form.value.orientation">
			<div class="w-100">
				<label data-testid="SUBSYS2_CATEGORIZATION_ORIENTATION_LBL" class="text-white">
					{{labels.IMAGE_CATEGORIZATION_FORM_COMPONENT_BONE_ORIENTATION}} &nbsp; *
				</label>
				<div class="box-suggestion">
					<p data-testid="SUBSYS2_CATEGORIZATION_ORIENTATION_INFO_TXT">
						{{labels.IMAGE_CATEGORIZATION_FORM_COMPONENT_BONE_ORIENTATION_QUESTION}}
					</p>
				</div>
				<div data-testid="SUBSYS2_CATEGORIZATION_ORIENTATION_INFO_BOX" class="box-img-setup">
					<div class="wrapper-img">
						<div data-testid="SUBSYS2_CATEGORIZATION_ORIENTATION_INFO_LEFT_LBL" class="btn-xray left">
							{{ orientLeftLabel }}</div>
						<div data-testid="SUBSYS2_CATEGORIZATION_ORIENTATION_INFO_TOP_LBL" class="btn-xray top"
							*ngIf="orientUpLabel">
							{{ orientUpLabel }}
						</div>
						<img [src]="imgOrientationSrc" alt="">
						<div data-testid="SUBSYS2_CATEGORIZATION_ORIENTATION_INFO_RIGHT_LBL" class="btn-xray right">
							{{ orientRightLabel }}
						</div>
						<div data-testid="SUBSYS2_CATEGORIZATION_ORIENTATION_INFO_BOTTOM_LBL" class="btn-xray bottom"
							*ngIf="orientDownLabel">
							{{ orientDownLabel }}
						</div>
					</div>
				</div>
				<div class="form-group pb-4">
					<div class="custom-control custom-radio custom-control-inline">
						<input data-testid="SUBSYS2_CATEGORIZATION_ORIENTATION_YES_INPUT" type="radio" id="yesOrientation"
							name="orientation" class="custom-control-input" (change)="changeOrient(false)"
							[checked]="!changeOrientation" required />
						<label data-testid="SUBSYS2_CATEGORIZATION_ORIENTATION_YES_LBL" class="custom-control-label"
							for="yesOrientation">
							{{labels.IMAGE_CATEGORIZATION_FORM_COMPONENT_BONE_ORIENTATION_YES}}
						</label>
					</div>
					<div class="custom-control custom-radio custom-control-inline">
						<input data-testid="SUBSYS2_CATEGORIZATION_ORIENTATION_NO_INPUT" type="radio" id="noOrientation"
							name="orientation" class="custom-control-input" (change)="changeOrient(true)"
							[checked]="changeOrientation" required />
						<label data-testid="SUBSYS2_CATEGORIZATION_ORIENTATION_NO_LBL" class="custom-control-label"
							for="noOrientation">
							{{labels.IMAGE_CATEGORIZATION_FORM_COMPONENT_BONE_ORIENTATION_NO}}
						</label>
					</div>
				</div>
			</div>
		</div>

	</form>

</div>