import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { ApiResponse, ApiResponseStatusCode, ExpressionOperator, FilterOption, LoaderService, PatientRepository, SearchFields, SearchOptions, SearchResult, SearchSortOptions } from '../core';


/**
 * This Service handles patient and plan list.
 */
@Injectable()
export class ListService {

	private _collapsed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

	private _searchResult: BehaviorSubject<SearchResult> = new BehaviorSubject<SearchResult>({ items: [], count: 0 });
	private _searchOptions: SearchOptions = {
		page: 0,
		top: 10,
		patientOnly: false,
		deleted: false,
		filters: []
	};

	constructor(
		private patRepo: PatientRepository,
		private loaderSrv: LoaderService
	) { }

	/**
	 * Update collapsed status of filters.
	 */
	collapse(val: boolean): void {
		this._collapsed.next(val);
	}

	/**
	 * Check if filters are collapsed.
	 */
	get isCollapsed(): Observable<boolean> {
		return this._collapsed.asObservable();
	}

	/**
	* Get current search result
	*/
	getSearchResult(): Observable<SearchResult> {
		return this._searchResult.asObservable();
	}

	/**
	* Set current search result
	*/
	setSearchResult(result: SearchResult): void {
		this._searchResult.next(result);
	}

	/**
	* Get current sort order option
	*/
	getSortOrder(): SearchSortOptions {
		return this._searchOptions.sortOrder;
	}

	/**
	* Get current page number option
	*/
	getPageNumber(): number {
		return this._searchOptions.page;
	}

	/**
	* Get current page size option
	*/
	getPageSize(): number {
		return this._searchOptions.top;
	}

	/**
	* Set single filter
	* @param {SearchFields} field Field of filter
	* @param {any} value Value of filter
	* @param {ExpressionOperator} operator Operator of filter
	*/
	setFilter(field: SearchFields, value: any, operator: ExpressionOperator) {
		const otherFilters = Object.assign([], this._searchOptions.filters.filter(filter => filter.name != field));
		if (value) {
			otherFilters.push({ name: field, operator: operator, value: value });
		}
		this._searchOptions.filters = otherFilters;
	}

	/**
	* Set range filter
	* @param {SearchFields} field Field of filter
	* @param {any} leftValue Value of left range
	* @param {ExpressionOperator} leftOperator Operator of left range
	* @param {any} rightValue Value of right range
	* @param {ExpressionOperator} rightOperator Operator of right range
	*/
	setRangeFilter(field: SearchFields, leftValue: any, leftOperator: ExpressionOperator, rightValue: any, rightOperator: ExpressionOperator) {
		const otherFilters = Object.assign([], this._searchOptions.filters.filter(filter => filter.name != field));
		if (leftValue || leftValue === 0) {
			otherFilters.push({ name: field, operator: leftOperator, value: leftValue });
		}
		if (rightValue || rightValue === 0) {
			otherFilters.push({ name: field, operator: rightOperator, value: rightValue });
		}
		this._searchOptions.filters = otherFilters;
	}

	/**
	* Set deleted flag option
	*/
	setDeletedFilter(value: boolean): void {
		this._searchOptions.deleted = value;
	}

	/**
	* Sort list
	* @param {string} column Column to sort
	* @param {string} direction order to sort, i.e asc or desc
	*/
	sort(column: string, direction: string) {
		if (direction == 'desc') {
			this._searchOptions.sortOrder = { name: column, desc: true };
		} else if (direction == 'asc') {
			this._searchOptions.sortOrder = { name: column, desc: false };
		} else {
			this._searchOptions.sortOrder = null;
		}
		this.search();
	}

	/**
	* Update list by page number
	*/
	changePageNumber(page: number) {
		this._searchOptions.page = page;
		this.search();
	}

	/**
	* Update list by page size
	*/
	changePageSize(size: number) {
		this._searchOptions.page = 0;
		this._searchOptions.top = size;
		this.search();
	}

	/**
	* Update list
	*/
	search(): void {
		this.loaderSrv.show();
		this.patRepo.search(this._searchOptions).pipe(
			finalize(() => this.loaderSrv.hide()),
			map(res => this.handleApiResponse(res))
		).subscribe(res => this.setSearchResult(res));
	}

	private handleApiResponse<T>(response: ApiResponse<T>) {
		if (response.statusCode == ApiResponseStatusCode.Success) {
			return response.result;
		} else {
			throw new Error("Generic error");
		}
	}

	/**
	* Reset patient list
	*/
	resetPatientSearch(): void {
		this.removeReceivedFilter();
		const excludeReceived: FilterOption = { name: SearchFields.patIsReceived, value: true, operator: ExpressionOperator.Ne };
		const currentFilters = Object.assign([], [...this._searchOptions.filters, excludeReceived]);
		const currentDeleted = this._searchOptions.deleted;
		this._searchOptions = {
			page: 0,
			top: 10,
			filters: currentFilters,
			deleted: currentDeleted,
			sortOrder: {
				name: SearchFields.patCreatedDate,
				desc: true
			},
			patientOnly: true
		};
		this.search();
	}

	/**
	* Reset plan list
	*/
	resetPlanSearch(): void {
		this.removeReceivedFilter();
		const excludeReceived: FilterOption = { name: SearchFields.planIsReceived, value: true, operator: ExpressionOperator.Ne };
		const currentFilters = Object.assign([], [...this._searchOptions.filters, excludeReceived]);
		const currentDeleted = this._searchOptions.deleted;
		this._searchOptions = {
			page: 0,
			top: 10,
			filters: currentFilters,
			deleted: currentDeleted,
			sortOrder: {
				name: SearchFields.planCreatedDate,
				desc: true
			},
			patientOnly: false
		};
		this.search();
	}

	/**
	* Reset received list
	*/
	resetReceivedSearch(): void {
		this.removeReceivedFilter();
		const onlyReceived: FilterOption = { name: SearchFields.planIsReceived, value: true, operator: ExpressionOperator.Eq };
		const currentFilters = Object.assign([], [...this._searchOptions.filters, onlyReceived]);
		const currentDeleted = this._searchOptions.deleted;
		this._searchOptions = {
			page: 0,
			top: 10,
			filters: currentFilters,
			deleted: currentDeleted,
			sortOrder: {
				name: SearchFields.planCreatedDate,
				desc: true
			},
			patientOnly: false
		};
		this.search();
	}

	/**
	* Reset filters option
	*/
	resetFilters(): void {
		const onlyReceived = this._searchOptions.filters.filter(f => f.name === SearchFields.patIsReceived || f.name === SearchFields.planIsReceived);
		this._searchOptions.filters = Object.assign([], [...onlyReceived]);
		this._searchOptions.deleted = null;
	}

	/**
	* Reset search result
	*/
	resetSearchResult(): void {
		this._searchResult.next({ items: [], count: 0 });
	}

	/**
	* Remove received filter
	*/
	private removeReceivedFilter(): void {
		if (this._searchOptions.filters && this._searchOptions.filters.length > 0) {
			const currentFilters = this._searchOptions.filters.filter(f => f.name !== SearchFields.patIsReceived && f.name !== SearchFields.planIsReceived);
			this._searchOptions.filters = Object.assign([], currentFilters);
		}
	}

}
