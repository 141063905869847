import { contains, email, maxLength, minLength, pattern, prop, required } from '@rxweb/reactive-form-validators';

const specialChars: RegExp = /^[^<>%#&?,]+$/;

/**
* Form model to send plan
*/
export class PlanSendForm {

	@required()
	userGuid: string;

	@required()
	planGuid: string;

	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_USERNAME" })
	@maxLength({ value: 128, messageKey: "VALIDATION_MESSAGE_MAXLENGTH_USERNAME" })
	@pattern({ expression: { 'noSpecialChars': specialChars }, messageKey: "VALIDATION_MESSAGE_SPECIALCHARS_USERNAME" })
	@email({ messageKey: "VALIDATION_MESSAGE_FORMAT_USERNAME" })
	@contains({ value: "%", messageKey: "VALIDATION_MESSAGE_NOT_EXIST_USERNAME", conditionalExpression: (x: PlanSendForm) => !!x.userNotExisting })
	@minLength({ value: 128, messageKey: "VALIDATION_MESSAGE_NOT_ELIGIBLE_USERNAME", conditionalExpression: (x: PlanSendForm) => !!x.userNotEligible })
	userName: string;

	@prop({ defaultValue: false })
	userNotExisting?: boolean;

	@prop({ defaultValue: false })
	userNotEligible?: boolean;

	@maxLength({ value: 1000, messageKey: "VALIDATION_MESSAGE_MAXLENGTH_PLAN_NOTES" })
	@pattern({ expression: { 'noSpecialChars': specialChars }, messageKey: "VALIDATION_MESSAGE_SPECIALCHARS_PLAN_NOTES" })
	notes: string;

}
