<div class="row row-contents">

	<div class="col-12">
		<ul class="nav nav-tabs nav-tabs-list" role="tablist" style="justify-content: flex-start">

			<h1 data-testid="SUBSYS2_LIST_TITLE_TXT" style="display: inline-flex;">{{labels.LIST_COMPONENT_TITLE}}</h1>
			<a data-testid="SUBSYS2_LIST_NEWPATIENT_BTN" [routerLink]="'/patient/new'" routerLinkActive="active"
				class="btn btn-primary btn-success" style="position: relative; margin: 0 10rem;">
				<i class="material-icons">add_circle_outline</i>{{labels.LIST_COMPONENT_NEW_PATIENT_BUTTON}}
			</a>

			<li>
				<a data-testid="SUBSYS2_LIST_PATIENTS_BTN" class="tab" id="tab-1-nav" [routerLink]="'/list/patients'"
					routerLinkActive="active" role="tab">
					{{ labels.LIST_COMPONENT_PATIENTS_TAB }}
				</a>
			</li>
			<li>
				<a data-testid="SUBSYS2_LIST_PLANS_BTN" class="tab" id="tab-2-nav" [routerLink]="'/list/plans'"
					routerLinkActive="active" role="tab">
					{{ labels.LIST_COMPONENT_PLANS_TAB }}
				</a>
			</li>
			<li>
				<a data-testid="SUBSYS2_LIST_RECEIVED_BTN" class="tab" id="tab-3-nav" [routerLink]="'/list/received'"
					routerLinkActive="active" role="tab">
					{{ labels.LIST_COMPONENT_RECEIVED_TAB }}
				</a>
			</li>
		</ul>
	</div>
	<div class="col-12 bg-tab-gradient"></div>

	<div class="col-12">
		<h2 data-testid="SUBSYS2_LIST_SEARCH_TOGGLE" class="search-toggle" [class.active]="!isFiltersCollapsed"
			(click)="isFiltersCollapsed = !isFiltersCollapsed">
			{{ labels.FILTERS_COMPONENT_TITLE }}
			<span class="material-icons ml-3">expand_more</span>
		</h2>
	</div>

</div>

<!-- Second row wrap all contents and columns -->
<div class="row row-contents">

	<div data-testid="SUBSYS2_LIST_SEARCH_BOX" class="search-sidebar order-lg-1 order-1"
		[ngClass]="{'col-lg-2': !isMobile, 'col-lg-3': isMobile }" [ngbCollapse]="isFiltersCollapsed"
		(hidden)="updateCollapsed(true)" (shown)="updateCollapsed(false)">

		<filters></filters>

	</div>

	<div class="order-lg-2 order-2 col-lg">
		<!-- Left column -->

		<router-outlet></router-outlet>

	</div>

</div>