import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable, tap } from 'rxjs';
import { environment } from '../../environments/environment';



/**
* This service handles help modal.
*/
@Injectable()
export class HelpService {

	private fileNameList: any;
	private _isLoaded: boolean;

	constructor(
		private http: HttpClient,
		private sanitizer: DomSanitizer
	) { }

	/**
	 * Check if help file name list is loaded
	 */
	get isLoaded(): boolean {
		return this._isLoaded;
	}

	/**
	 * Load help file name list
	 */
	loadFileNames(): Observable<any> {
		return this.http.get(environment.helpSite.fileNames).pipe(
			tap(list => {
				this.fileNameList = list;
				this._isLoaded = true;
			}));
	}

	/**
	 * Get safe url of help
	 */
	get safeUrl(): SafeUrl {
		const url = this.helpUrl;
		return url ? this.sanitizer.bypassSecurityTrustResourceUrl(url) : null;
	}

	private get helpUrl(): string {
		const fileName = this.fileNameList ? this.fileNameList[this.fileKey] : null;
		return fileName ? `${environment.helpSite.baseUrl}/${fileName}.html` : null;
	}

	private get fileKey(): string {
		const pathName = window.location.pathname;
		switch (pathName) {
			case '/dashboard': return 'DASHBOARD';

			case '/profile/edit': return 'PROFILE_EDIT';

			case '/patient/new': return 'PATIENT_CREATE_EDIT';
			case '/patient/edit': return 'PATIENT_CREATE_EDIT';

			case '/plan/new': return 'PLAN_CREATE_EDIT';
			case '/plan/edit': return 'PLAN_CREATE_EDIT';

			case '/list/patients': return 'PATIENT_PLAN_LIST';
			case '/list/plans': return 'PATIENT_PLAN_LIST';
			case '/list/received': return 'PATIENT_PLAN_LIST';

			default: return ''
		}
	}

}
