/**
* Dictionary model 
*/
export class Dictionary {
  name: string;
  values: Lookup[];
}

/**
* Model for dictionary values
*/
export class Lookup {
  id: number;
  value: string;
  otherInfo?: string;
}

export const DICTIONARY_NAME_USSTATE = 'USState';
