<!-- First row wrap Breadcrumb + Title + Alert -->
<div class="row row-header">
  <div class="col">

    <!-- Breadcrumbs -->
    <div class="breadcrumbs">
      <a href="#" onclick="return false;" (click)="returnToPreviousPage()"><i
          class="material-icons">arrow_back</i>{{ labels.PROFILE_FORM_COMPONENT_PREVIOUS_PAGE }}</a>
    </div>

    <!-- Page title -->
    <h1>{{ labels.PROFILE_FORM_COMPONENT_TITLE }} </h1>

  </div>
</div>

<!-- Second row wrap all contents and columns -->
<form [formGroup]="profileForm">

  <div class="row row-contents">

    <div class="col-lg-6">

      <p class="pb-5" innerHTML="{{ labels.PROFILE_FORM_COMPONENT_MSG }}"></p>

    </div>

    <div class="w-100"></div> <!-- This break the lines -->

    <div class="col-lg-3">
      <div class="form-group">
        <label for="FirstName">{{ labels.PROFILE_FORM_COMPONENT_FIRST_NAME }}</label>
        <small id="FirstName" class="help-text" style="font-size: 1.5rem">{{ profileForm.value.firstName }}</small>
      </div>
    </div>

    <div class="col-lg-3">
      <div class="form-group">
        <label for="LastName">{{ labels.PROFILE_FORM_COMPONENT_LAST_NAME }}</label>
        <small id="LastName" class="help-text" style="font-size: 1.5rem">{{ profileForm.value.lastName }}</small>
      </div>
    </div>

    <div class="w-100"></div> <!-- This break the lines -->

    <div class="col-lg-3">
      <div class="form-group">
        <label for="Email">{{ labels.PROFILE_FORM_COMPONENT_EMAIL }}</label>
        <small id="Email" class="help-text" style="font-size: 1.5rem">{{ profileForm.value.email }}</small>
      </div>
    </div>

    <div class="col-lg-3">
      <div class="form-group">
        <label for="RoleInfo">{{ labels.PROFILE_FORM_COMPONENT_ROLE_INFO }}</label>
        <small id="RoleInfo" class="help-text"
          style="font-size: 1.5rem">{{ isSurgeon ? labels.PROFILE_FORM_COMPONENT_HCP : profileForm.value.roleInfo }}</small>
      </div>
    </div>

    <div class="w-100"></div> <!-- This break the lines -->

    <div class="col-lg-3">
      <div class="form-group">
        <label for="Country">{{ labels.PROFILE_FORM_COMPONENT_COUNTRY }}</label>
        <small id="Country" class="help-text" style="font-size: 1.5rem">{{ profileForm.value.country?.name }}</small>
      </div>
    </div>

    <div class="col-lg-3">

      <div class="form-group">
        <label for="dateOfBirth">{{ labels.PROFILE_FORM_COMPONENT_DATE_OF_BIRTH }} *</label>
        <div class="input-group wrapper-calendar" #dateOfBirthTarget
          [ngClass]="{'is-valid': profileForm.controls.dateOfBirth.valid && profileForm.controls.dateOfBirth.touched, 'is-invalid': profileForm.controls.dateOfBirth.invalid && profileForm.controls.dateOfBirth.touched }">
          <i class="material-icons input-group-append icon-calendar" (click)="dateOfBirthPicker.toggle()">today</i>
          <input type="text" autocomplete="nope" class="form-control" id="dateOfBirth"
            placeholder="{{ labels.PROFILE_FORM_COMPONENT_DATE_OF_BIRTH }}" formControlName="dateOfBirthFormat"
            (focus)="profileForm.controls.dateOfBirth.markAsTouched()"
            [ngClass]="{'is-valid': profileForm.controls.dateOfBirth.valid && profileForm.controls.dateOfBirth.touched, 'is-invalid': profileForm.controls.dateOfBirth.invalid && profileForm.controls.dateOfBirth.touched }"
            readonly style="color: #fff" />
          <i class="material-icons input-group-append align-self-center md-18" (click)="clearDateOfBirth()">clear</i>
        </div>
        <div class="invalid-feedback" *ngIf="profileForm.controls.dateOfBirth.invalid">
          {{profileForm.controls.dateOfBirth["errorMessage"]}}</div>
      </div>
      <datepicker #dateOfBirthPicker [value]="profileForm.value.dateOfBirth" (dateChanged)="updateDateOfBirth($event)"
        [positionTarget]="dateOfBirthTarget" placement="bottom-right" [maxDate]="maxDateOfBirth">
      </datepicker>

    </div>

    <div class="w-100"></div> <!-- This break the lines -->

    <div class="col-lg-3" *ngIf="isSurgeon">
      <div class="form-group">
        <label for="Hospital">{{ labels.PROFILE_FORM_COMPONENT_HOSPITAL }} *</label>
        <input type="text" autocomplete="nope" class="form-control" id="Hospital"
          placeholder="{{ labels.PROFILE_FORM_COMPONENT_HOSPITAL }}" formControlName="hospital"
          [ngClass]="{'is-valid': profileForm.controls.hospital.valid && profileForm.controls.hospital.touched, 'is-invalid': profileForm.controls.hospital.invalid && profileForm.controls.hospital.touched}">
        <div class="invalid-feedback" *ngIf="profileForm.controls.hospital.invalid">
          {{profileForm.controls.hospital["errorMessage"]}}</div>
      </div>
    </div>

    <div class="col-lg-3" *ngIf="isSurgeon">
      <div class="form-group">
        <label for="Distributor">{{ labels.PROFILE_FORM_COMPONENT_DISTRIBUTOR }} *</label>
        <input type="text" autocomplete="nope" class="form-control" id="Distributor"
          placeholder="{{ labels.PROFILE_FORM_COMPONENT_DISTRIBUTOR }}" formControlName="distributor"
          [ngClass]="{'is-valid': profileForm.controls.distributor.valid && profileForm.controls.distributor.touched, 'is-invalid': profileForm.controls.distributor.invalid && profileForm.controls.distributor.touched}">
        <div class="invalid-feedback" *ngIf="profileForm.controls.distributor.invalid">
          {{profileForm.controls.distributor["errorMessage"]}}</div>
      </div>
    </div>

    <div class="col-lg-3" *ngIf="!isSurgeon">
      <div class="form-group">
        <label for="Company">{{ labels.PROFILE_FORM_COMPONENT_COMPANY }} *</label>
        <input type="text" autocomplete="nope" class="form-control" id="Company"
          placeholder="{{ labels.PROFILE_FORM_COMPONENT_COMPANY }}" formControlName="company"
          [ngClass]="{'is-valid': profileForm.controls.company.valid && profileForm.controls.company.touched, 'is-invalid': profileForm.controls.company.invalid && profileForm.controls.company.touched}">
        <div class="invalid-feedback" *ngIf="profileForm.controls.company.invalid">
          {{profileForm.controls.company["errorMessage"]}}</div>
      </div>
    </div>

    <div class="w-100"></div> <!-- This break the lines -->

    <div class="col-lg-3" *ngIf="!isUnitedStates">
      <div class="form-group">
        <label for="StateProvince">{{ labels.PROFILE_FORM_COMPONENT_STATE_PROVINCE }} *</label>
        <input type="text" autocomplete="nope" class="form-control" id="StateProvince"
          placeholder="{{ labels.PROFILE_FORM_COMPONENT_STATE_PROVINCE }}" formControlName="stateProvince"
          [ngClass]="{'is-valid': profileForm.controls.stateProvince.valid && profileForm.controls.stateProvince.touched, 'is-invalid': profileForm.controls.stateProvince.invalid && profileForm.controls.stateProvince.touched}">
        <div class="invalid-feedback" *ngIf="profileForm.controls.stateProvince.invalid">
          {{profileForm.controls.stateProvince["errorMessage"]}}</div>
      </div>
    </div>

    <div class="col-lg-3" *ngIf="isUnitedStates">
      <div class="form-group">
        <label for="USStateProvince">{{ labels.PROFILE_FORM_COMPONENT_STATE_PROVINCE }} *</label>
        <select class="form-control custom-select" id="USStateProvince" autocomplete="nope"
          formControlName="usStateProvince"
          [ngClass]="{'is-valid': profileForm.controls.usStateProvince.valid && profileForm.controls.usStateProvince.touched, 'is-invalid': profileForm.controls.usStateProvince.invalid && profileForm.controls.usStateProvince.touched}">
          <option [ngValue]="null">{{ labels.PROFILE_FORM_COMPONENT_STATE_PROVINCE_SELECT }}</option>
          <option *ngFor="let state of allUsStates" [ngValue]="state">{{ state }}</option>
        </select>
        <div class="invalid-feedback" *ngIf="profileForm.controls.usStateProvince.invalid">
          {{profileForm.controls.usStateProvince["errorMessage"]}}</div>
      </div>
    </div>

    <div class="col-lg-3">
      <div class="form-group">
        <label for="City">{{ labels.PROFILE_FORM_COMPONENT_CITY }} *</label>
        <input type="text" autocomplete="nope" class="form-control" id="City"
          placeholder="{{ labels.PROFILE_FORM_COMPONENT_CITY }}" formControlName="city"
          [ngClass]="{'is-valid': profileForm.controls.city.valid && profileForm.controls.city.touched, 'is-invalid': profileForm.controls.city.invalid && profileForm.controls.city.touched}">
        <div class="invalid-feedback" *ngIf="profileForm.controls.city.invalid">
          {{profileForm.controls.city["errorMessage"]}}</div>
      </div>
    </div>

    <div class="w-100"></div> <!-- This break the lines -->

    <div class="col-lg-3">
      <div class="form-group">
        <label for="Address">{{ labels.PROFILE_FORM_COMPONENT_ADDRESS }} *</label>
        <input type="text" autocomplete="nope" class="form-control" id="Address"
          placeholder="{{ labels.PROFILE_FORM_COMPONENT_ADDRESS }}" formControlName="address"
          [ngClass]="{'is-valid': profileForm.controls.address.valid && profileForm.controls.address.touched, 'is-invalid': profileForm.controls.address.invalid && profileForm.controls.address.touched}">
        <div class="invalid-feedback" *ngIf="profileForm.controls.address.invalid">
          {{profileForm.controls.address["errorMessage"]}}</div>
      </div>
    </div>

    <div class="col-lg-3">
      <div class="form-group">
        <label for="PostalCode">{{ labels.PROFILE_FORM_COMPONENT_POSTAL_CODE }} *</label>
        <input type="text" autocomplete="nope" class="form-control" id="PostalCode"
          placeholder="{{ labels.PROFILE_FORM_COMPONENT_POSTAL_CODE }}" formControlName="postalCode"
          [ngClass]="{'is-valid': profileForm.controls.postalCode.valid && profileForm.controls.postalCode.touched, 'is-invalid': profileForm.controls.postalCode.invalid && profileForm.controls.postalCode.touched}">
        <div class="invalid-feedback" *ngIf="profileForm.controls.postalCode.invalid">
          {{profileForm.controls.postalCode["errorMessage"]}}</div>
      </div>
    </div>

    <div class="w-100"></div> <!-- This break the lines -->

    <div class="col-lg-3">
      <div class="form-group">
        <label for="OfficePhone">{{ labels.PROFILE_FORM_COMPONENT_OFFICE_PHONE }} *</label>
        <input type="text" autocomplete="nope" class="form-control" id="OfficePhone"
          placeholder="{{ labels.PROFILE_FORM_COMPONENT_OFFICE_PHONE }}" formControlName="officePhone"
          [ngClass]="{'is-valid': profileForm.controls.officePhone.valid && profileForm.controls.officePhone.touched, 'is-invalid': profileForm.controls.officePhone.invalid && profileForm.controls.officePhone.touched}">
        <div class="invalid-feedback" *ngIf="profileForm.controls.officePhone.invalid">
          {{profileForm.controls.officePhone["errorMessage"]}}</div>
      </div>
    </div>

    <div class="col-lg-3">
      <div class="form-group">
        <label for="MobilePhone">{{ labels.PROFILE_FORM_COMPONENT_MOBILE }}</label>
        <input type="text" autocomplete="nope" class="form-control" id="MobilePhone"
          placeholder="{{ labels.PROFILE_FORM_COMPONENT_MOBILE }}" formControlName="mobilePhone"
          [ngClass]="{'is-valid': profileForm.controls.mobilePhone.valid && profileForm.controls.mobilePhone.touched, 'is-invalid': profileForm.controls.mobilePhone.invalid && profileForm.controls.mobilePhone.touched}">
        <div class="invalid-feedback" *ngIf="profileForm.controls.mobilePhone.invalid">
          {{profileForm.controls.mobilePhone["errorMessage"]}}</div>
      </div>
    </div>

    <div class="w-100"></div> <!-- This break the lines -->

    <ng-container *ngIf="profileForm.value.practice">

      <div class="col-lg-3">
        <div class="form-group">
          <label for="Practice">{{ labels.PROFILE_FORM_COMPONENT_PRACTICE }}</label>
          <small id="Practice" class="help-text" style="font-size: 1.5rem">{{ profileForm.value.practice.type }}</small>
        </div>
      </div>

      <div class="col-lg-3">
        <div class="form-group">
          <label for="Npi">{{ labels.PROFILE_FORM_COMPONENT_NPI }}</label>
          <small id="Npi" class="help-text" style="font-size: 1.5rem">{{ profileForm.value.practice.npi }}</small>
        </div>
      </div>

      <div class="w-100"></div> <!-- This break the lines -->

      <div class="col-lg-3">
        <div class="form-group">
          <label for="StateLicences">{{ labels.PROFILE_FORM_COMPONENT_STATE_LICENCES }}</label>
          <ng-container>
            <small class="help-text" style="font-size: 1.5rem">{{ profileForm.value.practice.l1StateCode }}
              {{profileForm.value.practice.l1Number}}</small><br />
          </ng-container>
          <ng-container *ngIf="profileForm.value.practice.l2StateCode">
            <small class="help-text" style="font-size: 1.5rem">{{ profileForm.value.practice.l2StateCode }} &nbsp;
              {{profileForm.value.practice.l2Number}}</small><br />
          </ng-container>
          <ng-container *ngIf="profileForm.value.practice.l3StateCode">
            <small class="help-text" style="font-size: 1.5rem">{{ profileForm.value.practice.l3StateCode }} &nbsp;
              {{profileForm.value.practice.l3Number}}</small><br />
          </ng-container>
          <ng-container *ngIf="profileForm.value.practice.l4StateCode">
            <small class="help-text" style="font-size: 1.5rem">{{ profileForm.value.practice.l4StateCode }} &nbsp;
              {{profileForm.value.practice.l4Number}}</small><br />
          </ng-container>
          <ng-container *ngIf="profileForm.value.practice.l5StateCode">
            <small class="help-text" style="font-size: 1.5rem">{{ profileForm.value.practice.l5StateCode }} &nbsp;
              {{profileForm.value.practice.l5Number}}</small>
          </ng-container>
        </div>
      </div>

      <div class="w-100"></div> <!-- This break the lines -->

    </ng-container>

    <div class="col-lg-6">
      <button type="button" class="btn btn-primary" [disabled]="!profileForm.valid || isSaving"
        (click)="openConfirmModal()">
        {{ labels.PROFILE_FORM_COMPONENT_SUBMIT_BTN }}
      </button>
    </div>


  </div>

</form>
