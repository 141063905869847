<form [formGroup]="patientForm">

	<div class="panel">

		<h2>{{ labels.PATIENT_CREATION_COMPONENT_FORM_TITLE }}</h2>

		<div class="panel alert">
			<div class="state-icon"><i class="material-icons md-18">report_problem</i></div>
			<p data-testid="SUBSYS2_PATFORM_LIABILITY_TXT">{{labels.PATIENT_CREATION_COMPONENT_LIABILITY_MESSAGE}}</p>
		</div>

		<div class="row">

			<div class="col-3">
				<div class="form-group">
					<label data-testid="SUBSYS2_PATFORM_PATID_LBL" for="patientId">
						{{ labels.PATIENT_CREATION_COMPONENT_PATIENT_ID }} *
					</label>
					<input data-testid="SUBSYS2_PATFORM_PATID_INPUT" type="text" class="form-control" id="patientId"
						placeholder="{{ labels.PATIENT_CREATION_COMPONENT_PATIENT_ID }}" formControlName="patientId"
						(input)="resetPatientIdNotUnique()" (change)="trimPatientId()"
						[ngClass]="{'is-valid': patientForm.controls.patientId.valid && patientForm.controls.patientId.touched, 'is-invalid': patientForm.controls.patientId.invalid && patientForm.controls.patientId.touched}" />
					<div class="invalid-feedback" *ngIf="patientForm.controls.patientId.invalid">
						{{patientForm.controls.patientId["errorMessage"]}}</div>
				</div>
			</div>
			<div class="col-3">
				<div class="form-group">
					<label data-testid="SUBSYS2_PATFORM_INITIALS_LBL" for="patientInitials">
						{{ labels.PATIENT_CREATION_COMPONENT_PATIENT_INITIALS }} *
					</label>
					<input data-testid="SUBSYS2_PATFORM_INITIALS_INPUT" type="text" class="form-control" id="patientInitials"
						placeholder="{{ labels.PATIENT_CREATION_COMPONENT_PATIENT_INITIALS }}" formControlName="patientInitials"
						(change)="trimPatientInitials()"
						[ngClass]="{'is-valid': patientForm.controls.patientInitials.valid && patientForm.controls.patientInitials.touched, 'is-invalid': patientForm.controls.patientInitials.invalid && patientForm.controls.patientInitials.touched}" />
					<div class="invalid-feedback" *ngIf="patientForm.controls.patientInitials.invalid">
						{{patientForm.controls.patientInitials["errorMessage"]}}</div>
				</div>
			</div>

			<div class="col-6">
				<div class="form-group"
					[ngClass]="{'is-valid': patientForm.controls.patientGender.valid && patientForm.controls.patientGender.touched, 'is-invalid': patientForm.controls.patientGender.invalid && patientForm.controls.patientGender.touched}">
					<label data-testid="SUBSYS2_PATFORM_GENDER_LBL" for="patientGender">
						{{ labels.PATIENT_CREATION_COMPONENT_GENDER }} *
					</label>
					<div class="custom-control custom-radio custom-control-inline">
						<input data-testid="SUBSYS2_PATFORM_GENDER_FEMALE_INPUT" type="radio" id="patientGender_F"
							name="patientGender" value="F" class="custom-control-input" formControlName="patientGender"
							[ngClass]="{'is-valid': patientForm.controls.patientGender.valid && patientForm.controls.patientGender.touched, 'is-invalid': patientForm.controls.patientGender.invalid && patientForm.controls.patientGender.touched}" />
						<label class="custom-control-label" for="patientGender_F">
							{{ labels.PATIENT_CREATION_COMPONENT_FEMALE }}
						</label>
					</div>
					<div class="custom-control custom-radio custom-control-inline">
						<input data-testid="SUBSYS2_PATFORM_GENDER_MALE_INPUT" type="radio" id="patientGender_M"
							name="patientGender" value="M" class="custom-control-input" formControlName="patientGender"
							[ngClass]="{'is-valid': patientForm.controls.patientGender.valid && patientForm.controls.patientGender.touched, 'is-invalid': patientForm.controls.patientGender.invalid && patientForm.controls.patientGender.touched}" />
						<label class="custom-control-label" for="patientGender_M">
							{{ labels.PATIENT_CREATION_COMPONENT_MALE }}
						</label>
					</div>
					<div class="custom-control custom-radio custom-control-inline">
						<input data-testid="SUBSYS2_PATFORM_GENDER_OTHER_INPUT" type="radio" id="patientGender_O"
							name="patientGender" value="O" class="custom-control-input" formControlName="patientGender"
							[ngClass]="{'is-valid': patientForm.controls.patientGender.valid && patientForm.controls.patientGender.touched, 'is-invalid': patientForm.controls.patientGender.invalid && patientForm.controls.patientGender.touched}" />
						<label class="custom-control-label" for="patientGender_O">
							{{ labels.PATIENT_CREATION_COMPONENT_OTHER }}
						</label>
					</div>
					<div class="invalid-feedback" *ngIf="patientForm.controls.patientGender.invalid">
						{{patientForm.controls.patientGender["errorMessage"]}}</div>
				</div>
			</div>

			<div class="col-12">
				<h5 data-testid="SUBSYS2_PATFORM_EXTRADATA_TOGGLE" class="title-accordion ml-0 mr-0"
					[ngClass]="{'active': isExtraDataVisible }" style="background-color: rgba(99,173,242,.2);"
					(click)="isExtraDataVisible = !isExtraDataVisible">
					{{ labels.PATIENT_CREATION_COMPONENT_ACCORDION_TITLE }}
					<div class="fake-material-icon icon-expand expand"></div>
				</h5>
			</div>

		</div>

		<div class="row mt-5" [ngbCollapse]="!isExtraDataVisible">

			<div class="col-3">
				<div class="form-group">
					<label data-testid="SUBSYS2_PATFORM_AGE_LBL" for="patientAge">
						{{ labels.PATIENT_CREATION_COMPONENT_AGE }}
					</label>
					<input data-testid="SUBSYS2_PATFORM_AGE_INPUT" type="text" class="form-control" id="patientAge"
						placeholder="{{ labels.PATIENT_CREATION_COMPONENT_AGE }}" formControlName="patientAge"
						[readonly]="patientForm.value.patientInfant"
						[ngClass]="{'is-valid': patientForm.controls.patientAge.valid && patientForm.controls.patientAge.touched, 'is-invalid': patientForm.controls.patientAge.invalid && patientForm.controls.patientAge.touched}" />
					<div class="invalid-feedback" *ngIf="patientForm.controls.patientAge.invalid">
						{{patientForm.controls.patientAge["errorMessage"]}}</div>
				</div>
			</div>
			<div class="col-3">
				<div class="form-group">
					<label>&nbsp;</label>
					<div class="custom-control custom-checkbox">
						<input data-testid="SUBSYS2_PATFORM_AGE_INFANT_INPUT" type="checkbox" class="custom-control-input"
							id="patientInfant" formControlName="patientInfant" (ngModelChange)="updateAge()" />
						<label class="custom-control-label" for="patientInfant">
							{{labels.PATIENT_CREATION_COMPONENT_INFANT}}
						</label>
					</div>
				</div>
			</div>

			<div class="w-100"></div> <!-- This break the lines -->

			<div class="col-9">

				<div class="row">

					<div class="col-5">
						<div class="form-group">
							<label data-testid="SUBSYS2_PATFORM_WEIGHT_KG_LBL" for="patientWeightKg">
								{{ labels.PATIENT_CREATION_COMPONENT_WEIGHT_KG }}
							</label>
							<input data-testid="SUBSYS2_PATFORM_WEIGHT_KG_INPUT" type="text" class="form-control" id="patientWeightKg"
								placeholder="{{ labels.PATIENT_CREATION_COMPONENT_WEIGHT_KG }}" formControlName="patientWeightKg"
								(input)="convertKgToLb()"
								[ngClass]="{'is-valid': patientForm.controls.patientWeightKg.valid && patientForm.controls.patientWeightKg.touched && !!patientForm.value.patientWeightKg, 'is-invalid': patientForm.controls.patientWeightKg.invalid && patientForm.controls.patientWeightKg.touched}" />
							<div class="invalid-feedback" *ngIf="patientForm.controls.patientWeightKg.invalid">
								{{patientForm.controls.patientWeightKg["errorMessage"]}}</div>
						</div>
					</div>

					<div class="col-1 d-flex justify-content-center align-items-center"><i
							class="material-icons">compare_arrows</i></div>

					<div class="col-5">
						<div class="form-group">
							<label data-testid="SUBSYS2_PATFORM_WEIGHT_LB_LBL" for="patientWeightLb">
								{{ labels.PATIENT_CREATION_COMPONENT_WEIGHT_LB }}
							</label>
							<input data-testid="SUBSYS2_PATFORM_WEIGHT_LB_INPUT" type="text" class="form-control" id="patientWeightLb"
								placeholder="{{ labels.PATIENT_CREATION_COMPONENT_WEIGHT_LB }}" formControlName="patientWeightLb"
								(input)="convertLbToKg()"
								[ngClass]="{'is-valid': patientForm.controls.patientWeightLb.valid && patientForm.controls.patientWeightLb.touched && !!patientForm.value.patientWeightLb, 'is-invalid': patientForm.controls.patientWeightLb.invalid && patientForm.controls.patientWeightLb.touched}" />
							<div class="invalid-feedback" *ngIf="patientForm.controls.patientWeightLb.invalid">
								{{patientForm.controls.patientWeightLb["errorMessage"]}}</div>
						</div>
					</div>

					<div class="w-100"></div> <!-- This break the lines -->

					<div class="col-5">
						<div class="form-group">
							<label data-testid="SUBSYS2_PATFORM_HEIGHT_CM_LBL" for="patientHeightCm">
								{{ labels.PATIENT_CREATION_COMPONENT_HEIGHT_CM }}
							</label>
							<input data-testid="SUBSYS2_PATFORM_HEIGHT_CM_INPUT" type="text" class="form-control" id="patientHeightCm"
								placeholder="{{ labels.PATIENT_CREATION_COMPONENT_HEIGHT_CM }}" formControlName="patientHeightCm"
								(input)="convertCmToFt()"
								[ngClass]="{'is-valid': patientForm.controls.patientHeightCm.valid&& patientForm.controls.patientHeightCm.touched && !!patientForm.value.patientHeightCm, 'is-invalid': patientForm.controls.patientHeightCm.invalid && patientForm.controls.patientHeightCm.touched}" />
							<div class="invalid-feedback" *ngIf="patientForm.controls.patientHeightCm.invalid">
								{{patientForm.controls.patientHeightCm["errorMessage"]}}</div>
						</div>
					</div>

					<div class="col-1 d-flex justify-content-center align-items-center"><i
							class="material-icons">compare_arrows</i></div>

					<div class="col-5">
						<div class="form-group">
							<label data-testid="SUBSYS2_PATFORM_HEIGHT_FT_LBL" for="patientHeightFt">
								{{ labels.PATIENT_CREATION_COMPONENT_HEIGHT_FT }}
							</label>
							<input data-testid="SUBSYS2_PATFORM_HEIGHT_FT_INPUT" type="text" class="form-control" id="patientHeightFt"
								placeholder="{{ labels.PATIENT_CREATION_COMPONENT_HEIGHT_FT }}" formControlName="patientHeightFt"
								(input)="convertFtToCm()"
								[ngClass]="{'is-valid': patientForm.controls.patientHeightFt.valid&& patientForm.controls.patientHeightFt.touched && !!patientForm.value.patientHeightFt, 'is-invalid': patientForm.controls.patientHeightFt.invalid && patientForm.controls.patientHeightFt.touched}" />
							<div class="invalid-feedback" *ngIf="patientForm.controls.patientHeightFt.invalid">
								{{patientForm.controls.patientHeightFt["errorMessage"]}}</div>
						</div>
					</div>

				</div>

			</div>

			<div class="col-3">
				<label>{{ labels.PATIENT_CREATION_COMPONENT_BMI }}</label>
				<div data-testid="SUBSYS2_PATFORM_BMI_BOX" class="box-bmi">
					<p>{{ labels.PATIENT_CREATION_COMPONENT_BMI_MESSAGE }}</p>
					<h2 data-testid="SUBSYS2_PATFORM_BMI_VALUE">
						{{ patientBMI ? patientBMI + ' ' + labels.PATIENT_CREATION_COMPONENT_BMI : null }}
					</h2>
				</div>
			</div>

		</div>

	</div>

	<div class="row">
		<div class="col-12 btn-container">

			<button data-testid="SUBSYS2_PATFORM_CANCEL_BTN" type="button" class="btn btn-secondary"
				(click)="openConfirmModal()">
				{{ labels.PATIENT_CREATION_COMPONENT_CANCEL_BUTTON }}
			</button>

			<button data-testid="SUBSYS2_PATFORM_SAVE_BTN" type="button" class="btn btn-primary" [disabled]="isSaveDisabled"
				(click)="savePatient()">
				{{ labels.PATIENT_CREATION_COMPONENT_SAVE_BUTTON }}
			</button>
			<button data-testid="SUBSYS2_PATFORM_CREATEPLAN_BTN" type="button" class="btn btn-primary"
				[disabled]="isSaveDisabled" (click)="saveAndCreatePlan()">
				{{ labels.PATIENT_CREATION_COMPONENT_SAVE_AND_CREATE_BUTTON }}
			</button>

		</div>
	</div>

</form>