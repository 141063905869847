<div class="row">
	<div class="col">

		<h1 data-testid="SUBSYS2_DASHBOARD_TITLE_TXT" class="title-dashboard" [class.mt-0]="isCalendarVisible">
			{{ labels.DASHBOARD_COMPONENT_TITLE }}
		</h1>

	</div>
</div>

<div class="row">
	<div class="col-12">
		<div class="wrapper-panel-dashboard">
			<div class="panel panel-card primary">
				<i class="material-icons">post_add</i>
				<h1 data-testid="SUBSYS2_DASHBOARD_CREATECARD_TITLE_TXT">{{ labels.DASHBOARD_COMPONENT_CARD_CREATE_TITLE }}</h1>
				<div>{{ labels.DASHBOARD_COMPONENT_CARD_CREATE_MSG }}</div>
				<div class="wrapper-btn-buttons-card">
					<button data-testid="SUBSYS2_DASHBOARD_SEARCHPAT_BTN" type="button" class="btn btn-third"
						(click)="searchPatient()">
						{{ labels.DASHBOARD_COMPONENT_CARD_CREATE_BTN_2 }}
					</button>
					<button data-testid="SUBSYS2_DASHBOARD_CREATEPAT_BTN" type="button" class="btn" (click)="createPatient()">
						{{ labels.DASHBOARD_COMPONENT_CARD_CREATE_BTN_1 }}
					</button>
				</div>
			</div>

			<div class="panel panel-card primary">
				<i class="material-icons">manage_search</i>
				<h1 data-testid="SUBSYS2_DASHBOARD_SEARCHCARD_TITLE_TXT">{{ labels.DASHBOARD_COMPONENT_CARD_SEARCH_TITLE }}</h1>
				<div class="wrapper-btn-buttons-card">
					<button data-testid="SUBSYS2_DASHBOARD_ARCHIVE_BTN" type="button" class="btn btn-third" (click)="archive()"
						*ngIf="isArchiveVisible">
						{{ labels.DASHBOARD_COMPONENT_CARD_ARCHIVE_BTN }}
					</button>
					<button data-testid="SUBSYS2_DASHBOARD_SEARCHPLAN_BTN" type="button" class="btn" (click)="searchPlan()">
						{{ labels.DASHBOARD_COMPONENT_CARD_SEARCH_BTN }}
					</button>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="row mt-5" *ngIf="isCalendarVisible">
	<div class="col-12">
		<div class="wrapper-panel-dashboard">

			<div class="panel panel-card">
				<dashboard-calendar></dashboard-calendar>
			</div>

			<div class="panel panel-card">
				<dashboard-events></dashboard-events>
			</div>

		</div>
	</div>
</div>


<div class="info-signin" *ngIf="signin">
	<label>{{ labels.DASHBOARD_COMPONENT_LAST_SIGNIN }}</label>
	<span>{{ signin.date | convertDate }}, {{ signin.date | date: "HH:mm:ss" }}</span><br />
	<span>{{ signin.city }}, {{ signin.state }}, {{ signin.country }}</span>
</div>