import { prop, required } from '@rxweb/reactive-form-validators';
import { AnatomicalSideEnum, BoneTypeEnum, BoneViewEnum, ImageOrientationEnum, ReferenceTypeEnum } from '../core';



/**
* Image categorization form model
*/
export class ImageCategorizationForm {

	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_BONE_TYPE" })
	boneType: BoneTypeEnum;

	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_BONE_SIDE" })
	boneSide: AnatomicalSideEnum;

	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_BONE_VIEW" })
	boneView: BoneViewEnum;

	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_IMAGE_ORIENTATION" })
	orientation: ImageOrientationEnum;

	@required()
	scaleFactor: number;


	// Ring calibration data

	@prop()
	isRingCalibration: boolean;

	@required({ conditionalExpression: (x: ImageCategorizationForm) => x.isRingCalibration })
	ringCalibration: RingCalibrationResult;
}

export interface RingCalibrationResult {
	referenceType: ReferenceTypeEnum;
	ellipsePoints: { x: number; y: number }[];
	proxRingId: number;
	distalRingId: number;
	ringMatrix: number[];
	angle: number;
	frontal: number;
	axial: number;
}
