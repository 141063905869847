<!-- Modal img -->
<div class="modal-header">
	<h5 data-testid="SUBSYS2_ATTACHPREVIEW_TITLE_TXT" class="modal-title">
		{{ fileName || labels.ATTACHMENT_PREVIEW_COMPONENT_TITLE }}
	</h5>
	<button data-testid="SUBSYS2_ATTACHPREVIEW_CLOSE_BTN" type="button" class="close" data-dismiss="modal"
		aria-label="Close" style="outline: none" (click)="activeModal.close()" ngbAutoFocus>
		<i class="material-icons" style="cursor: pointer;">clear</i>
	</button>
</div>
<div data-testid="SUBSYS2_ATTACHPREVIEW_IMAGE_BOX" class="modal-body">
	<img [src]="url" [ngClass]="{ 'flip-hor': isFlipHor, 'flip-ver': isFlipVer }" alt="attachment preview" />
</div>