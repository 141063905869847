import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ErrorMessageBindingStrategy, ReactiveFormConfig, RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { Subscription } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';
import { LanguageService, Patient, ToastService, convertLength, convertMass, convertToDecimal, truncDecimal } from '../../core';
import { PatientForm } from '../../models';
import { AttachmentService, PatientService, UserService } from '../../services';
import { BaseComponent, ConfirmationComponent } from '../../shared';


/**
* Patient form component to create/edit patient data
*/
@Component({
	selector: 'patient-form',
	templateUrl: './patient-form.component.html'
})
export class PatientFormComponent extends BaseComponent implements OnInit, OnDestroy {

	patientForm: RxFormGroup = <RxFormGroup>this.formBuilder.formGroup(PatientForm);
	patient: Patient;
	patientGuid: string;

	isExtraDataVisible: boolean;
	patientBMI: number;

	createPlan: boolean;
	isSaving: boolean;
	isAnotherUser: boolean;

	private _usrSub$: Subscription;

	constructor(
		private langSrv: LanguageService,
		private formBuilder: RxFormBuilder,
		private router: Router,
		public route: ActivatedRoute,
		private modalService: NgbModal,
		private patientSrv: PatientService,
		private attachSrv: AttachmentService,
		private toastSrv: ToastService,
		private userSrv: UserService
	) {
		super(langSrv);
	}

	ngOnInit() {
		this.initForm();
		this.initPatientForm();
	}

	private initForm() {
		ReactiveFormConfig.set({
			"validationMessage": this.langSrv.getValidationMessages(),
			"errorMessageBindingStrategy": ErrorMessageBindingStrategy.OnDirtyOrTouched
		});
	}

	private initPatientForm() {
		this.patientGuid = this.route.snapshot.queryParamMap.get('patientGuid');
		if (this.patientGuid != null) {
			this.patientSrv.getPatient(this.patientGuid).pipe(
				tap(pat => this.patient = pat),
				map(pat => this.patientSrv.createPatientForm(pat))
			).subscribe(patForm => {
				this.patientSrv.setCurrentPatient(this.patientGuid, this.patient.userGuid);
				this.patientForm = <RxFormGroup>this.formBuilder.formGroup(PatientForm, patForm);
				this.convertKgToLb();
				this.convertCmToFt();
				this.patientForm.markAsUntouched();
				this.initImages();
				this._usrSub$ = this.userSrv.isCustomerCareOtherUser(this.patient?.userGuid).subscribe(res => {
					this.isAnotherUser = res;
					this.patientSrv.setPatientReadonly(this.isAnotherUser);
				});
			})
		} else {
			this.patientSrv.setCurrentPatient(null, null);
			this.resetForm();
			this.initImages();
		}
	}

	private initImages() {
		this.patientGuid ? this.attachSrv.updateAttachmentList(this.patientGuid, this.patient.userGuid) : this.attachSrv.resetAttachmentList();
	}

	get isSaveDisabled(): boolean {
		return !this.patientForm.valid || this.isSaving || this.isAnotherUser;
	}

	/**
	* Reset PatientIdNotUnique flag after validation
	*/
	resetPatientIdNotUnique() {
		if (this.patientForm.value.patientIdNotUnique) {
			this.patientForm.controls.patientIdNotUnique.setValue(false);
			this.patientForm.controls.patientId.setValue(this.patientForm.value.patientId);
		}
	}

	/**
	* Trim patient id input field
	*/
	trimPatientId(): void {
		if (this.patientForm.value.patientId) {
			this.patientForm.controls.patientId.setValue(this.patientForm.value.patientId.trim())
		}
	}

	/**
	* Trim patient intitials input field
	*/
	trimPatientInitials(): void {
		if (this.patientForm.value.patientInitials) {
			this.patientForm.controls.patientInitials.setValue(this.patientForm.value.patientInitials.trim())
		}
	}

	private resetForm() {
		this.patientForm = <RxFormGroup>this.formBuilder.formGroup(PatientForm);
	}

	/**
	* Update age when click infant age checkox
	*/
	updateAge() {
		this.patientForm.value.patientInfant ? this.patientForm.controls.patientAge.setValue(0) : this.patientForm.controls.patientAge.setValue(null);
		this.patientForm.controls.patientAge.markAsTouched();
	}

	/**
	* Handle weight in kg and convert it in lb
	*/
	convertKgToLb() {
		this.patientForm.controls.patientWeightKg.setValue(truncDecimal(this.patientForm.value.patientWeightKg));
		const kg: number = convertToDecimal(this.patientForm.value.patientWeightKg);
		this.patientForm.controls.patientWeightLb.setValue(convertMass(kg, 'kg', 'lb'));
		this.patientForm.controls.patientWeightLb.markAsTouched();
		this.calculateBMI();
	}

	/**
	* Handle weight in lb and convert it in kg
	*/
	convertLbToKg() {
		this.patientForm.controls.patientWeightLb.setValue(truncDecimal(this.patientForm.value.patientWeightLb));
		const lb: number = convertToDecimal(this.patientForm.value.patientWeightLb);
		this.patientForm.controls.patientWeightKg.setValue(convertMass(lb, 'lb', 'kg'));
		this.patientForm.controls.patientWeightKg.markAsTouched();
		this.calculateBMI();
	}

	/**
	* Handle height in cm and convert it in ft
	*/
	convertCmToFt() {
		this.patientForm.controls.patientHeightCm.setValue(truncDecimal(this.patientForm.value.patientHeightCm));
		const cm: number = convertToDecimal(this.patientForm.value.patientHeightCm);
		this.patientForm.controls.patientHeightFt.setValue(convertLength(cm, 'cm', 'ft'));
		this.patientForm.controls.patientHeightFt.markAsTouched();
		this.calculateBMI();
	}

	/**
	* Handle height in ft and convert it in cm
	*/
	convertFtToCm() {
		this.patientForm.controls.patientHeightFt.setValue(truncDecimal(this.patientForm.value.patientHeightFt));
		const ft: number = convertToDecimal(this.patientForm.value.patientHeightFt);
		this.patientForm.controls.patientHeightCm.setValue(convertLength(ft, 'ft', 'cm'));
		this.patientForm.controls.patientHeightCm.markAsTouched();
		this.calculateBMI();
	}

	private calculateBMI() {
		const height: number = this.patientForm.value.patientHeightCm;
		const weight: number = this.patientForm.value.patientWeightKg;
		if (height && weight && +height && +weight && !isNaN(height) && !isNaN(weight)) {
			this.patientBMI = Math.round((weight / Math.pow(height / 100, 2) * 10)) / 10;
		} else {
			this.patientBMI = null;
		}
	}

	/**
	* Handle save and edit patient
	*/
	savePatient() {
		const patForm: PatientForm = this.patientForm.value;
		if (this.patientGuid == null) {
			this.isSaving = true;
			this.patientSrv.savePatientForm(patForm).pipe(
				finalize(() => this.isSaving = false)
			).subscribe({
				next: (patient: Patient) => {
					this.toastSrv.showSuccess(this.labels['PATIENT_CREATION_COMPONENT_SAVE_MESSAGE']);
					this.patient = patient;
					this.patientGuid = this.patient.id;
					this.patientSrv.setCurrentPatient(this.patientGuid, this.patient.userGuid);
					this.updateQueryParams();
					if (this.createPlan) this.navigateToPlanForm(this.patientGuid);
					this.createPlan = false;
				},
				error: (err: Error) => {
					this.toastSrv.showError(this.labels['PATIENT_CREATION_COMPONENT_ERROR_MESSAGE']);
					this.handlePatientIdNotUnique(err);
					this.createPlan = false;
				}
			});
		} else {
			this.isSaving = true;
			this.patientSrv.updatePatient(this.patient, patForm).pipe(
				finalize(() => this.isSaving = false)
			).subscribe({
				next: res => {
					this.toastSrv.showSuccess(this.labels['PATIENT_CREATION_COMPONENT_UPDATE_MESSAGE']);
					if (this.createPlan) this.navigateToPlanForm(this.patientGuid);
					this.createPlan = false;
				},
				error: (err: Error) => {
					this.toastSrv.showError(this.labels['PATIENT_CREATION_COMPONENT_ERROR_MESSAGE']);
					this.handlePatientIdNotUnique(err);
					this.createPlan = false;
				}
			});
		}
	}

	/**
	* Handle save and edit patient with navigation to plan creation page 
	*/
	saveAndCreatePlan() {
		this.createPlan = true;
		this.savePatient();
	}

	private updateQueryParams() {
		this.router.navigate(['/patient/edit'], { queryParams: { patientGuid: this.patientGuid } });
	}

	private navigateToPlanForm(patGuid: string) {
		this.router.navigate(['/plan/new'], { queryParams: { patientGuid: patGuid } });
	}

	private handlePatientIdNotUnique(err: Error) {
		if (err.message == "PatientNumberNotUnique") {
			this.patientForm.controls.patientIdNotUnique.setValue(true);
			this.patientForm.controls.patientId.setValue(this.patientForm.value.patientId);
		}
	}

	/**
	* Open confirmation modal before navigate to patient list page
	*/
	openConfirmModal() {
		if (!this.modalService.hasOpenModals()) {
			const modalRef: NgbModalRef = this.modalService.open(ConfirmationComponent, {
				centered: true, backdrop: 'static'
			});
			(modalRef.componentInstance as ConfirmationComponent).config = {
				title: this.labels['PATIENT_CREATION_COMPONENT_CANCEL_MODAL_TITLE'],
				message: this.labels['PATIENT_CREATION_COMPONENT_CANCEL_MODAL_MESSAGE'],
				cancelBtn: this.labels['PATIENT_CREATION_COMPONENT_CANCEL_MODAL_NO'],
				confirmBtn: this.labels['PATIENT_CREATION_COMPONENT_CANCEL_MODAL_YES']
			};
			modalRef.result.then(() => this.returnToPatientList()).catch(error => false);
		}
	}

	private returnToPatientList() {
		this.router.navigateByUrl("/list");
	}


	ngOnDestroy() {
		this.attachSrv.resetAttachmentList();
		this.patientSrv.setCurrentPatient(null, null);
		this.patientSrv.setPatientReadonly(null);
		this._usrSub$?.unsubscribe();
	}

}
