import { Component } from '@angular/core';
import { BaseComponent } from '../../../shared';
import { LanguageService } from '../../language.service';

/**
* Error page component for generic error
*/
@Component({
	selector: 'app-errors',
	templateUrl: './errors.component.html'
})
export class ErrorsComponent extends BaseComponent {

	constructor(private langSrv: LanguageService) {
		super(langSrv);
	}

}
