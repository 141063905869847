import { Component, OnInit } from '@angular/core';
import { AuthService } from '@ortho-next/auth-core';
import { SseService } from '@ortho-next/sse';
import { firstValueFrom } from 'rxjs';
import { environment } from '../environments/environment';
import { LanguageService, MonitoringService, ToastService } from './core';
import { TitleService } from './services';


/**
* Root application component
*/
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html'
})

export class AppComponent implements OnInit {

	constructor(
		private authService: AuthService,
		private monitoringService: MonitoringService,
		private sse: SseService,
		private langSrv: LanguageService,
		private titleSrv: TitleService,
		private toastSrv: ToastService
	) {
		authService.isDoneLoading$.subscribe(() => {
			if (authService.hasValidToken()) {
				sse.connection(environment.sseAPI, () => firstValueFrom(authService.getAccessToken()));

				const userName = authService.userName;
				if (userName) {
					monitoringService.setAuthenticatedUserId(userName);
				}
			}
		});
		authService.onLogout$.subscribe(() => monitoringService.clearAuthenticatedUserId());
	}

	ngOnInit() {
		this.titleSrv.init();
		if (this.authService.hasChangePassword) {
			this.toastSrv.showSuccess(this.langSrv.getLabels().APP_COMPONENT_CHANGE_PASSWORD_SUCCESS);
		}

		(window as any).noCircleDetection = false;
	}

}
