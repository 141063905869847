import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import { AnatomicalSideEnum, ApiResponse, ApiResponseStatusCode, BoneTypeEnum, BoneViewEnum, Image, ImageCategorizationEdit, ImageCategorizationSave, ImageRepository, Plan, ringDesc, RingSize } from '../core';
import { Attachment, ImageCategorizationForm, ImageItem } from '../models';
import { TlhexService } from './tlhex.service';
import { UserService } from './user.service';



/**
 * This Service handles attachment data.
 */
@Injectable()
export class AttachmentService {

	private _attachmentList: BehaviorSubject<Attachment[]> = new BehaviorSubject(null);

	private _loadingList: BehaviorSubject<string[]> = new BehaviorSubject([]);

	private _apImageId: BehaviorSubject<string> = new BehaviorSubject<string>(null);
	private _ltImageId: BehaviorSubject<string> = new BehaviorSubject<string>(null);

	private _originalApImage: string;
	private _originalLtImage: string;

	private _isCheckUp: boolean;
	private _checkUpBoneType: BoneTypeEnum;
	private _checkUpSide: AnatomicalSideEnum;
	private _checkUpProxRingId: number;
	private _checkUpDistRingId: number;

	private _ringList: RingSize[];

	constructor(
		private imageRepo: ImageRepository,
		private usrSrv: UserService,
		private tlxSrv: TlhexService
	) {
		this.usrSrv.isTlhex().pipe(
			filter(isTlex => isTlex),
			switchMap(() => this.tlxSrv.getRingSizes())
		).subscribe(list => this._ringList = list);
	}

	/**
	* Set current plan images
	*/
	setPlanImages(plan: Plan): void {
		this._originalApImage = plan.apImageGuid;
		this._originalLtImage = plan.ltImageGuid;
		this._apImageId.next(plan.apImageGuid);
		this._ltImageId.next(plan.ltImageGuid);
	}

	/**
	* Reset current plan images
	*/
	resetPlanImages(): void {
		this._originalApImage = null;
		this._originalLtImage = null;
		this._apImageId.next(null);
		this._ltImageId.next(null);
		this.updateCheckUpValidation();
	}

	/**
	 * Update checkup data for image validation.
	 */
	updateCheckUpValidation(plan?: Plan, proxRing?: number, distalRing?: number): void {
		this._isCheckUp = plan?.isCheckUp;
		this._checkUpBoneType = this._isCheckUp ? plan.boneType : null;
		this._checkUpSide = this._isCheckUp ? plan.side : null;
		this._checkUpProxRingId = this._isCheckUp && proxRing ? proxRing : null;
		this._checkUpDistRingId = this._isCheckUp && distalRing ? distalRing : null;
	}

	private setAttachmentList(list: Attachment[]): void {
		this._attachmentList.next(list);
	}

	/**
	 * Check if attachment list is not empty.
	 */
	get hasAttachments(): boolean {
		return !!this._attachmentList?.value?.length;
	}

	/**
	 * Get current check up data.
	 */
	get checkUpData(): { isCheckUp: boolean, checkUpBoneType: BoneTypeEnum, checkUpSide: AnatomicalSideEnum, checkUpProxRingId: number, checkUpDistRingId: number } {
		return {
			isCheckUp: this._isCheckUp,
			checkUpBoneType: this._checkUpBoneType,
			checkUpSide: this._checkUpSide,
			checkUpProxRingId: this._checkUpProxRingId,
			checkUpDistRingId: this._checkUpDistRingId
		};
	}

	/**
	* Update attachment list
	*/
	updateAttachmentList(patientGuid: string, userId: string): void {
		this.getThumbnails(patientGuid, userId).pipe(
			tap(list => this.updateRingDescription(list))
		).subscribe({
			next: list => this.setAttachmentList(list),
			error: () => this._attachmentList.next(null)
		});
	}

	private updateRingDescription(attachList: Attachment[]): void {
		if (attachList?.length && this._ringList?.length) {
			attachList.filter(a => a.ringProximalId && a.ringDistalId).forEach(a => {
				const isFoot = a.boneType === BoneTypeEnum.Forefoot || a.boneType === BoneTypeEnum.Hindfoot;
				// proximal ring
				const proximalRing = this._ringList.find(r => r.id == a.ringProximalId);
				if (proximalRing) {
					a.proxRingDesc = ringDesc(proximalRing, isFoot);
				}
				// distal ring
				const distalRing = this._ringList.find(r => r.id == a.ringDistalId);
				if (distalRing) {
					a.distalRingDesc = ringDesc(distalRing, isFoot);
				}
			});
		}
	}

	/**
	* Reset attachment list
	*/
	resetAttachmentList(): void {
		this.setAttachmentList(null);
	}

	/**
	* Get gallery data
	*/
	getAttachmentListStatus(): Observable<Attachment[]> {
		return combineLatest([
			this._attachmentList.asObservable(),
			this._apImageId.asObservable(),
			this._ltImageId.asObservable()
		]).pipe(
			filter(([list, ap, lt]) => !!list),
			tap(([list, ap, lt]) => this.handleImageCompatibility(list, ap, lt)),
			map(([list, ap, lt]) => list.length > 0 ? this.handlePreventDeletion(list, ap, lt) : list)
		)
	}

	private handlePreventDeletion(list: Attachment[], apId: string, ltId: string): Attachment[] {
		list.forEach(l => l.preventDelete = false);
		if (apId) list.find(l => l.id == apId).preventDelete = true;
		if (ltId) list.find(l => l.id == ltId).preventDelete = true;
		if (this._originalApImage) list.find(l => l.id == this._originalApImage).preventDelete = true;
		if (this._originalLtImage) list.find(l => l.id == this._originalLtImage).preventDelete = true;
		return list;
	}

	private handleImageCompatibility(list: Attachment[], apId: string, ltId: string): Attachment[] {
		const apImage = apId ? list.find(l => l.id == apId) : null;
		const ltImage = ltId ? list.find(l => l.id == ltId) : null;

		if (this._isCheckUp) {
			const otherView = this._otherView(apId, ltId);
			const otherImg = (l: Attachment) => l.boneView === BoneViewEnum.AP ? ltImage : apImage;
			list.forEach(l => l.compatible = isCheckUpImageValid(l, this._checkUpBoneType, this._checkUpSide, otherView, otherImg(l)));
			return list;
		}

		if (!apImage && !ltImage) {
			list.forEach(l => l.compatible = true);
			return list;
		}
		list.forEach(l => l.compatible = false);
		if (apImage && ltImage) return list;
		if (apImage) {
			list.forEach(l => l.compatible = isImageCompatible(l, BoneViewEnum.LAT, apImage));
		}
		if (ltImage) {
			list.forEach(l => l.compatible = isImageCompatible(l, BoneViewEnum.AP, ltImage));
		}
		return list;
	}

	private _otherView(apId: string, ltId: string): BoneViewEnum {
		if (!!apId && !ltId) return BoneViewEnum.LAT;
		if (!apId && !!ltId) return BoneViewEnum.AP;
		return null;
	}

	/**
	* Get missing uploads list
	*/
	getLoadingList(): Observable<string[]> {
		return this._loadingList.asObservable();
	}

	/**
	* Remove item from missing uploads list
	*/
	removeLoadingItem(itemId: string): void {
		return this._loadingList.next([...this._loadingList.value.filter(id => id != itemId)]);
	}

	/**
	* Reset missing uploads list
	*/
	resetLoadingList(): void {
		this._loadingList.next([]);
	}

	/**
	* Set current ap image id
	*/
	setApImageId(imageId: string) {
		this._apImageId.next(imageId);
	}

	/**
	* Get current ap image id
	*/
	getApImageId(): Observable<string> {
		return this._apImageId.asObservable().pipe(distinctUntilChanged());
	}

	/**
	* Get current ap image
	*/
	getApImage(): Observable<Attachment> {
		return combineLatest([
			this._attachmentList.asObservable(),
			this._apImageId.asObservable()
		]).pipe(
			filter(([list, apId]) => !!list),
			map(([list, apId]) => list.find(l => l.id == apId))
		)
	}

	/**
	* Set current lateral image id
	*/
	setLtImageId(imageId: string): void {
		this._ltImageId.next(imageId);
	}

	/**
	* Get current lateral image id
	*/
	getLtImageId(): Observable<string> {
		return this._ltImageId.asObservable().pipe(distinctUntilChanged());
	}

	/**
	* Get current lateral image
	*/
	getLtImage(): Observable<Attachment> {
		return combineLatest([
			this._attachmentList.asObservable(),
			this._ltImageId.asObservable()
		]).pipe(
			filter(([list, apId]) => !!list),
			map(([list, ltId]) => list.find(l => l.id == ltId))
		)
	}

	/**
	* Upload image file and data.
	* @param {Image} image Image file and data to upload
	*/
	uploadImage(image: ImageItem): Observable<any> {
		const imageData: ImageCategorizationSave = this.imageDataUploadMapper(image);
		return this.imageRepo.uploadImage(imageData, image?.file).pipe(
			map(res => this.handleImageResponse(res)),
			tap(data => this._loadingList.next([...this._loadingList.value, data?.id]))
		);
	}

	/**
	* Edit image categorization data.
	* @param {Image} image Image data to edit
	*/
	editImage(image: ImageItem): Observable<any> {
		const imageData: ImageCategorizationEdit = this.imageDataEditMapper(image);
		return this.imageRepo.editImageCategorization(imageData).pipe(
			map(res => this.handleImageResponse(res))
		);
	}

	/**
	* Delete images by patient guid
	* @param {string} patientGuid Patient guid
	* @param {string} userId User GUID of Patient
	* @param {Attachment[]} images Images to delete
	*/
	deleteImages(patientGuid: string, userId: string, images: Attachment[]): Observable<any> {
		const imageIds: string[] = images.map(img => img.id);
		return this.imageRepo.deleteImages(imageIds).pipe(tap(res => this.updateAttachmentList(patientGuid, userId)));
	}

	/**
	* Get image detail
	* @param {string} imageId Image id
	*/
	getImageDetail(imageId: string, userId: string): Observable<Image> {
		return this.imageRepo.getImageDetail(imageId, userId).pipe(
			map(res => this.handleImageResponse(res))
		);
	}

	private getThumbnails(patientGuid: string, userId: string): Observable<Attachment[]> {
		return this.imageRepo.getThumbnails(patientGuid, userId).pipe(map(res => this.handleImageResponse(res)));
	}

	/**
	 * Init image list to upload.
	 */
	initUploadImageList(patientId: string, fileList: FileList): ImageItem[] {
		return Array.from(Array(fileList?.length).keys()).map(index => this.imageFileMapper(patientId, fileList.item(index)));
	}

	/**
	 * Init image to edit.
	 */
	initEditImageList(image: Image): ImageItem {
		if (!image) return null;
		return {
			id: image.id,
			url: image.url,
			file: null,
			patientId: image.patientId,
			boneType: image.boneType,
			boneView: image.boneView,
			boneSide: image.boneSide,
			scaleFactor: image.scaleFactor,
			orientation: image.orientation,
			referenceType: image.referenceType,
			circleCoordinates: image.circleCoordinates,
			ringProximalId: image.ringProximalId,
			ringDistalId: image.ringDistalId,
			ringMatrix: image.ringMatrix,
			angle: image.angle,
			frontal: image.frontal,
			axial: image.axial
		}
	}

	/**
	 * Map File to ImageItem.
	 */
	private imageFileMapper(patientId: string, file: File): ImageItem {
		if (!file) return null;
		return {
			id: null,
			url: null,
			patientId: patientId,
			file: file,
			boneType: null,
			boneView: null,
			boneSide: null,
			scaleFactor: null,
			orientation: null,
			referenceType: null,
			circleCoordinates: null,
			ringProximalId: null,
			ringDistalId: null,
			ringMatrix: null,
			angle: null,
			axial: null,
			frontal: null
		}
	}


	/**
	 * Map ImageItem to ImageCategorizationSave data.
	 */
	private imageDataUploadMapper(image: ImageItem): ImageCategorizationSave {
		if (!image) return null;
		return {
			patientId: image.patientId,
			boneType: image.boneType,
			boneView: image.boneView,
			boneSide: image.boneSide,
			scaleFactor: image.scaleFactor,
			orientation: image.orientation,
			referenceType: image.referenceType,
			circleCoordinates: image.circleCoordinates,
			ringProximalId: image.ringProximalId,
			ringDistalId: image.ringDistalId,
			ringMatrix: image.ringMatrix,
			angle: image.angle,
			frontal: image.frontal,
			axial: image.axial
		}
	}

	/**
 * Map ImageItem to ImageCategorizationEdit data.
 */
	private imageDataEditMapper(image: ImageItem): ImageCategorizationEdit {
		if (!image) return null;
		return {
			id: image.id,
			patientId: image.patientId,
			boneType: image.boneType,
			boneView: image.boneView,
			boneSide: image.boneSide,
			scaleFactor: image.scaleFactor,
			orientation: image.orientation,
			referenceType: image.referenceType,
			circleCoordinates: image.circleCoordinates,
			ringProximalId: image.ringProximalId,
			ringDistalId: image.ringDistalId,
			ringMatrix: image.ringMatrix,
			angle: image.angle,
			frontal: image.frontal,
			axial: image.axial
		}
	}

	/**
	 * Update ImageItem object.
	 */
	updateImageItem(
		imageItem: ImageItem,
		categorizationForm?: ImageCategorizationForm
	): ImageItem {
		return {
			...imageItem,
			boneType: categorizationForm.boneType,
			boneView: categorizationForm.boneView,
			boneSide: categorizationForm.boneSide,
			scaleFactor: categorizationForm.scaleFactor,
			orientation: categorizationForm.orientation,
			referenceType: categorizationForm.ringCalibration?.referenceType,
			circleCoordinates: categorizationForm.ringCalibration?.ellipsePoints,
			ringProximalId: categorizationForm.ringCalibration?.proxRingId,
			ringDistalId: categorizationForm.ringCalibration?.distalRingId,
			ringMatrix: categorizationForm.ringCalibration?.ringMatrix,
			angle: categorizationForm.ringCalibration?.angle,
			frontal: categorizationForm.ringCalibration?.frontal,
			axial: categorizationForm.ringCalibration?.axial
		}
	}

	/**
	 * Map ImageItem object to ImageCategorizationForm object.
	 */
	categorizationFormMapper(item: Image): ImageCategorizationForm {
		if (!item) return null;
		return {
			boneType: item.boneType,
			boneSide: item.boneSide,
			boneView: item.boneView,
			orientation: item.orientation,
			scaleFactor: item.scaleFactor,
			isRingCalibration: !!item.circleCoordinates?.length,
			ringCalibration: item.circleCoordinates?.length ? {
				referenceType: item.referenceType,
				ellipsePoints: item.circleCoordinates,
				proxRingId: item.ringProximalId,
				distalRingId: item.ringDistalId,
				ringMatrix: item.ringMatrix,
				angle: item.angle,
				frontal: item.frontal,
				axial: item.axial
			} : null
		}
	}

	private handleImageResponse<T>(response: ApiResponse<T>): T {
		if (response.statusCode == ApiResponseStatusCode.Success) {
			return response.result;
		} else {
			throw new Error("Generic error");
		}
	}

}


export function isImageCompatible(img: Attachment, view: BoneViewEnum, otherImg: Attachment): boolean {
	// check view
	const viewValid = img.boneView === view;
	if (!viewValid) return false;
	// check side
	const sideValid = !otherImg || (img.boneSide === otherImg.boneSide);
	if (!sideValid) return false;
	// check bone
	let boneValid = !otherImg || (img.boneType === otherImg.boneType);
	if (!boneValid) {
		if (view === BoneViewEnum.AP) {
			boneValid = (img.boneType === BoneTypeEnum.LongLeg) && ((otherImg.boneType === BoneTypeEnum.Femur) || (otherImg.boneType === BoneTypeEnum.Tibia));
		} else {
			boneValid = (otherImg.boneType === BoneTypeEnum.LongLeg) && ((img.boneType === BoneTypeEnum.Femur) || (img.boneType === BoneTypeEnum.Tibia));
		}
	}
	if (!boneValid) return false;
	// check ring calibration
	const ringCalibValid = !otherImg || (img.ringProximalId == otherImg.ringProximalId && img.ringDistalId == otherImg.ringDistalId);
	return ringCalibValid;
}

export function isCheckUpImageValid(attach: Attachment, checkUpBoneType: BoneTypeEnum, checkUpSide: AnatomicalSideEnum, view: BoneViewEnum, otherImg: Attachment) {
	return attach.boneType === checkUpBoneType &&
		attach.boneSide === checkUpSide &&
		(!view || attach.boneView === view) &&
		(!otherImg || (attach.ringProximalId == otherImg.ringProximalId && attach.ringDistalId == otherImg.ringDistalId));
}