import { Component, Input } from '@angular/core';
import { EventItem, StrutEventEnum, StrutEventExtended } from '../../models';


/**
 * Component for events in dashboard calendar.
 */
@Component({
	selector: 'calendar-events',
	templateUrl: './calendar-events.component.html'
})
export class CalendarEventsComponent {

	@Input() set events(list: EventItem[]) {
		this.strutChanges = list?.find(i => i.type === StrutEventEnum.CHANGE)?.items;
		this.strutAdjusts = list?.find(i => i.type === StrutEventEnum.ADJUST)?.items;
	}

	strutAdjusts: StrutEventExtended[] = [];
	strutChanges: StrutEventExtended[] = [];
	strutEventEnum = StrutEventEnum;


	/**
	 * Get formatted description of event item.
	 */
	eventItemDesc(item: StrutEventExtended): string {
		return `${item.patientName}, ${item.planName}, Frame ${item.frameID}`;
	}

}
