import { Component, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { LanguageService, PortalPageEnum, SearchItems } from '../../core';
import { ListService, PatientService, TlhexService, UserService } from '../../services';
import { BaseComponent, ConfirmationComponent } from '../../shared';


/**
* Patient actions component on patient table
*/
@Component({
	selector: 'patient-action',
	templateUrl: './patient-action.component.html'
})
export class PatientActionComponent extends BaseComponent implements OnDestroy {

	@Input() set item(val: SearchItems) {
		this.patientId = val.patientId;
		this.hasPublishedRevoked = val.patientHasPublished || val.patientHasRevoked;
		this._usrSub$ = this.userSrv.isCustomerCareOtherUser(val.userId).subscribe(res => this.isReadonly = res);
	}

	patientId: string;
	hasPublishedRevoked: boolean;
	isReadonly: boolean;

	private _usrSub$: Subscription;

	constructor(
		private langSrv: LanguageService,
		private router: Router,
		private patSrv: PatientService,
		private listSrv: ListService,
		private modalService: NgbModal,
		private tlhexSrv: TlhexService,
		private userSrv: UserService
	) {
		super(langSrv);
	}

	/**
	* Navigate to patient edit page
	*/
	edit(): void {
		if (!this.isReadonly) {
			this.router.navigate(["/patient/edit"], { queryParams: { patientGuid: this.patientId } });
		}
	}

	/**
	* Navigate to plan creation page
	*/
	createPlan(): void {
		if (!this.isReadonly) {
			this.router.navigate(['/plan/new'], { queryParams: { patientGuid: this.patientId } });
		}
	}

	/**
	* Open confirmation modal before delete patient
	*/
	openDeleteModal(): void {
		if (!this.isReadonly && !this.modalService.hasOpenModals()) {
			const modalRef: NgbModalRef = this.modalService.open(ConfirmationComponent, {
				centered: true, backdrop: 'static'
			});
			(modalRef.componentInstance as ConfirmationComponent).config = {
				title: this.labels['PATIENT_ACTION_COMPONENT_DELETE_MODAL_TITLE'],
				message: this.labels['PATIENT_ACTION_COMPONENT_DELETE_MODAL_MESSAGE'],
				cancelBtn: this.labels['PATIENT_ACTION_COMPONENT_DELETE_MODAL_NO'],
				confirmBtn: this.labels['PATIENT_ACTION_COMPONENT_DELETE_MODAL_YES']
			};
			modalRef.result.then(() => this.delete()).catch(error => false);
		}
	}

	private delete(): void {
		this.patSrv.deletePatient(this.patientId).subscribe(res => this.listSrv.search());
	}

	/**
	 * Navigate to myHexPlan patient settings.
	 */
	viewPatientSettings(): void {
		if (!this.isReadonly) {
			this.tlhexSrv.navigateToMyhexplan(PortalPageEnum.PatientSettings, this.patientId);
		}
	}

	/**
	 * Navigate to myhexPlan patient history.
	 */
	viewPatientHistory(): void {
		if (!this.isReadonly) {
			this.tlhexSrv.navigateToMyhexplan(PortalPageEnum.PatientDiary, this.patientId);
		}
	}


	ngOnDestroy(): void {
		this._usrSub$?.unsubscribe();
	}
}
