<div class="modal-body">

	<div class="row">
		<div class="col-6 col-xl-8">

			<div id="categorization-canvas-container" class="categorization-canvas-container">
				<!-- LEFT VIEW LABELS -->
				<div data-testid="SUBSYS2_CATEGORIZATION_CANVAS_LEFT_LBL" *ngIf="!!leftLabel" class="btn-xray left">
					{{leftLabel}}
				</div>

				<!-- RIGHT VIEW LABELS -->
				<div data-testid="SUBSYS2_CATEGORIZATION_CANVAS_RIGHT_LBL" *ngIf="!!rightLabel" class="btn-xray right">
					{{rightLabel}}
				</div>

				<!-- TOP VIEW LABELS -->
				<div data-testid="SUBSYS2_CATEGORIZATION_CANVAS_TOP_LBL" *ngIf="!!topLabel" class="btn-xray top">
					{{topLabel}}
				</div>

				<!-- BOTTOM VIEW LABELS -->
				<div data-testid="SUBSYS2_CATEGORIZATION_CANVAS_BOTTOM_LBL" *ngIf="!!bottomLabel" class="btn-xray bottom">
					{{bottomLabel}}
				</div>


				<!-- WIDGET ZOOM -->
				<div class="widget-zoom">
					<div data-testid="SUBSYS2_CATEGORIZATION_CANVAS_FULLSCREEN_BTN" class="btn-zoom"
						title="{{ labels.IMAGE_GARAGE_COMPONENT_FIT_TO_VIEW }}" (click)="fitToView()">
						<i class="material-icons">fullscreen</i>
					</div>
					<div data-testid="SUBSYS2_CATEGORIZATION_CANVAS_ZOOMIN_BTN" class="btn-zoom"
						title="{{ labels.IMAGE_GARAGE_COMPONENT_ZOOM_IN }}" (click)="zoomIn()">
						<i class="material-icons">add</i>
					</div>
					<div data-testid="SUBSYS2_CATEGORIZATION_CANVAS_ZOOMOUT_BTN" class="btn-zoom"
						title="{{ labels.IMAGE_GARAGE_COMPONENT_ZOOM_OUT }}" (click)="zoomOut()">
						<i class="material-icons">remove</i>
					</div>
				</div>

				<div id="categorization-canvas" class="categorization-canvas"></div>
			</div>

		</div>

		<div class="col-6 col-xl-4">
			<image-categorization [imageList]="imageList" [edit]="edit">
			</image-categorization>
		</div>

	</div>
</div>