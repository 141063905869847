<div class="panel panel-plan">

	<div data-testid="SUBSYS2_PLANFORM_IMAGES_DISABLED_BOX" class="panel-plan-disabled" *ngIf="isBoxDisabled"></div>

	<h2 data-testid="SUBSYS2_IMAGELOADER_TITLE_TXT">{{labels.IMAGE_LOADER_COMPONENT_TITLE}}</h2>

	<div class="panel alert flex-grow-0">
		<div class="state-icon"><i class="material-icons md-18">report_problem</i></div>
		<p data-testid="SUBSYS2_IMAGELOADER_LIABILITY_TXT">{{labels.IMAGE_LOADER_COMPONENT_SUBTITLE}}</p>
	</div>

	<div class="row">
		<div class=" col-6 col-xl-6">
			<div data-testid="SUBSYS2_IMAGELOADER_AP_BOX" class="drag-area"
				[ngClass]="{'dropped': apImage && isApVisible, 'drag-in': !isApVisible }" cdkDropList [id]="dropApId"
				[cdkDropListEnterPredicate]="apPredicate(ltImage)" (cdkDropListDropped)="dropApImage($event)"
				(cdkDropListEntered)="onApEnter($event)" (cdkDropListExited)="onApExit($event)">
				<h4 *ngIf="!apImage && isApVisible" innerHtml="{{labels.IMAGE_LOADER_COMPONENT_AP_MSG}}"></h4>
				<div data-testid="SUBSYS2_IMAGELOADER_AP_REMOVE_BTN" class="icon-delete" *ngIf="apImage && isApVisible"
					(click)="removeApImage()"><i class="material-icons">remove_circle</i></div>
				<img *ngIf="apImage && isApVisible" [src]="apImage?.url" alt="AP image" />
			</div>
		</div>

		<div class="col-6 col-xl-6">
			<div data-testid="SUBSYS2_IMAGELOADER_LT_BOX" class="drag-area"
				[ngClass]="{'dropped': ltImage && isLateralVisible, 'drag-in': !isLateralVisible }" cdkDropList
				[id]="dropLateralId" [cdkDropListEnterPredicate]="latPredicate(apImage)"
				(cdkDropListDropped)="dropLateralImage($event)" (cdkDropListEntered)="onLateralEnter($event)"
				(cdkDropListExited)="onLateralExit($event)">
				<h4 *ngIf="!ltImage && isLateralVisible" innerHtml="{{labels.IMAGE_LOADER_COMPONENT_LATERAL_MSG}}"></h4>
				<div data-testid="SUBSYS2_IMAGELOADER_LT_REMOVE_BTN" class="icon-delete" *ngIf="ltImage && isLateralVisible"
					(click)="removeLateralImage()"><i class="material-icons">remove_circle</i></div>
				<img *ngIf="ltImage && isLateralVisible" [src]="ltImage?.url" alt="LT image" />
			</div>
		</div>
	</div>


	<div class="row">
		<div class="col-12">
			<label data-testid="SUBSYS2_PLANFORM_PRODUCTS_LBL">{{labels.PLAN_FORM_COMPONENT_PRODUCTS}}</label>
		</div>
		<div class="col-6">
			<div data-testid="SUBSYS2_PLANFORM_PRODUCTS_TXT" class="box-bmi box-bmi-plan col-6 col-xl-12">
				<ul *ngIf="productList?.length; else noProductsBlock">

					<span *ngIf="noProductsEnabled; else productListBlock">
						{{labels.PLAN_FORM_COMPONENT_ONLY_ANALYSIS_MSG}}
					</span>
					<ng-template #productListBlock>
						<li class="d-flex mb-3" *ngFor="let prod of productList">
							<i class="material-icons">linear_scale</i>
							<span class="text-white ml-3">{{prod}}</span>
						</li>
					</ng-template>

				</ul>
				<ng-template #noProductsBlock>
					<span class="text-danger">{{labels.PLAN_FORM_COMPONENT_NO_PRODUCTS_MSG}}</span>
				</ng-template>
			</div>
		</div>

		<div class="col-6">
			<form [formGroup]="form" *ngIf="!!form && isIwrenchVisible">

				<div class="form-group d-flex pb-0">
					<label>&nbsp;</label>
					<div class="custom-control custom-checkbox">
						<input data-testid="SUBSYS2_PLANFORM_IWRENCH_INPUT" type="checkbox" class="custom-control-input"
							id="iwrench" formControlName="hasIwrench"
							[attr.disabled]="isCheckUp || !isIwrenchEnabled ? true : null" />
						<label data-testid="SUBSYS2_PLANFORM_IWRENCH_LBL" class="custom-control-label" for="iwrench">
							{{ labels.PLAN_FORM_COMPONENT_IWRENCH }}
						</label>
					</div>
				</div>

			</form>
		</div>

	</div>

</div>