import { Component, OnInit } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LanguageService } from '../../core';
import { HelpService } from '../../services';
import { BaseComponent } from '../base-component';


/**
 * Help info component.
 */
@Component({
	selector: 'app-help',
	templateUrl: './help.component.html'
})
export class HelpComponent extends BaseComponent implements OnInit {

	safeUrl: SafeResourceUrl;

	constructor(
		private langSrv: LanguageService,
		public activeModal: NgbActiveModal,
		private helpSrv: HelpService
	) {
		super(langSrv);
	}

	ngOnInit(): void {
		if (!this.helpSrv.isLoaded) {
			this.helpSrv.loadFileNames().subscribe(() => this.safeUrl = this.helpSrv.safeUrl);
		} else {
			this.safeUrl = this.helpSrv.safeUrl;
		}
	}

}
