<div data-testid="SUBSYS2_DASHBOARD_CALENDAREVENT_STRUTCHANGE_BOX" *ngIf="strutChanges?.length"
	class="calendar-event strut-change" [ngbTooltip]="strutChangesTmp"
	tooltipClass="calendar-event-tooltip strut-change-tooltip" placement="right">
</div>
<div data-testid="SUBSYS2_DASHBOARD_CALENDAREVENT_STRUTADJUST_BOX" *ngIf="strutAdjusts?.length"
	class="calendar-event strut-adjust" [ngbTooltip]="strutAdjustsTmp"
	tooltipClass="calendar-event-tooltip strut-adjust-tooltip" placement="right">
</div>



<ng-template #strutChangesTmp>
	<div>{{strutEventEnum.CHANGE}}</div>
	<div *ngFor="let change of strutChanges">{{eventItemDesc(change)}}</div>
</ng-template>

<ng-template #strutAdjustsTmp>
	<div>{{strutEventEnum.ADJUST}}</div>
	<div *ngFor="let adjust of strutAdjusts">{{eventItemDesc(adjust)}}</div>
</ng-template>