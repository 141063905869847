export const environment = {
  name: 'verification',
  production: true,
  insightKey: '5666fdb0-4bd1-494e-a92a-151101ab7cb3',
  domainPatientAPI: 'https://api-ver2.orthonext.app/pat/v1',
  domainUserAPI: 'https://api-ver2.orthonext.app/usr/v1',
  domainImageAPI: 'https://api-ver2.orthonext.app/img/v1',
  domainRayAPI: 'https://api-ver2.orthonext.app/ray/v1',
  domainIntegrationAPI: 'https://api-ver2.orthonext.app/int/v1',
  domainTlhexAPI: 'https://api-ver2.orthonext.app/tlhex/v1',
  domainAiAPI: 'https://api-ver2.orthonext.app/ai/v1',
	sseAPI: 'https://api-ver2.orthonext.app/sse',
  cdnUrl: 'https://cdnver2.orthonext.app/ver',
  b2cTenantName: 'orthonextb2cver2',
  b2cTenantId: '262e6e85-9536-464a-bb73-0bb0c09a36e8',
  b2cSignInPolicyId: 'B2C_1_OnlySignIN2',
  b2cResetPasswordPolicyId: 'B2C_1_PasswordReset',
  b2cChangePasswordPolicyId: 'B2C_1A_PasswordChange',
  b2cClientId: 'f3c6d7b4-e0ae-41eb-a7e9-17d08f2fab9e',
  b2cApplicationScope: 'openid profile https://orthonextb2cver2.onmicrosoft.com/api/user_impersonation',
  appSSOutUriApi: 'https://api-ver2.orthonext.app/services/appssouturi',
  idleTimeToEndSession: 1800,
  buildVer: 'OrthoNext-PatientCase FE VER CI-CD_24108.01',
  validMIMETypes: ['image/png', 'image/jpeg'],
  maxAttachmentSize: 10485760,
	resetSubscriptionTimeout: 30000,
  nextraySite: 'https://nextrayver2.orthonext.app',
  fitboneSite: 'https://fitbonever2.orthonext.app',
	tlhexSite: 'https://tlhexver2.orthonext.app/tlhex',
  tlhexRaySite: 'https://tlhexver2.orthonext.app/hexray',
  tlhexArchiveSite: 'https://tlhexver2.orthonext.app/archive',
  tlhexViewSite: 'https://tlhexver2.orthonext.app/view',
  legalTermsSite: 'https://requestver2.orthonext.app/legal-terms',
  legalTermsAcceptSite: 'https://requestver2.orthonext.app/legal-terms-acceptance',
  aboutSite: 'https://cdnver2.orthonext.app/ver/about/about.html',
  guideSite: {
    baseUrl: 'https://cdnver2.orthonext.app/ver/Guide',
    list: 'https://cdnver2.orthonext.app/ver/Guide/guide_list.json'
  },
	helpSite: {
		baseUrl: 'https://cdnver2.orthonext.app/ver/Help/Patient20/html',
		fileNames: 'https://cdnver2.orthonext.app/ver/Help/Patient20/file_names.json'
	},
  customerCareEmail: 'orthonext@orthofix.com',
	myhexplanSite: 'https://treatmentsupportportalver2.azurewebsites.net',
	eifuSite: 'https://ifu.orthofix.it',
	cookieName: '_iub_cs-60532175'
};
