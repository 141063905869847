import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NgbDropdownModule, NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthGuardWithForcedLogin } from '@ortho-next/auth-core';
import { LoaderService, ToastComponent, ToastService, UserRepository } from '../core';
import { DashboardComponent } from '../dashboard';
import { PatientCreationComponent } from '../patient';
import { PlanComponent } from '../plan';
import { ProfileFormComponent } from '../profile';
import { GuideService, HelpService, UserService } from '../services';
import { ContainerTemplateComponent } from './container-template';
import { NavbarComponent } from './navbar';
import { SidebarComponent } from './sidebar';

const routes: Routes = [
	{
		path: '', component: ContainerTemplateComponent, children: [
			{ path: '', redirectTo: 'dashboard', pathMatch: 'full' },
			{ path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuardWithForcedLogin] },
			{ path: 'list', loadChildren: () => import('../list/list.module').then(m => m.ListModule), canActivate: [AuthGuardWithForcedLogin] },
			{ path: 'patient/new', component: PatientCreationComponent, canActivate: [AuthGuardWithForcedLogin] },
			{ path: 'patient/edit', component: PatientCreationComponent, canActivate: [AuthGuardWithForcedLogin] },
			{ path: 'plan/new', component: PlanComponent, canActivate: [AuthGuardWithForcedLogin] },
			{ path: 'plan/edit', component: PlanComponent, canActivate: [AuthGuardWithForcedLogin] },
			{ path: 'profile/edit', component: ProfileFormComponent, canActivate: [AuthGuardWithForcedLogin] }
		], canActivate: [AuthGuardWithForcedLogin]
	}
];

@NgModule({
	declarations: [
		ContainerTemplateComponent,
		SidebarComponent,
		NavbarComponent,
		ToastComponent
	],
	imports: [
		CommonModule,
		RouterModule.forChild(routes),
		NgbDropdownModule,
		NgbToastModule
	],
	exports: [
		RouterModule,
		ContainerTemplateComponent
	],
	providers: [
		ToastService,
		UserService,
		UserRepository,
		GuideService,
		LoaderService,
		HelpService
	]
})
export class TemplateModule { }
