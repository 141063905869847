import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { buildFormData } from '../utils';
import { IImageRepository } from './Iimage.repository';
import { ApiResponse, Image, ImageCategorizationEdit, ImageCategorizationSave, ImageThumb } from './models';

@Injectable()
export class ImageRepository implements IImageRepository {

	constructor(private http: HttpClient) { }

	uploadImage(imageData: ImageCategorizationSave, image: File): Observable<ApiResponse<Image>> {
		const formData = new FormData();
		buildFormData(formData, imageData);
		formData.append('image', image);
		return this.http.post<ApiResponse<Image>>(environment.domainImageAPI + "/api/Images/Upload", formData);
	}

	editImageCategorization(imageData: ImageCategorizationEdit): Observable<ApiResponse<any>> {
		return this.http.patch<ApiResponse<any>>(environment.domainImageAPI + "/api/Images/EditCategorization", imageData);
	}

	deleteImages(images: string[]): Observable<ApiResponse<any>> {
		return this.http.delete<ApiResponse<any>>(environment.domainImageAPI + "/api/Images/Delete", { body: images });
	}

	getImageDetail(image: string, user: string): Observable<ApiResponse<Image>> {
		return this.http.get<ApiResponse<Image>>(environment.domainImageAPI + `/api/Images/Detail?image=${image}&user=${user}`);
	}

	getThumbnails(patientGuid: string, userId: string): Observable<ApiResponse<ImageThumb[]>> {
		return this.http.get<ApiResponse<ImageThumb[]>>(environment.domainImageAPI + `/api/Images/ThumbList?patient=${patientGuid}&userId=${userId}`);
	}

}
