<sidebar></sidebar>

<navbar></navbar>

<div class="container-fluid container-main-nav">
  <router-outlet></router-outlet>
</div>

<app-toast aria-live="polite" aria-atomic="true"></app-toast>

