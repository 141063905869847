import { Component, Input } from '@angular/core';
import { LanguageService } from '../../core';
import { ImageItem } from '../../models';
import { CanvasService } from '../../services';
import { BaseComponent } from '../../shared';



/**
 * Component for x-ray image garage modal.
 */
@Component({
	selector: 'image-garage',
	templateUrl: './image-garage.component.html',
	styles: [`
		:host {
			height: 100%;
		}
	`]
})
export class ImageGarageComponent extends BaseComponent {

	@Input() edit: boolean;
	@Input() imageList: ImageItem[];

	constructor(
		private langSrv: LanguageService,
		private canvasSrv: CanvasService
	) {
		super(langSrv);
	}

	get leftLabel(): string {
		return this.canvasSrv.leftLabel;
	}
	get rightLabel(): string {
		return this.canvasSrv.rightLabel;
	}
	get topLabel(): string {
		return this.canvasSrv.topLabel;
	}
	get bottomLabel(): string {
		return this.canvasSrv.bottomLabel;
	}

	/**
	* Fit to view the view.
	*/
	fitToView(): void {
		this.canvasSrv.dispatch("fitToView");
	}

	/**
	* Zoom in the view.
	*/
	zoomIn(): void {
		this.canvasSrv.dispatch("zoomIn");
	}

	/**
	* Zoom out the view.
	*/
	zoomOut(): void {
		this.canvasSrv.dispatch("zoomOut");
	}

}
