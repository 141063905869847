<div class="box-menu-settings active position-relative">

	<div data-testid="SUBSYS2_CALIBRATION_DISABLED_BOX" class="panel-disabled" *ngIf="disabled">
	</div>

	<div class="row-box pt-4">
		<h4 data-testid="SUBSYS2_CALIBRATION_TITLE_TXT">{{labels.IMAGE_CALIBRATION_COMPONENT_TITLE}}</h4>
	</div>

	<div class="divider-box"></div>

	<form [formGroup]="form" class="categorization-column">

		<div class="row-box mt-4">
			<div class="form-group pb-4"
				[ngClass]="{'is-valid': form?.controls.calibrationTool.valid && form?.controls.calibrationTool.touched, 'is-invalid': form?.controls.calibrationTool.invalid && form?.controls.calibrationTool.touched}">
				<label data-testid="SUBSYS2_CALIBRATION_TOOL_LBL" for="calibrationTool" class="mb-0">
					{{labels.IMAGE_CALIBRATION_COMPONENT_CALIBRATION_TOOL}} &nbsp; *
				</label>
				<div class="custom-control custom-radio custom-control-inline mr-3">
					<input data-testid="SUBSYS2_CALIBRATION_TOOL_LINE_INPUT" type="radio" id="calibByLine" name="calibrationTool"
						class="custom-control-input" formControlName="calibrationTool" (change)="setLine()" value="line"
						[attr.disabled]="disabled ? true : null"
						[ngClass]="{'is-valid': form?.controls.calibrationTool.valid && form?.controls.calibrationTool.touched, 'is-invalid': form?.controls.calibrationTool.invalid && form?.controls.calibrationTool.touched}" />
					<label data-testid="SUBSYS2_CALIBRATION_TOOL_LINE_LBL" class="custom-control-label" for="calibByLine">
						{{labels.IMAGE_CALIBRATION_COMPONENT_LINE}}
					</label>
				</div>
				<div class="custom-control custom-radio custom-control-inline mr-3">
					<input data-testid="SUBSYS2_CALIBRATION_TOOL_CIRCLE_INPUT" type="radio" id="calibByCircle"
						name="calibrationTool" class="custom-control-input" formControlName="calibrationTool" (change)="setCircle()"
						value="circle" [attr.disabled]="disabled ? true : null"
						[ngClass]="{'is-valid': form?.controls.calibrationTool.valid && form?.controls.calibrationTool.touched, 'is-invalid': form?.controls.calibrationTool.invalid && form?.controls.calibrationTool.touched}" />
					<label data-testid="SUBSYS2_CALIBRATION_TOOL_CIRCLE_LBL" class="custom-control-label" for="calibByCircle">
						{{labels.IMAGE_CALIBRATION_COMPONENT_CIRCLE}}
					</label>
				</div>
				<div class="custom-control custom-radio custom-control-inline mr-0" *ngIf="isRingsToolVisible">
					<input data-testid="SUBSYS2_CALIBRATION_TOOL_RINGS_INPUT" type="radio" id="calibByRings"
						name="calibrationTool" class="custom-control-input" formControlName="calibrationTool" (change)="setRings()"
						value="rings" [attr.disabled]="disabled ? true : null"
						[ngClass]="{'is-valid': form?.controls.calibrationTool.valid && form?.controls.calibrationTool.touched, 'is-invalid': form?.controls.calibrationTool.invalid && form?.controls.calibrationTool.touched}" />
					<label data-testid="SUBSYS2_CALIBRATION_TOOL_RINGS_LBL" class="custom-control-label" for="calibByRings">
						{{labels.IMAGE_CALIBRATION_COMPONENT_RINGS}}
					</label>
				</div>
				<div class="invalid-feedback" *ngIf="form?.controls.calibrationTool.invalid">
					{{ form?.controls.calibrationTool["errorMessage"] }}
				</div>
			</div>
		</div>

		<ng-container *ngIf="form?.value.calibrationTool == 'line' || form?.value.calibrationTool == 'circle'">

			<div class="row-box">
				<div class="form-group pb-4">
					<label data-testid="SUBSYS2_CALIBRATION_VALUE_LBL" for="" class="mb-0">
						{{labels.IMAGE_CALIBRATION_COMPONENT_CALIBRATION_VALUE}} &nbsp; *
					</label>
					<input data-testid="SUBSYS2_CALIBRATION_VALUE_INPUT" #calibrationInput type="number"
						class="form-control col-8" placeholder="{{labels.IMAGE_CATEGORIZATION_COMPONENT_CALIBRATION_VALUE}}"
						formControlName="calibrationValue" (keydown)="preventScientificNotation($event)"
						(input)="preventScientificNotation($event)"
						[readonly]="disabled || form?.controls.calibrationTool.invalid || isPointsRegistering"
						[ngClass]="{'is-valid': form?.controls.calibrationValue.valid && form?.controls.calibrationValue.touched,  'is-invalid': form?.controls.calibrationValue.invalid && form?.controls.calibrationValue.touched }">
					<div class="invalid-feedback"
						*ngIf="form?.controls.calibrationValue.invalid && form?.controls.calibrationValue.touched">
						{{form?.controls.calibrationValue["errorMessage"]}}</div>
				</div>
			</div>

			<div class="row-box wrapper-img-axis justify-content-center">
				<div>
					<img class="squared" [src]="helpImgLine" alt="line-gif" *ngIf="form?.value.calibrationTool == 'line'">
					<img class="squared" [src]="helpImgCircle" alt="circle-gif" *ngIf="form?.value.calibrationTool == 'circle'">
				</div>
			</div>

		</ng-container>

		<ng-container *ngIf="form?.value.calibrationTool == 'rings'">

			<div class="row-box" *ngIf="!form?.value.proximalRing || !form?.value.distalRing">
				<div class="box-suggestion">
					<p data-testid="SUBSYS2_CALIBRATION_TOOLINFO_TXT">
						{{labels.IMAGE_CALIBRATION_COMPONENT_RINGS_CHOOSE_MESSAGE}}
					</p>
				</div>
			</div>

			<div class="row-box">

				<div class="form-group pb-0">
					<label data-testid="SUBSYS2_CALIBRATION_RING_PROX_LBL">
						{{ labels.IMAGE_CALIBRATION_COMPONENT_RING_PROX }}
					</label>
					<ring-selection data-testid="SUBSYS2_CALIBRATION_RING_PROX_DROPDOWN"
						placeholder="{{ labels.IMAGE_CALIBRATION_COMPONENT_RING_PROX_PLACEHOLDER }}" [list]="ringsList"
						[ngModel]="initProxRing" (ngModelChange)="updateProxRing($event)" [ngModelOptions]="{standalone: true}"
						ngDefaultControl [disabled]="disabled || isRingLoading" [class.disabled]="disabled || isRingLoading"
						[isFoot]="isFoot">
					</ring-selection>
				</div>
			</div>

			<div class="row-box pb-4">
				<div class="form-group pb-0">
					<label data-testid="SUBSYS2_CALIBRATION_RING_DIST_LBL">
						{{ labels.IMAGE_CALIBRATION_COMPONENT_RING_DIST }}
					</label>
					<ring-selection data-testid="SUBSYS2_CALIBRATION_RING_DIST_DROPDOWN"
						placeholder="{{ labels.IMAGE_CALIBRATION_COMPONENT_RING_DIST_PLACEHOLDER }}" [list]="ringsList"
						[ngModel]="initDistRing" (ngModelChange)="updateDistRing($event)" [ngModelOptions]="{standalone: true}"
						ngDefaultControl [disabled]="disabled || isRingLoading" [class.disabled]="disabled || isRingLoading"
						[isFoot]="isFoot">
					</ring-selection>
				</div>
			</div>

			<ng-container *ngIf="form?.value.proximalRing && form?.value.distalRing">
				<div class="row-box">
					<div class="box-suggestion">
						<p data-testid="SUBSYS2_CALIBRATION_TOOLINFO_TXT">
							{{labels.IMAGE_CALIBRATION_COMPONENT_RINGS_CALIBRATE_MESSAGE?.replace('{{1}}', ringCalibData?.refType)}}
						</p>
					</div>
				</div>
				<div class="row-box wrapper-img-axis justify-content-center">
					<div>
						<img class="squared" [src]="helpImgRing" alt="rings-gif">
					</div>
				</div>
			</ng-container>

		</ng-container>

		<div class="mt-auto">
			<div class="divider-box"></div>
			<div class="wrapper-btn-confirm">
				<button data-testid="SUBSYS2_CALIBRATION_CONFIRM_BTN" type="button" class="btn btn-primary"
					[disabled]="disabled || form.invalid || isPointsRegistering" (click)="confirm()">
					{{labels.IMAGE_CALIBRATION_COMPONENT_CONFIRM_BTN}}
				</button>
			</div>
		</div>

	</form>

</div>