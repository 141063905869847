<div class="boneMap_container" data-testid="SUBSYS2_PLANFORM_BONEMAP_BOX">
	<maphilight id="statesMap" [config]="config">

		<img src="assets/images/bilateralPlan.png" alt="skeleton" style="width: 100%;">

		<map name="image-map">
			<area shape="poly" alt="bone area" *ngFor="let item of boneMap | keyvalue" [id]="item.key"
				[coords]="item.value.coords">
		</map>

	</maphilight>


	<div class="imageMap_container" *ngIf="!disabled" data-testid="SUBSYS2_PLANFORM_BONEMAP_POINTS_BOX">

		<div class="markers" *ngFor="let item of boneMap | keyvalue" [attr.data-testid]="item.value.testId"
			[ngStyle]="{'grid-column': item.value.gridColumn, 'grid-row': item.value.gridRow}"
			(click)="highlightArea($event, item.value.site)">
		</div>

	</div>
</div>