<div ngbDropdown container="body">
	<i data-testid="SUBSYS2_PLANLIST_ACTION_VIEWCASE" class="material-icons action"
		title="{{labels.PLAN_ACTION_COMPONENT_VIEW}}" (click)="view()" *ngIf="isViewVisible">visibility</i>
	<i data-testid="SUBSYS2_PLANLIST_ACTION_EDIT" class="material-icons action"
		title="{{labels.PLAN_ACTION_COMPONENT_EDIT}}" (click)="edit()" *ngIf="isEditVisible"
		[class.disabled]="isReadonly">edit</i>
	<i data-testid="SUBSYS2_PLANLIST_ACTION_DELETE" class="material-icons action"
		title="{{labels.PLAN_ACTION_COMPONENT_DELETE}}" (click)="openDeleteModal()" *ngIf="isDeleteVisible"
		[class.disabled]="isReadonly">delete_outline</i>
	<i data-testid="SUBSYS2_PLANLIST_ACTION_SEND" class="material-icons action"
		title="{{labels.PLAN_ACTION_COMPONENT_SEND}}" (click)="openSendModal()">send</i>
	<i data-testid="SUBSYS2_PLANLIST_ACTION_MYHEXPLAN_SETTINGS" class="material-icons action"
		title="{{labels.PLAN_ACTION_COMPONENT_MYHEXPLAN_SETTINGS}}" *ngIf="isMyHexPlanSettingsVisible"
		(click)="viewPlanSettings()" [class.disabled]="isReadonly">settings_applications</i>
	<i data-testid="SUBSYS2_PLANLIST_ACTION_POSTOPCREATE" class="material-icons action fake-material-icon postoperative"
		title="{{labels.PLAN_ACTION_COMPONENT_POSTOP_CREATE}}" *ngIf="isPostopCreateVisible"
		(click)="openPostopCreateModal()" [class.disabled]="isReadonly"></i>


	<i data-testid="SUBSYS2_PLANLIST_ACTION_ANALYSIS" class="material-icons action"
		[ngClass]="{'disabled': !isAnalysisEnabled }" title="{{labels.PLAN_ACTION_COMPONENT_GO_RAY}}"
		(click)="navigateToApp()" *ngIf="isAnalysisVisible">square_foot</i>
</div>