/**
* Request for plan creation
*/
export interface PlanRequest {
	patientGuid: string;
	number: string;
	name?: string;
	side: AnatomicalSideEnum;
	type: string;
	boneType: BoneTypeEnum;
	referenceType: ReferenceTypeEnum;
	isPostOperative: boolean;
	surgeryDate?: Date;
	notes?: string;
	apImageGuid?: string;
	ltImageGuid?: string;
	hasIWrench?: boolean;
}

export enum AnatomicalSideEnum {
	Left = "Left",
	Right = "Right"
}

export enum BoneTypeEnum {
	LongLeg = "Long Leg",
	Femur = "Femur",
	Tibia = "Tibia",
	Ankle = "Ankle",
	Forefoot = "Forefoot",
	Hindfoot = "Hindfoot"
}

export enum ReferenceTypeEnum {
	Proximal = "Proximal",
	Distal = "Distal"
}

export enum BoneViewEnum {
	AP = "AP",
	LAT = "LAT"
}

export enum PlanTypeEnum {
	Deformity = "Deformity",
	Fracture = "Fracture"
}
