import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbAccordion } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { LanguageService } from '../../core';
import { DailyEventItem, StrutEventEnum, StrutEventExtended } from '../../models';
import { DashboardService } from '../../services';
import { BaseComponent } from '../../shared';


/**
 * Component for list of events in dashboard.
 */
@Component({
	selector: 'dashboard-events',
	templateUrl: './dashboard-events.component.html'
})
export class DashboardEventsComponent extends BaseComponent implements OnInit, OnDestroy {

	@ViewChild(NgbAccordion) accordion: NgbAccordion;

	events: DailyEventItem[];
	date: Date;
	openedPanelIds: string;

	readonly PANEL_ID_PREFIX = 'event-panel-';

	private _eventsSub: Subscription;
	private _dateSub: Subscription;

	constructor(
		private langSrv: LanguageService,
		private dashbrdSrv: DashboardService
	) {
		super(langSrv);
	}

	ngOnInit(): void {
		this._eventsSub = this.dashbrdSrv.dailyEvents$.subscribe(list => {
			this.events = list;
			this.updatePanels();
		});
		this._dateSub = this.dashbrdSrv.selectedDate$.subscribe(res => this.date = res);
	}

	private updatePanels(): void {
		this.openedPanelIds = "";
		this.events?.forEach((e, index) => this.openedPanelIds += `${this.PANEL_ID_PREFIX}${index},`);
		this.accordion?.expandAll();
	}

	/**
	 * Get css class according to event type.
	 */
	eventTypeClass(ev: StrutEventExtended): string {
		switch (ev.type) {
			case StrutEventEnum.ADJUST: return 'strut-adjust';
			case StrutEventEnum.CHANGE: return 'strut-change';
			default: return '';
		}
	}

	/**
	 * Get formatted description of event item.
	 */
	eventItemDesc(item: StrutEventExtended): string {
		return `${item.type} - ${item.planName}, Frame ${item.frameID}`;
	}

	/**
	 * Navigate to tlhex report page.
	 */
	goToReport(planId: string): void {
		window.location.href = environment.tlhexSite + '?caseGuid=' + planId;
	}


	ngOnDestroy(): void {
		this._eventsSub?.unsubscribe();
		this._dateSub?.unsubscribe();
	}
}
