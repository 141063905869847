import { Component, Input, OnDestroy } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { LanguageService, SearchItems } from '../../core';
import { ClonedListModalComponent, ReceivedCloneModalComponent } from '../../plan';
import { ListService, PlanService, UserService } from '../../services';
import { BaseComponent, ConfirmationComponent } from '../../shared';


/**
* Received plan actions component on received plan table
*/
@Component({
	selector: 'received-action',
	templateUrl: './received-action.component.html'
})
export class ReceivedActionComponent extends BaseComponent implements OnDestroy {

	@Input() set item(val: SearchItems) {
		this.planGuid = val.planId;
		this.planId = val.planNumber;
		this.planNotes = val.caseNotes;
		this._usrSub$ = this.userSrv.isCustomerCareOtherUser(val.userId).subscribe(res => this.isReadonly = res);
	}

	planGuid: string;
	planId: string;
	planNotes: string;
	isReadonly: boolean;

	private _usrSub$: Subscription;

	constructor(
		private langSrv: LanguageService,
		private modalSrv: NgbModal,
		private planSrv: PlanService,
		private listSrv: ListService,
		private userSrv: UserService
	) {
		super(langSrv);
	}

	/**
	* Open modal to clone received plan
	*/
	openCloneModal(): void {
		if (!this.isReadonly) {
			const modalRef: NgbModalRef = this.modalSrv.open(ReceivedCloneModalComponent, {
				centered: true, backdrop: 'static', windowClass: 'modal-form', size: 'lg'
			});
			(modalRef.componentInstance as ReceivedCloneModalComponent).planGuid = this.planGuid;
			(modalRef.componentInstance as ReceivedCloneModalComponent).planNotes = this.planNotes;
		}
	}

	/**
	* Open modal to view cloned plans
	*/
	openDetailModal(): void {
		const modalRef: NgbModalRef = this.modalSrv.open(ClonedListModalComponent, {
			centered: true, backdrop: 'static', windowClass: 'modal-list'
		});
		(modalRef.componentInstance as ClonedListModalComponent).originalPlanGuid = this.planGuid;
		(modalRef.componentInstance as ClonedListModalComponent).originalPlanId = this.planId;
	}

	/**
	* Open confirmation modal before delete plan
	*/
	openDeleteModal(): void {
		if (!this.isReadonly && !this.modalSrv.hasOpenModals()) {
			const modalRef: NgbModalRef = this.modalSrv.open(ConfirmationComponent, {
				centered: true, backdrop: 'static'
			});
			(modalRef.componentInstance as ConfirmationComponent).config = {
				title: this.labels['RECEIVED_ACTION_COMPONENT_DELETE_MODAL_TITLE'],
				message: this.labels['RECEIVED_ACTION_COMPONENT_DELETE_MODAL_MESSAGE'],
				cancelBtn: this.labels['RECEIVED_ACTION_COMPONENT_DELETE_MODAL_NO'],
				confirmBtn: this.labels['RECEIVED_ACTION_COMPONENT_DELETE_MODAL_YES']
			};
			modalRef.result.then(() => this.delete()).catch(error => false);
		}
	}

	private delete(): void {
		this.planSrv.deletePlan(this.planGuid).subscribe(res => this.listSrv.search());
	}


	ngOnDestroy(): void {
		this._usrSub$?.unsubscribe();
	}
}
