<input hidden
       ngbDatepicker
       [autoClose]="'outside'"
       [placement]="placement"
       [positionTarget]="positionTarget"
       (dateSelect)="onDateSelection($event)"
       [displayMonths]="2"
       [dayTemplate]="t"
       outsideDays="hidden"
       [minDate]="MIN_DATE"
       [maxDate]="MAX_DATE"
       [startDate]="fromDate">

<ng-template #t let-date let-focused="focused">
  <span class="custom-day"
        [class.focused]="focused"
        [class.range]="isRange(date)"
        [class.faded]="isHovered(date) || isInside(date)"
        (mouseenter)="hoveredDate = date"
        (mouseleave)="hoveredDate = null">
    {{ date.day }}
  </span>
</ng-template>
