import { maxDate, maxLength, minDate, pattern, prop, required } from '@rxweb/reactive-form-validators';
import { PracticeInfo, SURGEON, UserProfileCountry, US_CODE } from '../core';

const specialChars: RegExp = /^[^<>%#&?,]+$/;
const datePattern: RegExp = /^[1-9]\d{3}-\d\d-\d\d$/;


/**
* Edit profile form model
*/
export class ProfileForm {

  @prop()
  email: string;

  @prop()
  firstName: string;

  @prop()
  lastName: string;

  @prop()
  country: UserProfileCountry;

  @prop()
  roleInfo: string;

  @prop()
  practice?: PracticeInfo;

  @required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_DATE_OF_BIRTH" })
  @maxDate({ value: new Date(new Date().setFullYear(new Date().getFullYear() - 18)), operator: '<=', messageKey: "VALIDATION_MESSAGE_MAXDATE_DATE_OF_BIRTH" })
  @minDate({ value: new Date(1899, 12, 1), operator: '>=', messageKey: "VALIDATION_MESSAGE_INVALID_DATE_OF_BIRTH" })
  dateOfBirth: Date;

  @prop()
  dateOfBirthFormat: string;

  @required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_DISTRIBUTOR", conditionalExpression: (x: ProfileForm) => x.roleInfo && x.roleInfo === SURGEON })
  @maxLength({ value: 200, messageKey: "VALIDATION_MESSAGE_MAXLENGTH_DISTRIBUTOR" })
  @pattern({ expression: { 'noSpecialChars': specialChars }, messageKey: "VALIDATION_MESSAGE_SPECIALCHARS_DISTRIBUTOR" })
  distributor?: string;

  @required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_HOSPITAL", conditionalExpression: (x: ProfileForm) => x.roleInfo && x.roleInfo === SURGEON })
  @maxLength({ value: 200, messageKey: "VALIDATION_MESSAGE_MAXLENGTH_HOSPITAL" })
  @pattern({ expression: { 'noSpecialChars': specialChars }, messageKey: "VALIDATION_MESSAGE_SPECIALCHARS_HOSPITAL" })
  hospital?: string;

  @required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_COMPANY", conditionalExpression: (x: ProfileForm) => x.roleInfo && x.roleInfo !== SURGEON })
  @maxLength({ value: 200, messageKey: "VALIDATION_MESSAGE_MAXLENGTH_COMPANY" })
  company?: string;

  @required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_ADDRESS" })
  @maxLength({ value: 200, messageKey: "VALIDATION_MESSAGE_MAXLENGTH_ADDRESS" })
  address: string;

  @required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_CITY" })
  @maxLength({ value: 50, messageKey: "VALIDATION_MESSAGE_MAXLENGTH_CITY" })
  @pattern({ expression: { 'noSpecialChars': specialChars }, messageKey: "VALIDATION_MESSAGE_SPECIALCHARS_CITY" })
  city: string;

  @required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_PROVINCE", conditionalExpression: (x: ProfileForm) => x.country && x.country.code !== US_CODE })
  @maxLength({ value: 200, messageKey: "VALIDATION_MESSAGE_MAXLENGTH_PROVINCE" })
  @pattern({ expression: { 'noSpecialChars': specialChars }, messageKey: "VALIDATION_MESSAGE_SPECIALCHARS_PROVINCE" })
  stateProvince?: string;

  @required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_PROVINCE", conditionalExpression: (x: ProfileForm) => x.country && x.country.code === US_CODE })
  usStateProvince?: string;

  @required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_POSTAL_CODE" })
  @maxLength({ value: 50, messageKey: "VALIDATION_MESSAGE_MAXLENGTH_POSTAL_CODE" })
  @pattern({ expression: { 'noSpecialChars': specialChars }, messageKey: "VALIDATION_MESSAGE_SPECIALCHARS_POSTAL_CODE" })
  postalCode: string;

  @required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_PHONE" })
  @maxLength({ value: 50, messageKey: "VALIDATION_MESSAGE_MAXLENGTH_PHONE" })
  @pattern({ expression: { 'noSpecialChars': specialChars }, messageKey: "VALIDATION_MESSAGE_SPECIALCHARS_PHONE" })
  officePhone: string;

  @maxLength({ value: 50, messageKey: "VALIDATION_MESSAGE_MAXLENGTH_MOBILE" })
  @pattern({ expression: { 'noSpecialChars': specialChars }, messageKey: "VALIDATION_MESSAGE_SPECIALCHARS_MOBILE" })
  mobilePhone?: string;

}
