import { Pipe, PipeTransform } from '@angular/core';
import { BoneTypeEnum, LanguageService } from '../../core';


/**
 * Pipe to replace long leg substring in input string
 */
@Pipe({
  name: 'replaceLongLeg'
})
export class ReplaceLongLegPipe implements PipeTransform {

  constructor(private langSrv: LanguageService) { }

  transform(text: string): string {
    if (text && text.includes(BoneTypeEnum.LongLeg)) {
      return text.replace(BoneTypeEnum.LongLeg, this.langSrv.getLabels()["BONE_TYPE_LONG_LEG_LABEL"]);
    }
    return text;
  }

}
