import { AfterViewInit, Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Subscription } from 'rxjs';
import { LanguageService, SearchFields, SearchItems, SearchSortOptions } from '../../core';
import { ListService } from '../../services';
import { BaseComponent } from '../../shared';
import { SortEvent, SortableHeaderComponent } from '../sortable-header';


/**
* Received plans table component
*/
@Component({
	selector: 'received-table',
	templateUrl: './received-table.component.html'
})
export class ReceivedTableComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {

	@ViewChildren(SortableHeaderComponent) headers: QueryList<SortableHeaderComponent>;
	activeColumn: string;

	collectionSize: number;
	receivedList: SearchItems[] = [];

	searchResultSubscription$: Subscription;

	fields = SearchFields;

	constructor(
		private langSrv: LanguageService,
		private listSrv: ListService
	) {
		super(langSrv);
	}

	ngOnInit() {
		this.listSrv.resetSearchResult();
		this.initTable();
		this.searchResultSubscription$ = this.listSrv.getSearchResult().subscribe(list => {
			this.collectionSize = list ? list.count : 0;
			this.receivedList = list ? list.items : [];
		});
	}

	ngAfterViewInit() {
		setTimeout(() => {
			const sortOrder: SearchSortOptions = this.listSrv.getSortOrder();
			if (!!sortOrder) {
				this.headers.find(header => header.name == sortOrder.name).direction = sortOrder.desc ? 'desc' : 'asc';
				this.activeColumn = sortOrder.name;
			}
		});
	}

	private initTable() {
		this.listSrv.resetReceivedSearch();
	}

	/**
	* Update column sort order and sort list 
	*/
	onSort({ column, direction }: SortEvent) {
		// resetting other headers
		this.headers.forEach(header => header.name !== column ? header.reset() : null);
		// sorting
		this.activeColumn = direction === 'asc' || direction === 'desc' ? column : null;
		this.listSrv.sort(column, direction);
	}



	ngOnDestroy() {
		if (this.searchResultSubscription$) {
			this.searchResultSubscription$.unsubscribe();
		}
		this.listSrv.resetSearchResult();
	}

}
