<nav>

	<div class="nav-group" ngbDropdown container="body" placement="right-top">
		<a data-testid="SUBSYS2_SIDEBAR_MENU_TOGGLE" class="nav-link" href="" onclick="return false;" ngbDropdownToggle>
			<span class="material-icons">account_circle</span>
		</a>

		<div class="dropdown-menu" ngbDropdownMenu>
			<a data-testid="SUBSYS2_SIDEBAR_MENU_EDITPROFILE" href="" onclick="return false;" class="dropdown-item"
				(click)="editProfile()">
				{{ labels.SIDEBAR_COMPONENT_EDIT_PROFILE }}
			</a>
			<a data-testid="SUBSYS2_SIDEBAR_MENU_CHANGEPWD" class="dropdown-item" (click)="changePassword()">
				{{ labels.SIDEBAR_COMPONENT_CHANGE_PASSWORD }}
			</a>
			<a data-testid="SUBSYS2_SIDEBAR_MENU_LEGALTERMS" href="" onclick="return false;" class="dropdown-item"
				(click)="openLegalTerms()">
				{{ labels.SIDEBAR_COMPONENT_EULA_POLICY }}
			</a>
			<a data-testid="SUBSYS2_SIDEBAR_MENU_ABOUT" href="" onclick="return false;" class="dropdown-item"
				(click)="openAboutPage()">
				{{ labels.SIDEBAR_COMPONENT_ABOUT }}
			</a>
			<a data-testid="SUBSYS2_SIDEBAR_MENU_COOKIE" href="" onclick="return false;" class="dropdown-item"
				(click)="openCookieModal()">
				{{ labels.SIDEBAR_COMPONENT_COOKIE }}
			</a>
			<div data-testid="SUBSYS2_SIDEBAR_MENU_VERSION" class="simple-text" *ngIf="envName != 'production'">
				{{ buildVersion }}
			</div>
			<a data-testid="SUBSYS2_SIDEBAR_MENU_LOGOUT" href="" onclick="return false;" class="dropdown-item"
				(click)="logout()">
				{{ labels.SIDEBAR_COMPONENT_LOGOUT }}
			</a>
		</div>
	</div>

	<a data-testid="SUBSYS2_SIDEBAR_DASHBOARD_BTN" class="nav-link" style="bottom: 50%" [routerLink]="'/dashboard'"
		routerLinkActive="active">
		<i class="material-icons-outlined">dashboard</i>
	</a>

</nav>