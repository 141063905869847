import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbTooltipModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { IntegrationRepository, PlanRepository } from '../core';
import { GalleryModule } from '../gallery';
import { PlanFormService, PlanService, ReceivedService, SendService } from '../services';
import { SharedModule } from '../shared';
import { BoneSelectionComponent, MaphilightComponent } from './bone-selection';
import { ClonedListModalComponent } from './cloned-list-modal';
import { PlanComponent } from './plan';
import { PlanBoneComponent } from './plan-bone';
import { PlanDataComponent } from './plan-data';
import { PlanImageComponent } from './plan-image';
import { PlanSendModalComponent } from './plan-send-modal';
import { ReceivedCloneModalComponent } from './received-clone-modal';

@NgModule({
	declarations: [
		ReceivedCloneModalComponent,
		PlanSendModalComponent,
		ClonedListModalComponent,
		PlanComponent,
		PlanDataComponent,
		PlanBoneComponent,
		PlanImageComponent,
		MaphilightComponent,
		BoneSelectionComponent
	],
	entryComponents: [
		ReceivedCloneModalComponent,
		PlanSendModalComponent,
		ClonedListModalComponent
	],
	exports: [
		PlanComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		RxReactiveFormsModule,
		SharedModule,
		DragDropModule,
		NgbTypeaheadModule,
		NgbTooltipModule,
		GalleryModule
	],
	providers: [
		PlanRepository,
		PlanService,
		ReceivedService,
		SendService,
		IntegrationRepository,
		PlanFormService
	]
})
export class PlanModule { }
