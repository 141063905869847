<div class="box-menu-settings active">

	<div class="row-box pt-4">
		<h4 data-testid="SUBSYS2_EDITTOOLS_TITLE_TXT">{{labels.IMAGE_EDITING_TOOLS_COMPONENT_TITLE }}</h4>
	</div>

	<div class="divider-box"></div>

	<div data-testid="SUBSYS2_EDITTOOLS_CALIBRATE_BTN" class="row-box clickable" (click)="calibrate()">
		<i class="material-icons">gps_fixed</i>
		<span>{{ labels.IMAGE_EDITING_TOOLS_COMPONENT_CALIBRATE }}</span>
	</div>

</div>