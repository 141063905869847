import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AiRepository, CircleDetection } from "../core";


/**
 * This service handles data from ai services.
 */
@Injectable()
export class AiService {

	constructor(private aiRepo: AiRepository) { }

	circleDetection(image: File): Observable<CircleDetection> {
		return this.aiRepo.circleDetection(image);
	}
}