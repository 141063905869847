import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IAiRepository } from './Iai.repository';
import { CircleDetection } from './models';


@Injectable()
export class AiRepository implements IAiRepository {

	constructor(private httpClient: HttpClient) { }

	circleDetection(image: File): Observable<CircleDetection> {
		const formData = new FormData();
		formData.append('image', image);
		return this.httpClient.post<CircleDetection>(environment.domainAiAPI + "/CircleDetection", formData);
	}
}
