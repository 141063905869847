import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { NgbCollapseModule, NgbDropdownModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthGuardWithForcedLogin } from '@ortho-next/auth-core';
import { TlhexRepository } from '../core';
import { ListService, TlhexService } from '../services';
import { SharedModule } from '../shared';
import { FiltersComponent } from './filters';
import { ListComponent } from './list.component';
import { PaginationComponent } from './pagination';
import { PatientActionComponent } from './patient-action';
import { PatientTableComponent } from './patient-table';
import { PlanActionComponent } from './plan-action';
import { PlanTableComponent } from './plan-table';
import { ReceivedActionComponent } from './received-action';
import { ReceivedTableComponent } from './received-table';
import { SortableHeaderComponent } from './sortable-header';

const routes: Routes = [
	{
		path: '', component: ListComponent, children: [
			{ path: '', redirectTo: 'plans', pathMatch: 'full' },
			{ path: 'patients', component: PatientTableComponent, canActivate: [AuthGuardWithForcedLogin] },
			{ path: 'plans', component: PlanTableComponent, canActivate: [AuthGuardWithForcedLogin] },
			{ path: 'received', component: ReceivedTableComponent, canActivate: [AuthGuardWithForcedLogin] }
		], canActivate: [AuthGuardWithForcedLogin]
	}
];

@NgModule({
	declarations: [
		ListComponent,
		PatientTableComponent,
		SortableHeaderComponent,
		PaginationComponent,
		PatientActionComponent,
		PlanActionComponent,
		PlanTableComponent,
		FiltersComponent,
		ReceivedTableComponent,
		ReceivedActionComponent
	],
	exports: [
		ListComponent,
		RouterModule
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule.forChild(routes),
		SharedModule,
		NgbDropdownModule,
		NgbPaginationModule,
		NgbCollapseModule
	],
	providers: [
		ListService,
		TlhexRepository,
		TlhexService
	]
})
export class ListModule { }
