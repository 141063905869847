import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LanguageService } from '../core';
import { ListService } from '../services';
import { BaseComponent } from '../shared';

/**
* Patient and plan list page component
*/
@Component({
	selector: 'app-list',
	templateUrl: './list.component.html'
})
export class ListComponent extends BaseComponent implements OnInit, OnDestroy {

	isFiltersCollapsed: boolean;

	private _collapsedSub: Subscription;

	constructor(
		private langSrv: LanguageService,
		private listSrv: ListService
	) {
		super(langSrv);
	}

	ngOnInit(): void {
		this._collapsedSub = this.listSrv.isCollapsed.subscribe(val => this.isFiltersCollapsed = val);
	}

	/**
	 * Update filter collapsed status.
	 */
	updateCollapsed(val: boolean): void {
		this.listSrv.collapse(val);
	}


	ngOnDestroy(): void {
		this._collapsedSub?.unsubscribe();
	}

}
