<!-- First row wrap Breadcrumb + Title + Alert -->
<div class="row row-header">
	<div class="col">

		<!-- Breadcrumbs -->
		<div class="breadcrumbs">
			<a data-testid="SUBSYS2_PATFORM_BACK_BTN" href="#" onclick="return false;" (click)="returnToPreviousPage()">
				<i class="material-icons">arrow_back</i>
				{{ labels.PATIENT_CREATION_COMPONENT_PREVIOUS_PAGE }}
			</a>
		</div>

		<!-- Page title -->
		<h1 data-testid="SUBSYS2_PATFORM_TITLE_TXT">
			{{ isEdit ? labels.PATIENT_CREATION_COMPONENT_TITLE_EDIT : labels.PATIENT_CREATION_COMPONENT_TITLE_CREATE }}
		</h1>

	</div>
</div>

<!-- Second row wrap all contents and columns -->
<div class="row row-contents">

	<div class="col-lg-8">
		<!-- Left column -->

		<patient-form></patient-form>

	</div>

	<div class="col-lg-4">
		<!-- Right column -->
		<patient-attachment [isDragDisabled]="true" [isDisabled]="isPatReadonly"></patient-attachment>

	</div>

</div>