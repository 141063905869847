import { contains, maxLength, pattern, prop, required } from "@rxweb/reactive-form-validators";
import { SearchItems } from "../core";

const specialChars: RegExp = /^[^<>%#&?,]+$/;


/**
* Form model to clone received plan
*/
export class ReceivedCloneForm {

	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_PATIENT", conditionalExpression: (x: ReceivedCloneForm) => x.patientId == null || x.patientId == "" })
	existingPatient: SearchItems;

	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_PATIENTID", conditionalExpression: (x: ReceivedCloneForm) => x.existingPatient == null })
	@maxLength({ value: 50, messageKey: "VALIDATION_MESSAGE_MAXLENGTH_PATIENTID" })
	@pattern({ expression: { 'noSpecialChars': specialChars }, messageKey: "VALIDATION_MESSAGE_SPECIALCHARS_PATIENTID" })
	@contains({ value: "%", messageKey: "VALIDATION_MESSAGE_UNIQUE_PATIENTID", conditionalExpression: (x: ReceivedCloneForm) => !!x.patientIdNotUnique })
	patientId: string;

	@prop({ defaultValue: false })
	patientIdNotUnique?: boolean;

	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_PATIENT_INITIALS", conditionalExpression: (x: ReceivedCloneForm) => x.existingPatient == null })
	@maxLength({ value: 5, messageKey: "VALIDATION_MESSAGE_MAXLENGTH_PATIENT_INITIALS" })
	@pattern({ expression: { 'noSpecialChars': specialChars }, messageKey: "VALIDATION_MESSAGE_SPECIALCHARS_PATIENT_INITIALS" })
	patientInitials: string;

	@required()
	planGuid: string;

	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_PLANID" })
	@maxLength({ value: 50, messageKey: "VALIDATION_MESSAGE_MAXLENGTH_PLANID" })
	@pattern({ expression: { 'noSpecialChars': specialChars }, messageKey: "VALIDATION_MESSAGE_SPECIALCHARS_PLANID" })
	@contains({ value: "%", messageKey: "VALIDATION_MESSAGE_UNIQUE_PLANID", conditionalExpression: (x: ReceivedCloneForm) => !!x.planIdNotUnique })
	planId: string;

	@prop({ defaultValue: false })
	planIdNotUnique?: boolean;

	@maxLength({ value: 1000, messageKey: "VALIDATION_MESSAGE_MAXLENGTH_PLAN_NOTES" })
	@pattern({ expression: { 'noSpecialChars': specialChars }, messageKey: "VALIDATION_MESSAGE_SPECIALCHARS_PLAN_NOTES" })
	planNotes: string;
}
