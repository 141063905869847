import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from './base-component';
import { ConfirmationComponent } from './confirmation';
import { DatepickerComponent, DaterangepickerComponent } from './datepicker';
import { HelpComponent } from './help';
import { BoneTypeLabelPipe, BoneViewLabelPipe, ConvertDatePipe, ConvertLengthPipe, ConvertMassPipe, ReplaceLongLegPipe } from './pipe';
import { RingSelectionComponent } from './ring-selection';


@NgModule({
	declarations: [
		BaseComponent,
		ConfirmationComponent,
		ConvertMassPipe,
		ConvertLengthPipe,
		ConvertDatePipe,
		DatepickerComponent,
		DaterangepickerComponent,
		BoneTypeLabelPipe,
		ReplaceLongLegPipe,
		BoneViewLabelPipe,
		HelpComponent,
		RingSelectionComponent
	],
	exports: [
		BaseComponent,
		ConvertMassPipe,
		ConvertLengthPipe,
		ConvertDatePipe,
		DatepickerComponent,
		DaterangepickerComponent,
		BoneTypeLabelPipe,
		ReplaceLongLegPipe,
		BoneViewLabelPipe,
		RingSelectionComponent
	],
	entryComponents: [
		ConfirmationComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		NgbModule
	],
	providers: []
})
export class SharedModule { }
