<div ngbDropdown container="body">
	<i data-testid="SUBSYS2_PATLIST_ACTION_EDIT" class="material-icons action"
		title="{{labels.PATIENT_ACTION_COMPONENT_EDIT}}" [class.disabled]="isReadonly" (click)="edit()">
		assignment_ind
	</i>
	<i data-testid="SUBSYS2_PATLIST_ACTION_DELETE" class="material-icons action"
		title="{{labels.PATIENT_ACTION_COMPONENT_DELETE}}" [class.disabled]="isReadonly" *ngIf="!hasPublishedRevoked"
		(click)="openDeleteModal()">
		delete_outline
	</i>
	<i data-testid="SUBSYS2_PATLIST_ACTION_CREATEPLAN" class="material-icons action"
		title="{{labels.PATIENT_ACTION_COMPONENT_CREATE_PLAN}}" [class.disabled]="isReadonly" (click)="createPlan()">
		post_add
	</i>

	<ng-container *ngIf="hasPublishedRevoked">
		<i data-testid="SUBSYS2_PATLIST_ACTION_MYHEXPLAN_SETTINGS" class="material-icons action"
			title="{{labels.PATIENT_ACTION_COMPONENT_MYHEXPLAN_SETTINGS}}" [class.disabled]="isReadonly"
			(click)="viewPatientSettings()">
			manage_accounts
		</i>
		<i data-testid="SUBSYS2_PATLIST_ACTION_MYHEXPLAN_HISTORY" class="material-icons action"
			title="{{labels.PATIENT_ACTION_COMPONENT_MYHEXPLAN_HISTORY}}" [class.disabled]="isReadonly"
			(click)="viewPatientHistory()">
			history
		</i>
	</ng-container>
</div>