<!-- Modal -->
<div class="modal-content">
	<form [formGroup]="cloneForm">
		<div class="modal-header">
			<h5 data-testid="SUBSYS2_CLONEMODAL_TITLE_TXT" class="modal-title">
				{{ labels.RECEIVED_CLONE_MODAL_COMPONENT_TITLE }}
			</h5>
			<!--Serve per mettere il focus sul modale ed evitare l'autofocus sul primo bottone-->
			<button type="button"
				style="border-color: transparent; background-color: transparent; outline: none; cursor: inherit;"></button>
		</div>
		<div class="modal-body">

			<div class="panel alert mb-3">
				<div class="state-icon" (click)="confirm()"><i class="material-icons md-18">report_problem</i></div>
				<p data-testid="SUBSYS2_CLONEMODAL_LIABILITY_TXT">
					{{ labels.RECEIVED_CLONE_MODAL_COMPONENT_MESSAGE }}
				</p>
			</div>

			<div class="row">
				<div class="col-12">
					<h5 data-testid="SUBSYS2_CLONEMODAL_PATIENT_TXT">
						{{ labels.RECEIVED_CLONE_MODAL_COMPONENT_PATIENT_SELECT_MSG }}
					</h5>
				</div>
			</div>

			<div class="row" ngbTooltip="{{ labels.RECEIVED_CLONE_MODAL_COMPONENT_PATIENT_SELECT_TOOLTIP }}"
				#tooltipSelect="ngbTooltip">
				<div class="col-6">
					<div class="form-group">
						<label data-testid="SUBSYS2_CLONEMODAL_PATIENT_LBL" for="PatientList">
							{{ labels.RECEIVED_CLONE_MODAL_COMPONENT_PATIENT_LABEL }}
						</label>
						<input data-testid="SUBSYS2_CLONEMODAL_PATIENT_INPUT" type="text" autocomplete="off" class="form-control"
							id="PatientList" placeholder="{{ labels.RECEIVED_CLONE_MODAL_COMPONENT_PATIENT_SELECT }}"
							formControlName="existingPatient" [ngbTypeahead]="filterPatients" [focusFirst]="true"
							[inputFormatter]="patientFormatter" [resultFormatter]="patientFormatter" [editable]="false"
							#patientAutocomplete="ngbTypeahead" (focus)="showPatientList(patientAutocomplete)"
							(selectItem)="updatePatient($event)"
							[ngClass]="{'is-valid': cloneForm.controls.existingPatient.valid && cloneForm.controls.existingPatient.touched, 'is-invalid': cloneForm.controls.existingPatient.invalid && cloneForm.controls.existingPatient.touched}">
						<div class="invalid-feedback" *ngIf="cloneForm.controls.existingPatient.invalid">
							{{cloneForm.controls.existingPatient["errorMessage"]}}</div>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-12">
					<h5 data-testid="SUBSYS2_CLONEMODAL_NEWPATIENT_TXT">
						{{ labels.RECEIVED_CLONE_MODAL_COMPONENT_PATIENT_CREATE_MSG }}
					</h5>
				</div>
			</div>

			<div class="row" ngbTooltip="{{ labels.RECEIVED_CLONE_MODAL_COMPONENT_PATIENT_CREATE_TOOLTIP }}"
				#tooltipCreate="ngbTooltip">
				<div class="col-7">
					<div class="form-group mr-5">
						<label data-testid="SUBSYS2_CLONEMODAL_PATID_LBL" for="PatientId">
							{{ labels.RECEIVED_CLONE_MODAL_COMPONENT_PATIENT_ID }}
						</label>
						<input data-testid="SUBSYS2_CLONEMODAL_PATID_INPUT" type="text" autocomplete="nope" class="form-control"
							id="PatientId" formControlName="patientId"
							placeholder="{{ labels.RECEIVED_CLONE_MODAL_COMPONENT_PATIENT_ID }}"
							[ngClass]="{'is-valid': cloneForm.controls.patientId.valid && cloneForm.controls.patientId.touched, 'is-invalid': cloneForm.controls.patientId.invalid && cloneForm.controls.patientId.touched}"
							(input)="resetSelectedPatient()" (change)="trimPatientId()" />
						<div class="invalid-feedback" *ngIf="cloneForm.controls.patientId.invalid">
							{{cloneForm.controls.patientId["errorMessage"]}}</div>
					</div>
				</div>
				<div class="col-5">
					<div class="form-group">
						<label data-testid="SUBSYS2_CLONEMODAL_INITIALS_LBL" for="PatientInitials">
							{{ labels.RECEIVED_CLONE_MODAL_COMPONENT_PATIENT_INITIALS }}
						</label>
						<input data-testid="SUBSYS2_CLONEMODAL_INITIALS_INPUT" type="text" autocomplete="nope" class="form-control"
							id="PatientInitials" formControlName="patientInitials"
							placeholder="{{ labels.RECEIVED_CLONE_MODAL_COMPONENT_PATIENT_INITIALS }}"
							[ngClass]="{'is-valid': cloneForm.controls.patientInitials.valid && cloneForm.controls.patientInitials.touched, 'is-invalid': cloneForm.controls.patientInitials.invalid && cloneForm.controls.patientInitials.touched}"
							(input)="resetSelectedPatient()" (change)="trimPatientInitials()" />
						<div class="invalid-feedback" *ngIf="cloneForm.controls.patientInitials.invalid">
							{{cloneForm.controls.patientInitials["errorMessage"]}}</div>
					</div>
				</div>
			</div>

			<hr />

			<div class="row">
				<div class="col-7">
					<div class="form-group">
						<label data-testid="SUBSYS2_CLONEMODAL_PLANID_LBL" for="PlanId">
							{{ labels.RECEIVED_CLONE_MODAL_COMPONENT_PLAN_ID }}
						</label>
						<input data-testid="SUBSYS2_CLONEMODAL_PLANID_INPUT" type="text" autocomplete="nope" class="form-control"
							id="PlanId" formControlName="planId" placeholder="{{ labels.RECEIVED_CLONE_MODAL_COMPONENT_PLAN_ID }}"
							[ngClass]="{'is-valid': cloneForm.controls.planId.valid && cloneForm.controls.planId.touched, 'is-invalid': cloneForm.controls.planId.invalid && cloneForm.controls.planId.touched}"
							(input)="resetPlanIdNotUnique()" (change)="trimPlanId()" />
						<div class="invalid-feedback" *ngIf="cloneForm.controls.planId.invalid">
							{{cloneForm.controls.planId["errorMessage"]}}</div>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-12">
					<div class="form-group">
						<label data-testid="SUBSYS2_CLONEMODAL_NOTES_LBL" for="PlanNotes">
							{{ labels.RECEIVED_CLONE_MODAL_COMPONENT_PLAN_NOTES }}
						</label>
						<input data-testid="SUBSYS2_CLONEMODAL_NOTES_INPUT" type="text" autocomplete="nope" class="form-control"
							id="PlanNotes" formControlName="planNotes"
							placeholder="{{ labels.RECEIVED_CLONE_MODAL_COMPONENT_PLAN_NOTES }}"
							[ngClass]="{'is-valid': cloneForm.controls.planNotes.valid && cloneForm.controls.planNotes.touched, 'is-invalid': cloneForm.controls.planNotes.invalid && cloneForm.controls.planNotes.touched}" />
						<div class="invalid-feedback" *ngIf="cloneForm.controls.planNotes.invalid">
							{{cloneForm.controls.planNotes["errorMessage"]}}</div>
					</div>
				</div>
			</div>

		</div>
		<div class="modal-footer">
			<button data-testid="SUBSYS2_CLONEMODAL_CANCEL_BTN" type="button" class="btn btn-secondary"
				(click)="activeModal.dismiss()">
				{{ labels.RECEIVED_CLONE_MODAL_COMPONENT_CANCEL_BUTTON }}
			</button>
			<button data-testid="SUBSYS2_CLONEMODAL_CONFIRM_BTN" type="button" class="btn btn-primary" (click)="confirm()"
				[disabled]="!cloneForm.valid || isSaving">
				{{ labels.RECEIVED_CLONE_MODAL_COMPONENT_CONFIRM_BUTTON }}
			</button>
		</div>
	</form>
</div>