import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { PlanTypeEnum } from '@ortho-next/nextray-core';
import { ErrorMessageBindingStrategy, ReactiveFormConfig, RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import Slider from 'bootstrap-slider';
import { first } from 'rxjs/operators';
import { AnatomicalSideEnum, BoneTypeEnum, convertLength, convertMass, convertToISOString, convertToLocalDate, ExpressionOperator, GenderEnum, LanguageService, SearchFields } from '../../core';
import { SearchForm } from '../../models';
import { ListService, UserService } from '../../services';
import { BaseComponent, DateRange } from '../../shared';


/**
* Filters component to filter patient and plan list
*/
@Component({
	selector: 'filters',
	templateUrl: './filters.component.html',
	styles: []
})
export class FiltersComponent extends BaseComponent implements OnInit, OnDestroy {

	searchForm: RxFormGroup = <RxFormGroup>this.rxFormBuilder.formGroup(SearchForm);
	filtersForm: FormGroup;

	isCustomerCare: boolean;
	isFilterVisible: boolean[];
	isAllFiltersCollapsed: boolean;

	surgeryDateRange: DateRange;
	planDateRange: DateRange;

	readonly genderList: GenderEnum[] = Object.values(GenderEnum);
	readonly sideList: AnatomicalSideEnum[] = Object.values(AnatomicalSideEnum);
	readonly boneList: BoneTypeEnum[] = Object.values(BoneTypeEnum);
  readonly planTypeList: PlanTypeEnum[] = Object.values(PlanTypeEnum);
  preOpFilter: boolean;
  postOpFilter: boolean;


	ageSlider: any;
	weightSlider: any;
	heightSlider: any;

	private convertWeight = (val: number) => val === 0 ? 0 : convertMass(val, 'kg', 'lb');
	private convertHeight = (val: number) => val === 0 ? 0 : convertLength(val, 'cm', 'ft');

	private userNameExistsMsg: string;

	constructor(
		private langSrv: LanguageService,
		private listSrv: ListService,
		private formBuilder: FormBuilder,
		private rxFormBuilder: RxFormBuilder,
		private userSrv: UserService
	) {
		super(langSrv);
	}

	ngOnInit() {
		this.initForm();
		this.initSliders();
		this.isFilterVisible = Array<boolean>(8).fill(false);
		this.isAllFiltersCollapsed = this._isAllFiltersCollapsed;

		this.userSrv.isCustomerCare().pipe(first()).subscribe(res => {
			this.isCustomerCare = res;
			this.userNameExistsMsg = this.langSrv.getValidationMessages()['VALIDATION_MESSAGE_NOT_EXIST_USERNAME'];
		});

    this.preOpFilter = false;
    this.postOpFilter = false;
	}

	private initForm(): void {
		ReactiveFormConfig.set({
			"validationMessage": this.langSrv.getValidationMessages(),
			"errorMessageBindingStrategy": ErrorMessageBindingStrategy.OnDirtyOrTouched
		});
		this.filtersForm = this.formBuilder.group({
			gender: this.initEnumGroup(this.genderList),
			side: this.initEnumGroup(this.sideList),
			bone: this.initEnumGroup(this.boneList),
      planType: this.initEnumGroup(this.planTypeList)
		});
	}

	private initSliders(): void {
		const ageOptions = { id: "slider12c", min: 0, max: 100, step: 1, range: true, value: [0, 100], tooltip: 'hide' };
		this.ageSlider = new Slider("#ageSlider", ageOptions).on('slideStop', (val) => this.submitAge(val));

		const measureOptions = { id: "slider12c", min: 0, max: 250, step: 0.1, range: true, value: [0, 250], tooltip: 'hide' };
		this.weightSlider = new Slider("#weightSlider", measureOptions).on('slideStop', (val) => this.submitWeight(val));
		this.heightSlider = new Slider("#heightSlider", measureOptions).on('slideStop', (val) => this.submitHeight(val));
	}


	/**
	* Get Estimated Surgery Date range in string format
	*/
	get surgeryDateFormat(): string {
		return this.surgeryDateRange ? `${convertToLocalDate(this.surgeryDateRange.from, false)} - ${convertToLocalDate(this.surgeryDateRange.to, false)}` : '';
	};

	/**
	* Get Plan Creation Date range in string format
	*/
	get planDateFormat(): string {
		return this.planDateRange ? `${convertToLocalDate(this.planDateRange.from, false)} - ${convertToLocalDate(this.planDateRange.to, false)}` : '';
	};

	/**
	* Get Patient Gender form group
	*/
	get genderGroup(): FormArray {
		return this.filtersForm.get('gender') as FormArray;
	};

	/**
	* Get Plan Side form group
	*/
	get sideGroup(): FormArray {
		return this.filtersForm.get('side') as FormArray;
	};

	/**
	* Get plan bone type form group
	*/
	get boneGroup(): FormArray {
		return this.filtersForm.get('bone') as FormArray;
	};

  /**
	* Get plan type form group
	*/
	get planTypeGroup(): FormArray {
		return this.filtersForm.get('planType') as FormArray;
	};

	/**
	* Get Patient Age range in string format
	*/
	get rangeAge(): string {
		return `${this.ageSlider.getValue()[0]} -  ${this.ageSlider.getValue()[1]} years`;
	}

	/**
	* Get Patient Weight range in kg in string format
	*/
	get rangeWeightKg(): string {
		return `${this.weightSlider.getValue()[0]} -  ${this.weightSlider.getValue()[1]} Kg`;
	}

	/**
	* Get Patient Weight range in lb in string format
	*/
	get rangeWeightLb(): string {
		return `${this.convertWeight(this.weightSlider.getValue()[0])} -  ${this.convertWeight(this.weightSlider.getValue()[1])} Lb`;
	}

	/**
	* Get Patient height range in cm in string format
	*/
	get rangeHeightCm(): string {
		return `${this.heightSlider.getValue()[0]} - ${this.heightSlider.getValue()[1]} Cm`;
	}

	/**
	* Get Patient Height range in ft in string format
	*/
	get rangeHeightFt(): string {
		return `${this.convertHeight(this.heightSlider.getValue()[0])} - ${this.convertHeight(this.heightSlider.getValue()[1])} Ft`;
	}

	/**
	* Update Estimated Surgery Date string format
	*/
	updateSurgeryDateRange(range: DateRange): void {
		this.surgeryDateRange = range;
	}

	/**
	* Update Plan Creation Date string format
	*/
	updatePlanDateRange(range: DateRange): void {
		this.planDateRange = range;
	}

	/**
	* Submit patient gender filter and update list
	*/
	submitGender(result: boolean[]) {
		const selected = this.getSelectedEnums(this.genderList, result);
		this.listSrv.setFilter(SearchFields.patGender, selected, ExpressionOperator.In);
		this.listSrv.search();
	}

	/**
	* Submit plan side filter and update list
	*/
	submitSide(result: boolean[]) {
		const selected = this.getSelectedEnums(this.sideList, result);
		this.listSrv.setFilter(SearchFields.side, selected, ExpressionOperator.In);
		this.listSrv.search();
	}

	/**
	* Submit plan bone type filter and update list
	*/
	submitBone(result: boolean[]) {
		const selected = this.getSelectedEnums(this.boneList, result);
		this.listSrv.setFilter(SearchFields.boneType, selected, ExpressionOperator.In);
		this.listSrv.search();
	}

	/**
	* Submit plan type filter and update list
	*/
	submitPlanType(result: boolean[]) {
		const selected = this.getSelectedEnums(this.planTypeList, result);
		this.listSrv.setFilter(SearchFields.planType, selected, ExpressionOperator.In);
		this.listSrv.search();
	}

  changePreOpFilter(): void {
    this.preOpFilter = !this.preOpFilter;
    this.submitPlanStep();
  }

  changePostOpFilter(): void {
    this.postOpFilter = !this.postOpFilter;
    this.submitPlanStep();
  }

	/**
	* Submit plan step filter and update list
	*/
	private submitPlanStep(): void {
    if (this.preOpFilter && !this.postOpFilter) {
      this.listSrv.setFilter(SearchFields.isPostOperative, true, ExpressionOperator.Ne);
    }
    else if (!this.preOpFilter && this.postOpFilter) {
      this.listSrv.setFilter(SearchFields.isPostOperative, true, ExpressionOperator.Eq);
    }
    else {
      this.listSrv.setFilter(SearchFields.isPostOperative, true, ExpressionOperator.In);
    }
		this.listSrv.search();
	}

  private resetPlanStep(): void {
    (document.getElementById("preOpFilter") as HTMLInputElement).checked = false;
    this.preOpFilter = false;
    (document.getElementById("postOpFilter") as HTMLInputElement).checked = false;
    this.postOpFilter = false;
  }

	/**
	* Submit patient age filter and update list
	*/
	submitAge(result: number[]): void {
		const from: number = result[0] === 0 ? null : result[0];
		const to: number = result[1] === 100 ? null : result[1];
		this.listSrv.setRangeFilter(SearchFields.patAge, from, ExpressionOperator.Ge, to, ExpressionOperator.Le);
		this.listSrv.search();
	}

	/**
	* Submit patient weight filter and update list
	*/
	submitWeight(result: number[]): void {
		const from: number = result[0] === 0 ? null : result[0];
		const to: number = result[1] === 250 ? null : result[1];
		this.listSrv.setRangeFilter(SearchFields.patWeight, from, ExpressionOperator.Ge, to, ExpressionOperator.Le);
		this.listSrv.search();
	}

	/**
	* Submit patient height filter and update list
	*/
	submitHeight(result: number[]): void {
		const from: number = result[0] === 0 ? null : result[0];
		const to: number = result[1] === 250 ? null : result[1];
		this.listSrv.setRangeFilter(SearchFields.patHeight, from, ExpressionOperator.Ge, to, ExpressionOperator.Le);
		this.listSrv.search();
	}


	private initEnumGroup(list: any[]): FormArray {
		const arr = list.map(s => {
			return this.formBuilder.control(false);
		})
		return this.formBuilder.array(arr);
	}

	private getSelectedEnums<T>(list: T[], value: boolean[]): T[] {
		const selected = value.map((s, i) => {
			return s ? list[i] : null;
		}).filter(s => !!s);
		return selected.length > 0 ? selected : null;
	}

	/**
	* Update list according customer care role
	*/
	search(): void {
		if (this.isCustomerCare) {
			this.applyCustomerCareFilters();
		} else {
			this.applyMainFilters();
		}
	}

	private applyCustomerCareFilters(): void {
		this.listSrv.setDeletedFilter(this.searchForm.value.showDeleted);

		if (!!this.searchForm.value.userName) {
			this.userSrv.getUserGuid(this.searchForm.value.userName).subscribe(guid => {
				if (!!guid) {
					this.listSrv.setFilter(SearchFields.userGuid, guid, ExpressionOperator.Eq);
					this.applyMainFilters();
				} else {
					this.searchForm.controls.userName.setErrors({ userNameExists: { message: this.userNameExistsMsg } });
				}
			});
		} else {
			this.listSrv.setFilter(SearchFields.userGuid, null, ExpressionOperator.Eq);
			this.applyMainFilters();
		}
	}

	private applyMainFilters(): void {
		this.listSrv.setFilter(SearchFields.patNumber, this.searchForm.value.patientId, ExpressionOperator.Contains);
		this.listSrv.setFilter(SearchFields.planNumber, this.searchForm.value.planId, ExpressionOperator.Contains);

		if (this.surgeryDateRange) {
			const fromDate = convertToISOString(this.surgeryDateRange.from);
			const toDate = convertToISOString(this.surgeryDateRange.to);
			this.listSrv.setRangeFilter(SearchFields.planSurgeryDate, fromDate, ExpressionOperator.Ge, toDate, ExpressionOperator.Le);
		} else {
			this.listSrv.setFilter(SearchFields.planSurgeryDate, null, ExpressionOperator.Le);
		}

		if (this.planDateRange) {
			const fromDate = convertToISOString(this.planDateRange.from);
			const toDate = convertToISOString(this.planDateRange.to);
			this.listSrv.setRangeFilter(SearchFields.planCreatedDate, fromDate, ExpressionOperator.Ge, toDate, ExpressionOperator.Le);
		} else {
			this.listSrv.setFilter(SearchFields.planCreatedDate, null, ExpressionOperator.Le);
		}

		this.listSrv.search();
	}

	/**
	* Reset all filters
	*/
	clearFilters(): void {
		this.searchForm.reset();
		this.surgeryDateRange = null;
		this.planDateRange = null;
		this.filtersForm.reset();
    this.resetPlanStep();
		this.ageSlider.setValue([0, 100]);
		this.weightSlider.setValue([0, 250]);
		this.heightSlider.setValue([0, 250]);
		this.listSrv.resetFilters();
		this.search();
	}

	/**
	* Reset Estimated Surgery Date filter
	*/
	surgeryDateClear(): void {
		this.surgeryDateRange = null;
	}

	/**
	* Reset Plan Creation Date filter
	*/
	planDateClear(): void {
		this.planDateRange = null;
	}

	/**
	* Collapse all filters
	*/
	collapseAllFilters(): void {
		this.isFilterVisible = this.isFilterVisible.map(filter => false);
		this.isAllFiltersCollapsed = this._isAllFiltersCollapsed;
	}

	/**
	* Show all filters
	*/
	showAllFilters(): void {
		this.isFilterVisible = this.isFilterVisible.map(filter => true);
		this.isAllFiltersCollapsed = this._isAllFiltersCollapsed;
	}

	/**
	* Collpase/show only selected filter
	*/
	changeFilterVisibility(index: number): void {
		this.isFilterVisible[index] = !this.isFilterVisible[index];
		this.isAllFiltersCollapsed = this._isAllFiltersCollapsed;
	}

	private get _isAllFiltersCollapsed(): boolean {
		return !this.isFilterVisible.includes(true);
	}

	ngOnDestroy() {
		this.listSrv.resetFilters();
	}

}
