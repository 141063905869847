import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RxFormGroup } from '@rxweb/reactive-form-validators';
import { AnatomicalSideEnum, BoneTypeEnum, BoneViewEnum, ImageOrientationEnum, LanguageService } from '../../core';
import { ImageCategorizationForm } from '../../models';
import { BoneImageEnum, CanvasService } from '../../services';
import { BaseComponent } from '../../shared';


/**
 * Component with image categorization form on image garage modal.
 */
@Component({
	selector: 'image-categorization-form',
	templateUrl: './image-categorization-form.component.html'
})
export class ImageCategorizationFormComponent extends BaseComponent implements OnInit {

	@Input() form: RxFormGroup;
	@Output() onChange: EventEmitter<void> = new EventEmitter<void>();

	readonly boneTypeList: BoneTypeEnum[] = [BoneTypeEnum.LongLeg, BoneTypeEnum.Femur, BoneTypeEnum.Tibia, BoneTypeEnum.Ankle, BoneTypeEnum.Forefoot, BoneTypeEnum.Hindfoot];
	readonly boneViewEnum = BoneViewEnum;
	readonly boneSideEnum = AnatomicalSideEnum;

	apViewLabel: string = this.labels.IMAGE_CATEGORIZATION_FORM_COMPONENT_BONE_VIEW_AP;
	showOrientBox: boolean;
	imgOrientationSrc: string;
	orientLeftLabel: string;
	orientRightLabel: string;
	orientDownLabel: string;
	orientUpLabel: string;
	changeOrientation: boolean;

	constructor(
		private langSrv: LanguageService,
		private canvasSrv: CanvasService,
	) {
		super(langSrv);
	}

	private get _formValue(): ImageCategorizationForm {
		return this.form.value;
	}

	private get _imgOrientationSrc(): string {
		return this._formValue.boneSide == AnatomicalSideEnum.Right ? this._imgOrientationSrc_Right : this._imgOrientationSrc_Left;
	}

	private get _imgOrientationSrc_Right(): string {
		if (this._formValue.boneView == BoneViewEnum.AP) {
			switch (this._formValue.boneType) {
				case BoneTypeEnum.LongLeg: return BoneImageEnum.Right_Leg_AP;
				case BoneTypeEnum.Femur: return BoneImageEnum.Right_Leg_AP;
				case BoneTypeEnum.Tibia: return BoneImageEnum.Right_Leg_AP;
				case BoneTypeEnum.Ankle: return BoneImageEnum.Right_Foot_AP;
				case BoneTypeEnum.Forefoot: return BoneImageEnum.Right_Foot_AP;
				case BoneTypeEnum.Hindfoot: return BoneImageEnum.Right_Foot_AP;
				default: return null;
			}
		} else {
			switch (this._formValue.boneType) {
				case BoneTypeEnum.LongLeg: return BoneImageEnum.Right_Leg_LT;
				case BoneTypeEnum.Femur: return BoneImageEnum.Right_Leg_LT;
				case BoneTypeEnum.Tibia: return BoneImageEnum.Right_Leg_LT;
				case BoneTypeEnum.Ankle: return BoneImageEnum.Right_Foot_LT;
				case BoneTypeEnum.Forefoot: return BoneImageEnum.Right_Foot_LT;
				case BoneTypeEnum.Hindfoot: return BoneImageEnum.Right_Foot_LT;
				default: return null;
			}
		}
	}

	private get _imgOrientationSrc_Left(): string {
		if (this._formValue.boneView == BoneViewEnum.AP) {
			switch (this._formValue.boneType) {
				case BoneTypeEnum.LongLeg: return BoneImageEnum.Left_Leg_AP;
				case BoneTypeEnum.Femur: return BoneImageEnum.Left_Leg_AP;
				case BoneTypeEnum.Tibia: return BoneImageEnum.Left_Leg_AP;
				case BoneTypeEnum.Ankle: return BoneImageEnum.Left_Foot_AP;
				case BoneTypeEnum.Forefoot: return BoneImageEnum.Left_Foot_AP;
				case BoneTypeEnum.Hindfoot: return BoneImageEnum.Left_Foot_AP;
				default: return null;
			}
		} else { // BoneViewEnum.LT
			switch (this._formValue.boneType) {
				case BoneTypeEnum.LongLeg: return BoneImageEnum.Left_Leg_LT;
				case BoneTypeEnum.Femur: return BoneImageEnum.Left_Leg_LT;
				case BoneTypeEnum.Tibia: return BoneImageEnum.Left_Leg_LT;
				case BoneTypeEnum.Ankle: return BoneImageEnum.Left_Foot_LT;
				case BoneTypeEnum.Forefoot: return BoneImageEnum.Left_Foot_LT;
				case BoneTypeEnum.Hindfoot: return BoneImageEnum.Left_Foot_LT;
				default: return null;
			}
		}
	}

	ngOnInit() {
		this.setBoneViewLabel();
		this.setOrientationBox();
		this.changeOrientation = this._formValue.orientation && this._formValue.orientation != ImageOrientationEnum.Nothing;
	}

	/**
	 * Checks changes on bone categorization values.
	 */
	checkBoneCategorizValues(): void {
		this.setBoneViewLabel();
		this.setOrientationBox();
		this.changeOrient(false); // reset orientation
		this.onChange.emit();
	}

	/**
	 * Check click on radio button to change the orientation.
	 * @param value orientation radio button value 
	 */
	changeOrient(value: boolean): void {
		this.changeOrientation = value;
		this.imgOrientationSrc = this._imgOrientationSrc;

		this.setFormOrientationValue();
	}

	/**
	 * Update AP view label according to bone type selected.
	 */
	private setBoneViewLabel(): void {
		const isFoot = this._formValue.boneType === BoneTypeEnum.Forefoot || this._formValue.boneType === BoneTypeEnum.Hindfoot;
		this.apViewLabel = isFoot ? this.labels.IMAGE_CATEGORIZATION_FORM_COMPONENT_BONE_VIEW_DORS : this.labels.IMAGE_CATEGORIZATION_FORM_COMPONENT_BONE_VIEW_AP;
	}

	/**
	 * Set labels and image src of orientation box.
	 */
	private setOrientationBox(): void {
		this.showOrientBox = !!this._formValue.boneSide && !!this._formValue.boneType && !!this._formValue.boneView;
		this.orientDownLabel = this.orientUpLabel = this.canvasSrv.bottomLabel = this.canvasSrv.topLabel
			= this.canvasSrv.leftLabel = this.canvasSrv.rightLabel = null;

		if (this.showOrientBox) {
			this.imgOrientationSrc = this._imgOrientationSrc;
			const hasTopBottomLabels = this._formValue.boneType == BoneTypeEnum.Forefoot || this._formValue.boneType == BoneTypeEnum.Hindfoot;

			if (this._formValue.boneView == BoneViewEnum.AP) {
				this.setOrientationLabels_AP(hasTopBottomLabels);
			} else {
				this.setOrientationLabels_LT(hasTopBottomLabels);
			}
		}
	}

	private setOrientationLabels_AP(hasTopBottomLabels: boolean): void {
		if (this._formValue.boneSide == AnatomicalSideEnum.Left) { // Left
			this.canvasSrv.leftLabel = this.orientLeftLabel = this.labels['IMAGE_CATEGORIZATION_FORM_COMPONENT_BONE_ORIENTATION_MED'];
			this.canvasSrv.rightLabel = this.orientRightLabel = this.labels['IMAGE_CATEGORIZATION_FORM_COMPONENT_BONE_ORIENTATION_LAT'];
		} else { // Right
			this.canvasSrv.leftLabel = this.orientLeftLabel = this.labels['IMAGE_CATEGORIZATION_FORM_COMPONENT_BONE_ORIENTATION_LAT'];
			this.canvasSrv.rightLabel = this.orientRightLabel = this.labels['IMAGE_CATEGORIZATION_FORM_COMPONENT_BONE_ORIENTATION_MED'];
		}

		if (hasTopBottomLabels) {
			this.canvasSrv.topLabel = this.orientUpLabel = this.labels['IMAGE_CATEGORIZATION_FORM_COMPONENT_BONE_ORIENTATION_POST'];
			this.canvasSrv.bottomLabel = this.orientDownLabel = this.labels['IMAGE_CATEGORIZATION_FORM_COMPONENT_BONE_ORIENTATION_ANT'];
		}
	}

	private setOrientationLabels_LT(hasTopBottomLabels: boolean): void {
		if (this._formValue.boneSide == AnatomicalSideEnum.Left) { // Left
			this.canvasSrv.leftLabel = this.orientLeftLabel = this.labels['IMAGE_CATEGORIZATION_FORM_COMPONENT_BONE_ORIENTATION_POST'];
			this.canvasSrv.rightLabel = this.orientRightLabel = this.labels['IMAGE_CATEGORIZATION_FORM_COMPONENT_BONE_ORIENTATION_ANT'];
		} else { // Right
			this.canvasSrv.leftLabel = this.orientLeftLabel = this.labels['IMAGE_CATEGORIZATION_FORM_COMPONENT_BONE_ORIENTATION_ANT'];
			this.canvasSrv.rightLabel = this.orientRightLabel = this.labels['IMAGE_CATEGORIZATION_FORM_COMPONENT_BONE_ORIENTATION_POST'];
		}

		if (hasTopBottomLabels) {
			this.canvasSrv.topLabel = this.orientUpLabel = this.labels['IMAGE_CATEGORIZATION_FORM_COMPONENT_BONE_ORIENTATION_DORS'];
			this.canvasSrv.bottomLabel = this.orientDownLabel = this.labels['IMAGE_CATEGORIZATION_FORM_COMPONENT_BONE_ORIENTATION_PLAN'];
		}
	}

	/**
	 * Set orientation form value by click on 'yes' or 'no' radio input.
	 */
	private setFormOrientationValue(): void {
		const ctrlOrientation = this.form.controls.orientation;

		if (this.changeOrientation) {
			if (this._formValue.boneView == BoneViewEnum.AP &&
				(this._formValue.boneType == BoneTypeEnum.Forefoot || this._formValue.boneType == BoneTypeEnum.Hindfoot)) {
				ctrlOrientation.setValue(ImageOrientationEnum.Rotate180);
				this.canvasSrv.dispatch('flipVertical');
				this.canvasSrv.dispatch('flipHorizontal');
			} else {
				ctrlOrientation.setValue(ImageOrientationEnum.Horizontal);
				this.canvasSrv.dispatch('flipHorizontal');
			}
		} else {
			this._formValue.orientation == ImageOrientationEnum.Vertical && this.canvasSrv.dispatch('flipVertical');
			this._formValue.orientation == ImageOrientationEnum.Horizontal && this.canvasSrv.dispatch('flipHorizontal');
			if (this._formValue.orientation == ImageOrientationEnum.Rotate180) {
				this.canvasSrv.dispatch('flipHorizontal');
				this.canvasSrv.dispatch('flipVertical');
			}
			ctrlOrientation.setValue(ImageOrientationEnum.Nothing);
		}
	}
}
