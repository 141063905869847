import { contains, maxLength, maxNumber, numeric, NumericValueType, pattern, prop, range, required } from '@rxweb/reactive-form-validators';
import { GenderEnum } from '../core';

const specialChars: RegExp = /^[^<>%#&?,]+$/;
const decimalFormat: RegExp = /^\d+([.,]\d+)?$/;


/**
* Patient create/edit form model
*/
export class PatientForm {

	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_PATIENTID" })
	@maxLength({ value: 50, messageKey: "VALIDATION_MESSAGE_MAXLENGTH_PATIENTID" })
	@pattern({ expression: { 'noSpecialChars': specialChars }, messageKey: "VALIDATION_MESSAGE_SPECIALCHARS_PATIENTID" })
	@contains({ value: "%", messageKey: "VALIDATION_MESSAGE_UNIQUE_PATIENTID", conditionalExpression: (x: PatientForm) => !!x.patientIdNotUnique })
	patientId: string;

	@prop({ defaultValue: false })
	patientIdNotUnique?: boolean;

	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_PATIENT_INITIALS" })
	@maxLength({ value: 5, messageKey: "VALIDATION_MESSAGE_MAXLENGTH_PATIENT_INITIALS" })
	@pattern({ expression: { 'noSpecialChars': specialChars }, messageKey: "VALIDATION_MESSAGE_SPECIALCHARS_PATIENT_INITIALS" })
	patientInitials: string;

	@required({ messageKey: "VALIDALIDATION_MESSAGE_REQUIRED_GENDER" })
	patientGender: GenderEnum;

	@numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: false, messageKey: "VALIDATION_MESSAGE_NUMERIC_AGE" })
	@range({ minimumNumber: 1, maximumNumber: 100, messageKey: "VALIDATION_MESSAGE_RANGEVALUE_AGE", conditionalExpression: (x: PatientForm) => !x.patientInfant })
	patientAge?: number;

	@prop({ defaultValue: false })
	patientInfant?: boolean;

	@pattern({ expression: { 'decimalFormat': decimalFormat }, messageKey: "VALIDATION_MESSAGE_NUMERIC_HEIGHT_CM" })
	@maxNumber({ value: 250, messageKey: "VALIDATION_MESSAGE_MAX_HEIGHT_CM" })
	patientHeightCm?: number;

	@pattern({ expression: { 'decimalFormat': decimalFormat }, messageKey: "VALIDATION_MESSAGE_NUMERIC_HEIGHT_FT" })
	@maxNumber({ value: 8.2, messageKey: "VALIDATION_MESSAGE_MAX_HEIGHT_FT" })
	patientHeightFt?: number;

	@pattern({ expression: { 'decimalFormat': decimalFormat }, messageKey: "VALIDATION_MESSAGE_NUMERIC_WEIGHT_KG" })
	@maxNumber({ value: 250, messageKey: "VALIDATION_MESSAGE_MAX_WEIGHT_KG" })
	patientWeightKg?: number;

	@pattern({ expression: { 'decimalFormat': decimalFormat }, messageKey: "VALIDATION_MESSAGE_NUMERIC_WEIGHT_LB" })
	@maxNumber({ value: 551.2, messageKey: "VALIDATION_MESSAGE_MAX_WEIGHT_LB" })
	patientWeightLb?: number;

}
