import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Image, ImageOrientationEnum, LanguageService } from '../../core';
import { BaseComponent } from '../../shared';


/**
* Image previw modal component
*/
@Component({
	selector: 'attachment-preview',
	templateUrl: './attachment-preview.component.html'
})
export class AttachmentPreviewComponent extends BaseComponent {

	@Input() fileName: string;
	@Input() set img(val: Image) {
		this.update(val);
	};

	url: string;
	isFlipHor: boolean;
	isFlipVer: boolean;

	constructor(private langSrv: LanguageService, public activeModal: NgbActiveModal) {
		super(langSrv);
	}

	private update(img: Image): void {
		this.url = img?.url || '';
		this.isFlipHor = img?.orientation === ImageOrientationEnum.Horizontal;
		this.isFlipVer = img?.orientation === ImageOrientationEnum.Vertical;
	}

}
