import { AfterViewInit, Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { GenderEnum, LanguageService, SearchFields, SearchItems, SearchSortOptions } from '../../core';
import { ListService, UserService } from '../../services';
import { BaseComponent } from '../../shared';
import { SortEvent, SortableHeaderComponent } from '../sortable-header';


/**
* Patient table component
*/
@Component({
	selector: 'patient-table',
	templateUrl: './patient-table.component.html'
})
export class PatientTableComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {

	@ViewChildren(SortableHeaderComponent) headers: QueryList<SortableHeaderComponent>;
	activeColumn: string;

	collectionSize: number = 0;
	patientList: SearchItems[] = [];

	searchResultSubscription$: Subscription;
	fields = SearchFields;

	isTlhexUser: boolean;

	constructor(
		private langSrv: LanguageService,
		private listSrv: ListService,
		private router: Router,
		private userSrv: UserService
	) {
		super(langSrv);
	}

	ngOnInit() {
		this.listSrv.resetSearchResult();
		this.initTable();
		this.searchResultSubscription$ = this.listSrv.getSearchResult().subscribe(list => {
			this.collectionSize = list ? list.count : 0;
			this.patientList = list ? list.items : [];
		});
		this.userSrv.isTlhex().subscribe(val => this.isTlhexUser = val);
	}

	ngAfterViewInit() {
		setTimeout(() => {
			const sortOrder: SearchSortOptions = this.listSrv.getSortOrder();
			if (!!sortOrder) {
				this.headers.find(header => header.name == sortOrder.name).direction = sortOrder.desc ? 'desc' : 'asc';
				this.activeColumn = sortOrder.name;
			}
		});
	}

	private initTable() {
		this.listSrv.resetPatientSearch();
	}

	/** 
	 * Get css class according to gender.
	*/
	genderClass(gender: GenderEnum): string {
		switch (gender) {
			case GenderEnum.FEMALE: return 'f';
			case GenderEnum.MALE: return 'm';
			case GenderEnum.OTHER: return 'o';
			default: return '';
		}
	}

	/**
	* Update column sort order and sort list
	*/
	onSort({ column, direction }: SortEvent) {
		// resetting other headers
		this.headers.forEach(header => header.name !== column ? header.reset() : null);
		// sorting
		this.activeColumn = direction === 'asc' || direction === 'desc' ? column : null;
		this.listSrv.sort(column, direction);
	}

	/**
	* Navigate to patient edit page
	*/
	editPatient(patientGuid: string): void {
		this.router.navigate(["/patient/edit"], { queryParams: { patientGuid: patientGuid } });
	}

	ngOnDestroy() {
		if (this.searchResultSubscription$) {
			this.searchResultSubscription$.unsubscribe();
		}
		this.listSrv.resetSearchResult();
	}

}
