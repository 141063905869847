<div class="calendar-event-list">

	<h3 data-testid="SUBSYS2_DASHBOARD_EVENTS_TITLE_TXT">
		{{labels.DASHBOARD_EVENTS_COMPONENT_TITLE}} {{ date ? '-' : '' }} {{ date | convertDate:false }}
	</h3>
	<hr class="title-line" />

	<span data-testid="SUBSYS2_DASHBOARD_EVENTS_EMPTY_TXT" *ngIf="!events?.length; else listContent">
		{{labels.DASHBOARD_EVENTS_COMPONENT_LIST_EMPTY}}
	</span>

	<ng-template #listContent>

		<div class="event-list-container">

			<ngb-accordion data-testid="SUBSYS2_DASHBOARD_EVENTS_ACCORDION" #acc="ngbAccordion" [activeIds]="openedPanelIds"
				[destroyOnHide]="false">
				<ngb-panel [id]="PANEL_ID_PREFIX+i" cardClass="panel panel-accordion p-0 m-0"
					*ngFor="let event of events; let i = index">

					<ng-template ngbPanelHeader let-opened="opened">
						<div data-testid="SUBSYS2_DASHBOARD_EVENTS_PANEL_HEADER" class="custom-header"
							(click)="acc.toggle(PANEL_ID_PREFIX+i)">
							{{ event.patient }}
							<span class="material-icons" [class.opened]="opened">expand_more</span>
						</div>
					</ng-template>

					<ng-template ngbPanelContent>
						<div data-testid="SUBSYS2_DASHBOARD_EVENTS_PANEL_CONTENT" class="custom-item"
							*ngFor="let item of event.items" (click)="goToReport(item.caseId)">
							<span class="material-icons circle mr-3" [ngClass]="eventTypeClass(item)">circle</span>
							<span data-testid="SUBSYS2_DASHBOARD_EVENTS_PANEL_CONTENT_TXT" class="custom-item-text">
								{{ eventItemDesc(item) }}</span>
						</div>
					</ng-template>

				</ngb-panel>
			</ngb-accordion>

		</div>

	</ng-template>
</div>