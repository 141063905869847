import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbDate, NgbDatepicker, NgbDatepickerNavigateEvent } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { EventItem } from '../../models';
import { DashboardService } from '../../services';


/**
* Component for calendar in dashboard page.
*/
@Component({
	selector: 'dashboard-calendar',
	templateUrl: './dashboard-calendar.component.html'
})
export class DashboardCalendarComponent implements OnInit, OnDestroy {

	@ViewChild(NgbDatepicker) datepicker: NgbDatepicker;

	eventsMap: Map<string, EventItem[]> = new Map<string, EventItem[]>();

	constructor(private dashbrdSrv: DashboardService) { }

	private _eventsSub: Subscription;

	ngOnInit(): void {
		this._eventsSub = this.dashbrdSrv.strutEvents$.subscribe(list => this.updateMap(list));
	}

	/**
	 * Update events map according to input  event item list.
	 * @param list 
	 */
	private updateMap(list: EventItem[]) {
		this.eventsMap.clear();
		if (list?.length) {
			for (let ev of list) {
				const key = this.dateKey(ev.date);
				if (this.eventsMap.has(key)) {
					this.eventsMap.get(key).push(ev);
				} else {
					this.eventsMap.set(key, [ev]);
				}
			}
		}
	}

	/**
	 * Get key map from date Date.
	 */
	dateKey(date: Date): string {
		return new Intl.DateTimeFormat('en-US', { numberingSystem: 'latn' }).format(date);
	}

	/**
	 * Get key map from date NgbDate.
	 */
	ngbDateKey(date: NgbDate): string {
		return `${date.month}/${date.day}/${date.year}`;
	}

	/**
	 * Update strut events after calendar month change.
	 */
	changeMonth(ev: NgbDatepickerNavigateEvent): void {
		this.dashbrdSrv.loadEvents(ev.next, !!ev.current);
	}

	/**
	 * Update current selected date.
	 */
	changeDate(ev: NgbDate): void {
		this.dashbrdSrv.updateDate(ev);
	}


	ngOnDestroy(): void {
		this._eventsSub?.unsubscribe();
		this.dashbrdSrv.reset();
	}
}
