import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Event, ResolveEnd, Router } from '@angular/router';
import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web';
import { CookieService } from 'ngx-cookie-service';
import { filter } from 'rxjs/operators';
import { environment } from '../../environments/environment';

/**
 * This service monitor errors and navigation pages with Application Insights
 */
@Injectable()
export class MonitoringService {

	appInsights: ApplicationInsights;
	hasFuncCookies: boolean = false;

	private readonly exceptionSeverityLevel = SeverityLevel.Warning;
	private readonly traceSeverityLevel = SeverityLevel.Information;

	consoleError = console.error;

	constructor(
		private router: Router,
		private cookieSrv: CookieService
	) {
		const funcCookie = this.cookieSrv.get(environment.cookieName);
		this.hasFuncCookies = funcCookie ? JSON.parse(funcCookie)?.purposes?.[2] : false;

		this.appInsights = new ApplicationInsights({
			config: {
				instrumentationKey: environment.insightKey,
				disableCookiesUsage: !this.hasFuncCookies
			}
		});

		const consoleLog = console.log;
		const consoleWarn = console.warn;
		console.log = (msg, params) => {
			params ? consoleLog(msg, params) : consoleLog(msg);
			this.logEvent(msg, params);
		};
		console.warn = (msg, params) => {
			params ? consoleWarn(msg, params) : consoleWarn(msg);
			this.logEvent(msg, params);
		};
		console.error = (msg, params) => {
			this.logError(msg, params);
		};

		this.appInsights?.loadAppInsights();

		if (this.hasFuncCookies) {
			this.router.events.pipe(filter(event => event instanceof ResolveEnd)).subscribe((res: Event) => {
				const event = res as ResolveEnd;
				const activatedComponent = this.getActivatedComponent(event.state.root);
				if (activatedComponent) {
					this.logPageView(`${activatedComponent.name} ${this.getRouteTemplate(event.state.root)}`, event.urlAfterRedirects);
				}
			});
		}
	}

	private getActivatedComponent(snapshot: ActivatedRouteSnapshot): any {
		if (snapshot.firstChild) {
			return this.getActivatedComponent(snapshot.firstChild);
		}
		return snapshot.component;
	}

	private getRouteTemplate(snapshot: ActivatedRouteSnapshot): string {
		let path = '';
		if (snapshot.routeConfig) {
			path += snapshot.routeConfig.path;
		}
		if (snapshot.firstChild) {
			return path + this.getRouteTemplate(snapshot.firstChild);
		}
		return path;
	}

	private addGlobalProperties(properties?: { [key: string]: string }): { [key: string]: string } {
		if (!properties) {
			properties = {};
		}
		// add custom properties such as app version
		return properties;
	}

	/**
	* Track current page
	*/
	public logPageView(name?: string, uri?: string) {
		this.appInsights?.trackPageView({ name, uri });
	}

	/**
	* Track log events
	*/
	public logEvent(message: string, properties?: { [key: string]: string }) {
		this.appInsights?.trackTrace({ message, severityLevel: this.traceSeverityLevel }, this.addGlobalProperties(properties));
	}

	/**
	* Track log errors
	*/
	public logError(error: any, params?: any) {
		this.appInsights?.trackException({ error, severityLevel: this.exceptionSeverityLevel });
		params ? this.consoleError(error, params) : this.consoleError(error);
	}

	/**
	* Track authenticated user
	*/
	public setAuthenticatedUserId(userId: string): void {
		if (this.hasFuncCookies) {
			this.appInsights?.setAuthenticatedUserContext(userId);
		}
	}

	/**
	* Reset authenticated user
	*/
	public clearAuthenticatedUserId(): void {
		this.appInsights?.clearAuthenticatedUserContext();
	}
}
