/**
* Wrapper response model of API
*/
export interface ApiResponse<T> {
	statusCode: ApiResponseStatusCode;
	errorMessage: string;
	result?: T;
}

export enum ApiResponseStatusCode {
	Success = "Success",
	ItemNotFound = "ItemNotFound",
	PatientNumberNotUnique = "PatientNumberNotUnique",
	CaseNumberNotUnique = "CaseNumberNotUnique",
	UserNotJpsProd = "UserNotJpsProd",
	UserNotFitboneProd = "UserNotFitboneProd",
	UserNotTlhexProd = "UserNotTlhexProd",
	UserNotFound = "UserNotFound",
	PublishedRevokedCaseError = "PublishedRevokedCaseError",
	GenericError = "GenericError"
}
