import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiResponse, ApiResponseStatusCode, IntegrationRepository, Plan, PlanRepository, SendCase } from '../core';
import { PlanSendForm } from '../models';


/**
 * This service handles plan sending.
 */
@Injectable()
export class SendService {

	constructor(
		private planRepo: PlanRepository,
		private integrationRepo: IntegrationRepository
	) { }

	/**
	* Get plan by plan guid
	* @param {string} planGuid The plan guid
	*/
	getPlan(planGuid: string): Observable<Plan> {
		return this.planRepo.getPlan(planGuid).pipe(
			map(res => this.handlePlanResponse(res))
		);
	}

	/**
	* Send case to a recipient.
	* @param {SendCase} sendCase Case data to send
	* @returns Operation success
	*/
	private sendPlan(sendPlan: SendCase): Observable<void> {
		return this.integrationRepo.sendCase(sendPlan).pipe(
			map(res => this.handleSendResponse(res))
		);
	}

	/**
	* Send case to a recipient by form.
	* @param {PlanSendForm} sendForm Form of plan to send
	* @returns Operation success
	*/
	sendForm(sendForm: PlanSendForm): Observable<void> {
		if (sendForm) {
			const planToSend: SendCase = {
				userId: sendForm.userGuid,
				caseId: sendForm.planGuid,
				recipient: sendForm.userName,
				notes: sendForm.notes
			};
			return this.sendPlan(planToSend);
		}
		return throwError(() => new Error('Send Form is null'));
	}


	private handlePlanResponse(response: ApiResponse<Plan>) {
		switch (response.statusCode) {
			case ApiResponseStatusCode.Success: return response.result;
			case ApiResponseStatusCode.CaseNumberNotUnique: throw new Error("PlanNumberNotUnique");
			default: throw new Error("Generic error");
		}
	}

	private handleSendResponse(response: ApiResponse<void>) {
		switch (response.statusCode) {
			case ApiResponseStatusCode.Success: return response.result;
			case ApiResponseStatusCode.UserNotFound: throw new Error('UserNotFound');
			case ApiResponseStatusCode.UserNotJpsProd: throw new Error('UserNotJpsProd');
			case ApiResponseStatusCode.UserNotFitboneProd: throw new Error('UserNotFitboneProd');
			case ApiResponseStatusCode.UserNotTlhexProd: throw new Error('UserNotTlhexProd');
			default: throw new Error("Generic error");
		}
	}

}
