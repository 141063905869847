<div class="row no-gutters mt-5">
	<div class="col-lg-6">
		<ngb-pagination [collectionSize]="collectionSize" [page]="page" [pageSize]="pageSize"
			(pageChange)="changePageNumber($event)" [boundaryLinks]="true" [directionLinks]="true" [maxSize]="1"
			[ellipses]="false">
			<ng-template ngbPaginationFirst>&laquo;</ng-template>
			<ng-template ngbPaginationPrevious><i class="material-icons md-18">chevron_left</i></ng-template>
			<ng-template ngbPaginationNumber>{{ page }}</ng-template>
			<ng-template ngbPaginationNext><i class="material-icons md-18">chevron_right</i></ng-template>
			<ng-template ngbPaginationLast>&raquo;</ng-template>
		</ngb-pagination>
		<select data-testid="SUBSYS2_LIST_PAGINATION_SELECT" class="form-control select-items ml-5" [(ngModel)]="pageSize"
			(change)="changePageSize()">
			<option [value]="10">10</option>
			<option [value]="15">15</option>
			<option [value]="20">20</option>
			<option [value]="25">25</option>
			<option [value]="50">50</option>
		</select>
	</div>
	<div class="col-lg-6 text-right">
		<span data-testid="SUBSYS2_LIST_PAGINATION_TXT" class="results-items">
			{{collectionSize}} {{labels.PAGINATION_COMPONENT_ITEMS}} {{totalPages}} {{labels.PAGINATION_COMPONENT_PAGES}}
		</span>
	</div>
</div>