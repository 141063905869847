import { email, maxLength, pattern, prop } from '@rxweb/reactive-form-validators';

const specialChars: RegExp = /^[^<>%#&?,]+$/;

/**
* Patient/plan filters form model
*/
export class SearchForm {

  @maxLength({ value: 128, messageKey: "VALIDATION_MESSAGE_MAXLENGTH_EMAIL" })
  @pattern({ expression: { 'noSpecialChars': specialChars }, messageKey: "VALIDATION_MESSAGE_SPECIALCHARS_EMAIL" })
  @email({ messageKey: "VALIDATION_MESSAGE_FORMAT_EMAIL" })
  userName: string;

  @prop()
  showDeleted: boolean;

  @maxLength({ value: 50, messageKey: "VALIDATION_MESSAGE_MAXLENGTH_PATIENTID" })
  @pattern({ expression: { 'noSpecialChars': specialChars }, messageKey: "VALIDATION_MESSAGE_SPECIALCHARS_PATIENTID" })
  patientId: string;

  @maxLength({ value: 50, messageKey: "VALIDATION_MESSAGE_MAXLENGTH_PLANID" })
  @pattern({ expression: { 'noSpecialChars': specialChars }, messageKey: "VALIDATION_MESSAGE_SPECIALCHARS_PLANID" })
  planId: string;

}
