import { CdkDragDrop, copyArrayItem, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SseService } from '@ortho-next/sse';
import { Observable, Subscription, from } from 'rxjs';
import { concatAll, filter, finalize, map, switchMap, toArray } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ApiResponse, ApiResponseStatusCode, LanguageService, ToastService } from '../../core';
import { Attachment } from '../../models';
import { AttachmentService, PatientService, PlanService } from '../../services';
import { BaseComponent, ConfirmationComponent } from '../../shared';
import { AttachmentPreviewComponent } from '../attachment-preview';
import { ImageGarageComponent } from '../image-garage';


/**
* Images gallery component
*/
@Component({
	selector: 'patient-attachment',
	templateUrl: './attachment.component.html'
})
export class AttachmentComponent extends BaseComponent implements OnInit, OnDestroy {

	@Input() isDragDisabled: boolean;
	@Input() dropListConnected: string[];
	@Input() isDisabled: boolean;

	patientGuid: string;
	userGuid: string
	attachmentList: Attachment[] = [];
	loadingList: string[] = [];
	numberOfSelected: number = 0;
	isFooterVisible: boolean;
	isDeleting: boolean;

	patientGuidSubscription$: Subscription;
	attachmentListSubscription$: Subscription;
	loadingListSubscription$: Subscription;
	thumbnailSubscription$: Subscription;

	readonly loaderUrl = `${environment.cdnUrl}/style/images/loading.gif`;

	constructor(
		private langSrv: LanguageService,
		private attachSrv: AttachmentService,
		private modalService: NgbModal,
		private patSrv: PatientService,
		private planSrv: PlanService,
		private toastSrv: ToastService,
		private sse: SseService
	) {
		super(langSrv);
	}

	ngOnInit() {
		this.patientGuidSubscription$ = this.patSrv.getCurrentPatient().pipe(filter(pat => !!pat)).subscribe(pat => {
			this.patientGuid = pat.patGuid;
			this.userGuid = pat.userGuid;
		});
		this.attachmentListSubscription$ = this.attachSrv.getAttachmentListStatus().subscribe(list => {
			this.attachmentList = list;
			this.refreshNumberOfSelected();
		});
		this.loadingListSubscription$ = this.attachSrv.getLoadingList().subscribe(list => {
			this.loadingList = list;
		});
		this.sse.subscribe(`OrthoNext.Image.Thumb`, (res: { image: string, error: boolean }) => {
			this.attachSrv.updateAttachmentList(this.patientGuid, this.userGuid);
			this.attachSrv.removeLoadingItem(res?.image);
		});
	}

	/**
	* Open confirmation modal before image editing.
	*/
	openEditWarning(imageId: string) {
		if (!this.modalService.hasOpenModals()) {
			const modalRef: NgbModalRef = this.modalService.open(ConfirmationComponent, {
				centered: true, backdrop: 'static'
			});
			(modalRef.componentInstance as ConfirmationComponent).config = {
				title: this.labels['ATTACHMENT_COMPONENT_EDIT_CONFIRMATION_TITLE'],
				message: this.labels['ATTACHMENT_COMPONENT_EDIT_CONFIRMATION_MESSAGE']
			};
			modalRef.result.then(() => this.openCategorizationModal(imageId)).catch(error => false);
		}
	}

	/**
	* Open image categorization modal to edit
	*/
	private openCategorizationModal(imageId: string) {
		if (!this.modalService.hasOpenModals()) {
			this.attachSrv.getImageDetail(imageId, this.userGuid).subscribe(img => {
				const list = [this.attachSrv.initEditImageList(img)];
				const modalRef: NgbModalRef = this.modalService.open(ImageGarageComponent, {
					centered: true, backdrop: 'static', windowClass: 'modal-image-garage', size: 'xl'
				});
				(modalRef.componentInstance as ImageGarageComponent).edit = true;
				(modalRef.componentInstance as ImageGarageComponent).imageList = list;
			});
		}
	}

	/**
	* Open image preview modal
	*/
	openPreview(imageId: string) {
		if (!this.modalService.hasOpenModals()) {
			this.attachSrv.getImageDetail(imageId, this.userGuid).subscribe(img => {
				const modalRef: NgbModalRef = this.modalService.open(AttachmentPreviewComponent, {
					centered: true, backdrop: 'static', windowClass: 'modal-image'
				});
				(modalRef.componentInstance as AttachmentPreviewComponent).img = img;
				(modalRef.componentInstance as AttachmentPreviewComponent).fileName = this.attachmentList.find(img => img.id == imageId)?.fileName;
			});
		}
	}

	/**
	* Open confirmation modal before images deletion
	*/
	openDeleteWarning() {
		if (!this.modalService.hasOpenModals() && !this.isDeleting) {
			const modalRef: NgbModalRef = this.modalService.open(ConfirmationComponent, {
				centered: true, backdrop: 'static'
			});
			(modalRef.componentInstance as ConfirmationComponent).config = {
				title: this.labels['ATTACHMENT_COMPONENT_DELETE_CONFIRMATION_TITLE'],
				message: this.labels['ATTACHMENT_COMPONENT_DELETE_CONFIRMATION_MESSAGE']
			};
			modalRef.result.then(() => this.delete()).catch(error => false);
		}
	}

	/**
	* Delete images
	*/
	private delete(): void {
		const imagesToDelete: Attachment[] = this.attachmentList.filter(x => x.selected);
		this.isDeleting = true;
		from(imagesToDelete).pipe(
			map(i => this.deletePlansByImage(i)),
			concatAll()
		).pipe(
			toArray(),
			finalize(() => this.isDeleting = false),
			map(list => list.filter(i => !!i)),
			filter(list => !!list?.length),
			switchMap(list => this.attachSrv.deleteImages(this.patientGuid, this.userGuid, list))
		).subscribe({
			next: () => this.toastSrv.showSuccess(this.labels['ATTACHMENT_COMPONENT_DELETE_IMAGES_MESSAGE']),
			error: () => this.toastSrv.showError(this.labels['ATTACHMENT_COMPONENT_DELETE_IMAGES_ERROR'])
		});
	}

	private deletePlansByImage(img: Attachment): Observable<Attachment> {
		return this.planSrv.deletePlansByImageId(img.id).pipe(
			map(res => this.handlePlanDeleteResponse(img, res))
		);
	}

	private handlePlanDeleteResponse(img: Attachment, res: ApiResponse<any>): Attachment {
		switch (res.statusCode) {
			case ApiResponseStatusCode.Success: return img;
			case ApiResponseStatusCode.PublishedRevokedCaseError: {
				this.toastSrv.showWarning(`${img.fileName} ${this.labels['ATTACHMENT_COMPONENT_DELETE_IMAGES_PUBLISHED_ERROR']}`);
				return null;
			};
			default: return null;
		}
	}

	/**
	* Update number of seleted images.
	*/
	refreshNumberOfSelected() {
		this.numberOfSelected = this.attachmentList.filter(x => x.selected).length;
		this.isFooterVisible = this.numberOfSelected > 0;
	}

	/**
	* Move image inside gallery or copy in other container by drag&drop.
	*/
	dropAttachment(event: CdkDragDrop<Attachment[]>) {
		if (event.previousContainer === event.container) {
			moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
		} else {
			copyArrayItem(event.previousContainer.data,
				event.container.data,
				event.previousIndex,
				event.currentIndex);
		}
	}



	ngOnDestroy() {
		this.patientGuidSubscription$?.unsubscribe();
		this.attachmentListSubscription$?.unsubscribe();
		this.loadingListSubscription$?.unsubscribe();
		this.attachSrv.resetLoadingList();
		this.sse.unsubscribe(`OrthoNext.Image.Thumb`);
	}

}
