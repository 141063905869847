import { AfterViewInit, Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LanguageService, SearchFields, SearchItems, SearchSortOptions } from '../../core';
import { ListService, UserService } from '../../services';
import { BaseComponent } from '../../shared';
import { SortEvent, SortableHeaderComponent } from '../sortable-header';


/**
* Plan table component
*/
@Component({
	selector: 'plan-table',
	templateUrl: './plan-table.component.html'
})
export class PlanTableComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {

	@ViewChildren(SortableHeaderComponent) headers: QueryList<SortableHeaderComponent>;
	activeColumn: string;

	collectionSize: number;
	planList: SearchItems[] = [];
	fields = SearchFields;
	statusClassEnum = PlanStatusClassEnum;
	isTlhexUser: boolean;

	isFiltersCollapsed: boolean;

	private _searchResultSubscription$: Subscription;
	private _collapsedSub: Subscription;

	constructor(
		private langSrv: LanguageService,
		private listSrv: ListService,
		private router: Router,
		private userSrv: UserService
	) {
		super(langSrv);
	}

	ngOnInit() {
		this.listSrv.resetSearchResult();
		this.initTable();
		this._searchResultSubscription$ = this.listSrv.getSearchResult().subscribe(list => {
			this.collectionSize = list ? list.count : 0;
			this.planList = list ? list.items : [];
		});
		this.userSrv.isTlhex().subscribe(val => this.isTlhexUser = val);
		this._collapsedSub = this.listSrv.isCollapsed.subscribe(val => this.isFiltersCollapsed = val);
	}

	ngAfterViewInit() {
		setTimeout(() => {
			const sortOrder: SearchSortOptions = this.listSrv.getSortOrder();
			if (!!sortOrder) {
				this.headers.find(header => header.name == sortOrder.name).direction = sortOrder.desc ? 'desc' : 'asc';
				this.activeColumn = sortOrder.name;
			}
		});
	}

	private initTable() {
		this.listSrv.resetPlanSearch();
	}

	/**
	* Update column sort order and sort list 
	*/
	onSort({ column, direction }: SortEvent) {
		// resetting other headers
		this.headers.forEach(header => header.name !== column ? header.reset() : null);
		// sorting
		this.activeColumn = direction === 'asc' || direction === 'desc' ? column : null;
		this.listSrv.sort(column, direction);
	}

	/**
	* Navigate to plan edit page
	*/
	editPlan(planGuid: string) {
		this.router.navigate(['/plan/edit'], { queryParams: { planGuid: planGuid } });
	}

	ngOnDestroy() {
		this._searchResultSubscription$?.unsubscribe();
		this._collapsedSub?.unsubscribe();
		this.listSrv.resetSearchResult();
	}

}

export enum PlanStatusClassEnum {
	Active = "active",
	AdjustedReport = "adjusted-report",
	Revoked = "revoked"
}
