import { length, mass } from 'units-converter/dist/es';

/**
* Convert string in float number
*/
export function filterFloat(value: string) {
	if (/^([-+])?(\d+([.]\d+)?)$/.test(value))
		return Number(value);
	return NaN;
};

/**
* Convert string in decimal number
*/
export function convertToDecimal(value: string): number {
	return /^([-+])?(\d+([.,]\d+)?)$/.test(value) ? parseFloat(value.replace(",", ".")) : null;
}

/**
* Trunc decimal number with two decimal digits
*/
export function truncDecimal(value: number): string {
	const decimal: string = value != null ? value.toString() : null;
	if (/^(\d+[.]\d+)$/.test(decimal)) {
		return decimal.substring(0, decimal.indexOf(".") + 2);
	} else if (/^(\d+,\d+)$/.test(decimal)) {
		return decimal.substring(0, decimal.indexOf(",") + 2);
	} else {
		return decimal;
	}
}

/**
* Convert decimal number in string
*/
export function printDecimal(value: number, digits: number): string {
	return new Intl.NumberFormat(navigator.language, { maximumFractionDigits: digits }).format(value);
}

/**
* Convert mass measure from a unit to other one
*/
export function convertMass(massValue: number, measureFrom: string, measureTo: string): number {
	const conv: number = massValue ? mass(massValue).from(measureFrom).to(measureTo).value : NaN;
	return isNaN(conv) ? null : Math.round(conv * 10) / 10;
}

/**
* Convert length measure from a unit to other one
*/
export function convertLength(lengthValue: number, measureFrom: string, measureTo: string): number {
	const conv: number = lengthValue ? length(lengthValue).from(measureFrom).to(measureTo).value : NaN;
	return isNaN(conv) ? null : Math.round(conv * 10) / 10;
}
