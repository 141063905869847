import { ContextBase, State, StateTransitionData } from '@ortho-next/nextray-core';
import { Actions, StateTypes } from './Context';

/**
 * State indicating that the application is loading and restoring data. At the end it will the state loaded.
 */
export class Loading extends State {
	public stateDescription = {
		isWorkflowRunning: 0,
		isPointsPositioningRunning: false,
	};

	constructor(context: ContextBase) {
		super(context, undefined, StateTypes.loading);
	}

	public initState(): void {
		// empty
	}

	protected handleChangeStateEvent<K extends keyof Actions>(event: K, args?: Actions[K]): StateTransitionData {
		switch (event) {
			case "setCalibrationRegisteringMode":
				this.stateDescription.isPointsPositioningRunning = args as boolean;
				return this.dispatchToMain(event, args);
			case "setModel":
			case "setCalibrationMode":
			case "setCalibrationCircleFromPoints":
			case "calibrate":
			case "startCrop":
			case "startRotate":
			case "confirmCrop":
			case "startCalibration":
			case "confirmRotate":
			case "setFlip":
			case "setRing":
			case "setRingCalibrationData":
				return this.dispatchToMain(event, args);
		}
		return undefined;
	}

}
