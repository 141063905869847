<div class="categorization-content">

	<div class="modal-header p-3">
		<h2 data-testid="SUBSYS2_GARAGE_TITLE_TXT" class="modal-title">
			{{ labels.IMAGE_GARAGE_COMPONENT_TITLE }}
		</h2>
		<button data-testid="SUBSYS2_GARAGE_CLOSE_BTN" type="button" class="close" data-dismiss="modal" aria-label="Close"
			style="outline: none" (click)="openConfirmModal()" ngbAutoFocus>
			<i class="material-icons">clear</i>
		</button>
	</div>

	<div class="row">
		<div class="col">

			<div class="box-suggestion box-suggestion-header">
				<p data-testid="SUBSYS2_CATEGORIZATION_INFO_TXT">{{ labels.IMAGE_CATEGORIZATION_COMPONENT_MESSAGE }}</p>
			</div>

		</div>
	</div>

	<div class="row">

		<div class="col-6">

			<image-categorization-form [form]="categorizationForm" (onChange)="updateCalibration()">
			</image-categorization-form>

		</div>

		<div class="col-6">

			<image-editing-tools *ngIf="!isCalibrationVisible"></image-editing-tools>
			<image-calibration *ngIf="isCalibrationVisible" [form]="calibrationForm" [disabled]="isCalibrationDisabled"
				[isFoot]="isFoot" [ringCalibData]="ringCalibData" [isRingsToolVisible]="isRingsToolVisible"
				(onSubmit)="updateScaleFactor()">
			</image-calibration>

		</div>
	</div>

</div>

<div class="categorization-footer">

	<div class="row">
		<div class="col-12">

			<div class="btn-container align-items-end">
				<button data-testid="SUBSYS2_CATEGORIZATION_CONFIRM_BTN" type="button" class="btn btn-primary"
					[disabled]="isConfirmBtnDisabled" (click)="confirm()">
					{{ counter }} &nbsp; {{ confirmBtn }}
				</button>
			</div>

		</div>
	</div>

</div>