import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ErrorMessageBindingStrategy, ReactiveFormConfig, RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { Subscription, finalize } from 'rxjs';
import { LanguageService, SURGEON, ToastService, US_CODE, convertToLocalDate } from '../../core';
import { ProfileForm } from '../../models';
import { UserService } from '../../services';
import { BaseComponent, ConfirmationComponent } from '../../shared';


/**
 * Edit account information component
 */
@Component({
	selector: 'profile-form',
	templateUrl: './profile-form.component.html',
	styles: []
})
export class ProfileFormComponent extends BaseComponent implements OnInit, OnDestroy {

	profileForm: RxFormGroup = <RxFormGroup>this.formBuilder.formGroup(ProfileForm);

	isSurgeon: boolean;
	isUnitedStates: boolean;

	private usStateSubscription: Subscription;
	allUsStates: string[] = [];

	maxDateOfBirth: Date = new Date(new Date().setFullYear(new Date().getFullYear() - 18));

	isSaving: boolean;

	constructor(
		private langSrv: LanguageService,
		private router: Router,
		private formBuilder: RxFormBuilder,
		private userSrv: UserService,
		private toastSrv: ToastService,
		private modalSrv: NgbModal
	) {
		super(langSrv);
	}

	ngOnInit() {
		this.initForm();
		this.initProfileForm();
	}

	private initForm(): void {
		ReactiveFormConfig.set({
			"validationMessage": this.langSrv.getValidationMessages(),
			"errorMessageBindingStrategy": ErrorMessageBindingStrategy.OnDirtyOrTouched
		});
	}

	private initProfileForm(): void {
		this.userSrv.getProfileForm().subscribe(form => {
			this.profileForm = <RxFormGroup>this.formBuilder.formGroup(ProfileForm, form);
			this.isSurgeon = form.roleInfo === SURGEON;
			this.isUnitedStates = form.country.code === US_CODE;
			if (this.isUnitedStates) this.initUsStatesList();
		});
	}

	private initUsStatesList(): void {
		this.usStateSubscription = this.userSrv.getUsStatesList().subscribe(list => this.allUsStates = list);
	}

	/**
	* Update date of birth string format
	* @param {Date} date Date selected from date picker
	*/
	updateDateOfBirth(date: Date): void {
		this.profileForm.controls.dateOfBirth.setValue(date);
		this.profileForm.controls.dateOfBirthFormat.setValue(convertToLocalDate(date));
		this.profileForm.controls.dateOfBirth.markAsTouched();
	}

	/**
	* Reset date of birth
	*/
	clearDateOfBirth(): void {
		this.profileForm.controls.dateOfBirth.setValue(null);
		this.profileForm.controls.dateOfBirth.markAsTouched();
		this.profileForm.controls.dateOfBirthFormat.setValue(null);
	}

	/**
	* Open modal to confirm editin profile
	*/
	openConfirmModal() {
		if (!this.modalSrv.hasOpenModals()) {
			const modalRef: NgbModalRef = this.modalSrv.open(ConfirmationComponent, {
				centered: true, backdrop: 'static'
			});
			(modalRef.componentInstance as ConfirmationComponent).config = {
				title: this.labels['PROFILE_FORM_COMPONENT_CONFIRMATION_TITLE'],
				message: this.labels['PROFILE_FORM_COMPONENT_CONFIRMATION_MESSAGE']
			};
			modalRef.result.then(() => this.submitForm()).catch(error => false);
		}
	}

	/**
	* Submit edit profile form
	*/
	submitForm(): void {
		this.isSaving = true;
		this.userSrv.editProfileForm(this.profileForm.value).pipe(
			finalize(() => this.isSaving = false)
		).subscribe({
			next: () => {
				this.toastSrv.showSuccess(this.labels['PROFILE_FORM_COMPONENT_SUCCESS_MESSAGE']);
				this.returnToPreviousPage();
			},
			error: () => this.toastSrv.showError(this.labels['PROFILE_FORM_COMPONENT_ERROR_MESSAGE'])
		});
	}

	/**
	* Navigate to previous page
	*/
	returnToPreviousPage(): void {
		history.back();
	}


	ngOnDestroy() {
		if (this.usStateSubscription) {
			this.usStateSubscription.unsubscribe();
		}
	}

}
