import { AnatomicalSideEnum, BoneTypeEnum, BoneViewEnum, ReferenceTypeEnum } from "./plan-request";

export interface ImageCategorization {
	/**
	 * Patient GUID the image belongs to.
	 */
	patientId: string;

	/**
	 * Anatomical bone side (Left, Right).
	 */
	boneSide: AnatomicalSideEnum;

	/**
	 * Bone view (AP, LT).
	 */
	boneView: BoneViewEnum;

	/**
	 * Bone type.
	 */
	boneType: BoneTypeEnum;

	/**
	 * Image scale factor.
	 */
	scaleFactor: number;

	/**
	 * Orientation.
	 */
	orientation: ImageOrientationEnum;

	/**
	 * Reference type.
	 */
	referenceType: ReferenceTypeEnum;

	/**
	 * Coordinates of the 3 points for image circle identifying.
	 */
	circleCoordinates: Point2D[];

	/**
	 * Selected ring local matrix.
	 */
	ringMatrix: number[];

	/**
	 * Calibration ring angle.
	 */
	angle: number;

	/**
	 * Calibration ring frontal rotation.
	 */
	frontal: number;

	/**
	* Calibration ring axial rotation.
	*/
	axial: number;

	/**
	 * Proximal Ring ID.
	 */
	ringProximalId: number;

	/**
	 * Distal Ring ID.
	 */
	ringDistalId: number;
}

export enum ImageOrientationEnum {
	Nothing = "Nothing",
	Horizontal = "Horizontal",
	Vertical = "Vertical",
	Rotate180 = "Rotate180"
}


/**
 * Simple 2D point representing a location in (x,y) coordinate space.
 */
export interface Point2D {
	x: number;
	y: number;
}