import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { LanguageService } from '../../core';
import { PlanFormService, UserService } from '../../services';
import { BaseComponent } from '../../shared';


/**
 * Plan creation/editing page component.
 */
@Component({
	selector: 'app-plan',
	templateUrl: './plan.component.html'
})
export class PlanComponent extends BaseComponent implements OnInit, OnDestroy {

	isEdit: boolean;
	patientId: string;
	isAnotherUser: boolean;

	private _usrSub$: Subscription;

	constructor(
		private langSrv: LanguageService,
		private route: ActivatedRoute,
		public planFormSrv: PlanFormService,
		private userSrv: UserService
	) {
		super(langSrv);
	}

	ngOnInit(): void {
		this.planFormSrv.initForm(this.route).subscribe(() => {
			this.isEdit = this.planFormSrv.isEdit;
			this.patientId = this.planFormSrv.patient?.number;
			if (this.planFormSrv.isEdit) {
				this._usrSub$ = this.userSrv.isCustomerCareOtherUser(this.planFormSrv.plan?.userGuid).subscribe(res => this.isAnotherUser = res);
			}
		});
	}

	get isSaveDisabled(): boolean { return this.planFormSrv.isSaveDisabled || this.isAnotherUser; }
	get isStartAnalysisDisabled(): boolean { return this.planFormSrv.isStartAnalysisDisabled || this.isAnotherUser; }


	ngOnDestroy(): void {
		this.planFormSrv.reset();
		this._usrSub$?.unsubscribe();
	}
}
