import { Routes } from '@angular/router';
import { ErrorsComponent, FallbackComponent } from './core';
import { AuthGuardWithForcedLogin } from '@ortho-next/auth-core';

export const APP_ROUTES: Routes = [
  { path: 'error', component: ErrorsComponent },
  {
    path: '', loadChildren: () => import(`./template/template.module`).then(m => m.TemplateModule)
  },
  { path: '**', component: FallbackComponent, canActivate: [AuthGuardWithForcedLogin] },
];
