import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IIntegrationRepository } from './Iintegration.repository';
import { ApiResponse, CloneCase, SendCase } from './models';

@Injectable()
export class IntegrationRepository implements IIntegrationRepository {

	constructor(private httpClient: HttpClient) { }

	sendCase(sendCase: SendCase): Observable<ApiResponse<void>> {
		return this.httpClient.post<ApiResponse<void>>(environment.domainIntegrationAPI + "/api/integration/sendcase", sendCase);
	}

	cloneSendCase(cloneCase: CloneCase): Observable<ApiResponse<void>> {
		return this.httpClient.post<ApiResponse<void>>(environment.domainIntegrationAPI + "/api/integration/clonesendcase", cloneCase);
	}

	createPostopPlan(caseId: string, userId: string): Observable<ApiResponse<string>> {
		return this.httpClient.post<ApiResponse<string>>(environment.domainIntegrationAPI + `/api/integration/PreOp2PostOp?caseId=${caseId}&userId=${userId}`, null);
	}

}
