import { Component, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { RxFormGroup } from '@rxweb/reactive-form-validators';
import { LanguageService, PlanTypeEnum, ReferenceTypeEnum, convertToLocalDate } from '../../core';
import { PlanForm } from '../../models';
import { PlanFormService } from '../../services';
import { BaseComponent } from '../../shared';


/**
 * Plan data section component.
 */
@Component({
	selector: 'plan-data',
	templateUrl: './plan-data.component.html'
})
export class PlanDataComponent extends BaseComponent implements OnInit {

	form: RxFormGroup;
	isCheckUp: boolean;

	readonly referenceTypeEnum = ReferenceTypeEnum;
	readonly planTypeEnum = PlanTypeEnum;

	constructor(
		private langSrv: LanguageService,
		public planFormSrv: PlanFormService
	) {
		super(langSrv);
	}

	ngOnInit(): void {
		this.planFormSrv.getForm().subscribe(form => {
			this.form = form;
			this.isCheckUp = this.planFormSrv.plan?.isCheckUp;
		});
	}

	get formValue(): PlanForm { return this.form?.value };

	get formCtrl(): { [key in keyof PlanForm]: AbstractControl } {
		return this.form?.controls as { [key in keyof PlanForm]: AbstractControl };
	}

	/**
	* Trim plan id input field
	*/
	trimPlanId(): void {
		if (this.formValue.planId) {
			this.formCtrl.planId.setValue(this.formValue.planId.trim())
		}
	}

	/**
	* Reset PlanIdNotUnique flag after validation
	*/
	resetPlanIdNotUnique() {
		if (this.formValue.planIdNotUnique) {
			this.formCtrl.planIdNotUnique.setValue(false);
			this.formCtrl.planId.setValue(this.formValue.planId);
		}
	}

	/**
	* Update Estimated Surgery Date string format
	*/
	updateSurgeryDate(date: Date): void {
		this.formCtrl.surgeryDate.setValue(date);
		this.formCtrl.surgeryDateFormat.setValue(convertToLocalDate(date));
		this.formCtrl.surgeryDate.markAsTouched();
	}

	/**
	* Reset Estimated Surgery Date
	*/
	clearSurgeryDate(): void {
		this.formCtrl.surgeryDate.setValue(null);
		this.formCtrl.surgeryDate.markAsTouched();
		this.formCtrl.surgeryDateFormat.setValue(null);
	}

	updateProductList(): void {
		this.planFormSrv.updateProductList();
	}
}
