import { AppModel as AppModelBase } from '@ortho-next/nextray-core';
import { Context } from './Context';
import { Vector3, Matrix4 } from '@ortho-next/three-base/three.js/build/three.module';

/**
 * Model used by angular to create bindings. Only three.js part can set this values.
 */
export class AppModel extends AppModelBase {
    protected override _context: Context;
    public calibrationEllipsePoints: Vector3[];
    public ringMatrix: Matrix4;
    public angle: number;
    public frontal: number;
    public axial: number;
}
