import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgbDate, NgbDateParserFormatter, NgbDateStruct, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { CustomDateParserFormatter } from './date-parser-formatter';
import { DateRange } from './date-range';


/**
 * Date range picker component
 */
@Component({
	selector: 'daterangepicker',
	templateUrl: './daterangepicker.component.html',
	providers: [
		{ provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
	],
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DaterangepickerComponent {

	@Input() placement: string;
	@Input() positionTarget: string | HTMLElement;

	@Input() set range(val: DateRange | null) {
		this.setRange(val);
	}

	@Output() rangeChanged = new EventEmitter<DateRange>();

	@ViewChild(NgbInputDatepicker, { static: true }) inputDatePicker: NgbInputDatepicker;

	hoveredDate: NgbDate | null = null;

	fromDate: NgbDate | null;
	toDate: NgbDate | null;

	MIN_DATE: NgbDateStruct = { year: 1900, month: 1, day: 1 }
	MAX_DATE: NgbDateStruct = { year: 2300, month: 1, day: 1 }

	/**
	* Toggle the calendar
	*/
	toggle(): void {
		if (!this.fromDate || !this.toDate) {
			this.fromDate = null;
			this.toDate = null;
		}
		this.inputDatePicker.toggle();
	}

	private setRange(val: DateRange | null): void {
		this.fromDate = val ? new NgbDate(val.from.getFullYear(), val.from.getMonth() + 1, val.from.getDate()) : null;
		this.toDate = val ? new NgbDate(val.to.getFullYear(), val.to.getMonth() + 1, val.to.getDate()) : null;
	}

	/**
	* Update and emit range selected
	*/
	onDateSelection(date: NgbDate) {
		if (!this.fromDate && !this.toDate) {
			this.fromDate = date;
		} else if (this.fromDate && !this.toDate && date && (date.after(this.fromDate) || date.equals(this.fromDate))) {
			this.toDate = date;
			this.emitDateRange();
		} else {
			this.toDate = null;
			this.fromDate = date;
			this.emitDateRange();
		}
	}

	private emitDateRange(): void {
		if (this.fromDate && this.toDate) {
			const from = new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day, 0, 0, 0, 0);
			const to = new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day, 23, 59, 59, 999);
			this.rangeChanged.emit({ from: from, to: to });
		} else {
			this.rangeChanged.emit(null);
		}
	}

	/**
	* Check if is selecting the second date
	*/
	isHovered(date: NgbDate) {
		return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
	}

	/**
	* Check if date is between range selected
	*/
	isInside(date: NgbDate) {
		return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
	}

	/**
	* Check if range selected is correct
	*/
	isRange(date: NgbDate) {
		return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
	}

}
