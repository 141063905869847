import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { BridgeResultMessagesBase, RingCalibrationData } from '@ortho-next/nextray-core';
import { RxFormGroup } from '@rxweb/reactive-form-validators';
import { LanguageService, ReferenceTypeEnum, RingSize, ToastService } from '../../core';
import { ImageCalibrationForm } from '../../models';
import { AttachmentService, CanvasService, TlhexService } from '../../services';
import { BaseComponent } from '../../shared';



/**
 * Component for image calibration on image garage modal.
 */
@Component({
	selector: 'image-calibration',
	templateUrl: './image-calibration.component.html'
})
export class ImageCalibrationComponent extends BaseComponent implements OnInit, OnDestroy {

	@Input() form: RxFormGroup;
	@Input() disabled: boolean;
	@Input() isRingsToolVisible: boolean;
	@Input() isFoot: boolean;
	@Input() ringCalibData: RingCalibrationData;
	@Output() onSubmit: EventEmitter<void> = new EventEmitter<void>();

	@ViewChild('calibrationInput') calibrationInput?: ElementRef<HTMLInputElement>;

	ringsList: RingSize[];
	initProxRing: RingSize;
	initDistRing: RingSize;
	referenceEnum = ReferenceTypeEnum;

	public readonly helpImgLine = "assets/images/gif/line.gif";
	public readonly helpImgCircle = "assets/images/gif/circle.gif";
	public readonly helpImgRing = "assets/images/gif/rings.gif";

	constructor(
		private langSrv: LanguageService,
		private canvasSrv: CanvasService,
		private toastSrv: ToastService,
		private tlhexSrv: TlhexService,
		private attachSrv: AttachmentService
	) {
		super(langSrv);
	}


	ngOnInit(): void {
		this.canvasSrv.addEventListener('onResult', this.calibToolInsertError);
		this.initForm();
	}

	ngAfterViewInit() {
		this.calibrationInput?.nativeElement.addEventListener('wheel', this.disableScroll);
		this.calibrationInput?.nativeElement.addEventListener('keydown', this.handleKeyPress);
	}

	private disableScroll = (event) => event.preventDefault();

	private handleKeyPress = (event) => {
		if (event.key == 'ArrowUp' || event.key == 'ArrowDown') {
			event.preventDefault();
		}
	};

	/**
	* Event listener for calibration tool insertion error.
	*/
	calibToolInsertError = (event) => {
		if (event.args == BridgeResultMessagesBase.calibrationCircleRadiusError) {
			this.toastSrv.showWarning(this.labels.IMAGE_CALIBRATION_COMPONENT_CIRCLE_RADIUS_ERROR);
		} else if (event.args == BridgeResultMessagesBase.calibrationEllipseRadiusError) {
			this.toastSrv.showWarning(this.labels.IMAGE_CALIBRATION_COMPONENT_ELLIPSE_RADIUS_ERROR);
		}
	};

	private initForm(): void {
		this.form.controls.calibrationTool.setValue(null);
		this.form.controls.calibrationValue.setValue(null);
		this.form.markAsUntouched();
	}

	/**
	 * Check if registering mode is active.
	 */
	get isPointsRegistering(): boolean {
		return this.canvasSrv.isPointsRegistering;
	}

	/**
	 * Prevent use of scientific notation characters.
	 */
	preventScientificNotation(event: KeyboardEvent | Event): void {
		if (event instanceof KeyboardEvent && ["e", "E", "+", "-"].includes(event.key)) {
			event.preventDefault();
		}
		const val = (event.target as HTMLInputElement).value;
		if (!val || val.includes("e") || val.includes("E") || val.includes("+") || val.includes("-")) {
			(event.target as HTMLInputElement).value = null;
		}
	}

	/**
	 * Insert line calibration tool.
	 */
	setLine(): void {
		this.canvasSrv.dispatch('startCalibration');
		this.canvasSrv.dispatch('calibByLine');
		this.reset();
	}

	/**
	 * Insert circle calibration tool.
	 */
	setCircle(): void {
		this.canvasSrv.dispatch('startCalibration');
		this.canvasSrv.dispatch('calibByCircle');
		this.reset();
	}

	/**
	 * Insert rings calibration tool.
	 */
	setRings(): void {
		this.canvasSrv.dispatch('startCalibration');
		this.canvasSrv.dispatch('calibByEllipse');
		this.canvasSrv.dispatch('setRingCalibrationData', { ...this.ringCalibData });
		this.reset();
		this.isRingsToolVisible && this.initRingData();
	}

	private reset(): void {
		this.form.controls.calibrationValue.setValue(null);
		this.form.controls.proximalRing.reset();
		this.form.controls.distalRing.reset();
		setTimeout(() => this.calibrationInput?.nativeElement.focus(), 1);
	}

	private initRingData(): void {
		if (!this.ringsList?.length) {
			this.tlhexSrv.getRingSizes().subscribe(list => {
				this.ringsList = list;
				this.initRings();
			});
		} else {
			this.initRings();
		}
	}

	private initRings(): void {
		const checkupData = this.attachSrv.checkUpData;
		if (checkupData.isCheckUp) {
			this.initProxRing = this.ringsList.find(r => r.id === checkupData.checkUpProxRingId);
			this.updateProxRing(this.initProxRing);
			this.initDistRing = this.ringsList.find(r => r.id === checkupData.checkUpDistRingId);
			this.updateDistRing(this.initDistRing);
		}
	}

	/**
	 * Set circle by input points.
	 */
	setCircleByPoints(points: { x: number; y: number; }[]): void {
		this.canvasSrv.dispatch('startCalibration');
		this.canvasSrv.dispatch('setCalibrationCircleFromPoints', points);
		this.form.controls.calibrationTool.setValue('circle');
		setTimeout(() => this.calibrationInput?.nativeElement.focus(), 1);
	}

	/**
	* Confirm calibration and close workflow.
	*/
	confirm(): void {
		if (this.form.controls.calibrationValue.valid && !this.isPointsRegistering) {
			const value = this.form.value.calibrationValue;
			this.canvasSrv.dispatch("calibrate", value);
			this.onSubmit.emit();
		}
	}

	//#region RINGS

	/**
	 * Check if a ring is loading.
	 */
	get isRingLoading(): boolean {
		return false;
	}

	/**
	 * Update proximal ring.
	 */
	updateProxRing(ring: RingSize): void {
		this.form.controls.proximalRing.setValue(ring);
		this.updateRingCalibrationValue();
	}

	/**
	 * Update distal ring.
	 */
	updateDistRing(ring: RingSize): void {
		this.form.controls.distalRing.setValue(ring);
		this.updateRingCalibrationValue();
	}

	private updateRingCalibrationValue(): void {
		const formValue = this.form.value as ImageCalibrationForm;
		const ref: ReferenceTypeEnum = this.ringCalibData.refType;
		const ring: RingSize = ref == this.referenceEnum.Proximal ? formValue.proximalRing : formValue.distalRing;
		const oppositeRing: RingSize = ref == this.referenceEnum.Proximal ? formValue.distalRing : formValue.proximalRing;
		if (ref && ring && oppositeRing) {
			const value = Number.parseInt(ring.description.split('mm')[0]);
			this.form.controls.calibrationValue.setValue(value);
			const oppositeRingType = oppositeRing.ringType;
			this.canvasSrv.dispatch("setRing", { ringModel: { ...ring, type: ref === ReferenceTypeEnum.Proximal ? "proximal" : "distal" }, oppositeRingType });
		} else {
			this.canvasSrv.dispatch("setRing");
		}

	}

	//#endregion

	ngOnDestroy() {
		this.calibrationInput?.nativeElement.removeEventListener('wheel', this.disableScroll);
		this.calibrationInput?.nativeElement.removeEventListener('keydown', this.handleKeyPress);
		this.canvasSrv.removeEventListener('onResult', this.calibToolInsertError);
	}

}
