import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../environments/environment';
import { StateTypes } from '../../../nextray/Core/Context';
import { LanguageService, SearchItems } from '../../core';
import { ReceivedService } from '../../services';
import { BaseComponent } from '../../shared';

@Component({
	selector: 'app-cloned-list-modal',
	templateUrl: './cloned-list-modal.component.html',
	styles: []
})
export class ClonedListModalComponent extends BaseComponent implements OnInit {

	originalPlanGuid: string;
	originalPlanId: string;
	clonedList: SearchItems[] = [];

	constructor(
		private langSrv: LanguageService,
		public activeModal: NgbActiveModal,
		private receivedSrv: ReceivedService,
		private router: Router
	) {
		super(langSrv);
	}

	ngOnInit() {
		this.receivedSrv.getClonedList(this.originalPlanGuid).subscribe(list => this.clonedList = list);
	}

	/**
	* Navigate to patient edit page
	*/
	editPatient(patientGuid: string): void {
		if (patientGuid) {
			this.activeModal.close();
			this.router.navigate(['/patient/edit'], { queryParams: { patientGuid: patientGuid } });
		}
	}

	/**
	* Navigate to plan edit page
	*/
	editPlan(planGuid: string): void {
		if (planGuid) {
			this.activeModal.close();
			this.router.navigate(['/plan/edit'], { queryParams: { planGuid: planGuid } });
		}
	}

	/**
	* Navigate to ray site
	*/
	navigateToApp(plan: SearchItems): void {
		if (plan && !!plan.caseProducts?.length) {
			let appRedirectUrl;
			if (!plan.caseAPImagesGuid && !plan.caseLTImagesGuid) {
				appRedirectUrl = environment.tlhexSite;
			} else if (plan.caseModelStatus === StateTypes.RPM) {
				appRedirectUrl = environment.fitboneSite;
			} else if (plan.caseModelStatus === StateTypes.tlhex) {
				appRedirectUrl = environment.tlhexRaySite;
			} else if (plan.caseModelStatus === StateTypes.tlhexSchedule) {
				appRedirectUrl = environment.tlhexSite;
			} else {
				appRedirectUrl = environment.nextraySite;
			}

			this.activeModal.close();
			window.location.href = appRedirectUrl + '?caseGuid=' + plan.planId;
		}
	}

}
