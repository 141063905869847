import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { PatientRepository } from '../core';
import { GalleryModule } from '../gallery';
import { PatientService } from '../services';
import { SharedModule } from '../shared';
import { PatientCreationComponent } from './patient-creation';
import { PatientFormComponent } from './patient-form';


@NgModule({
	declarations: [
		PatientCreationComponent,
		PatientFormComponent
	],
	exports: [
		PatientCreationComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		RxReactiveFormsModule,
		SharedModule,
		GalleryModule,
		NgbCollapseModule
	],
	providers: [
		PatientRepository,
		PatientService
	]
})
export class PatientModule { }
