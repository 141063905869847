import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiResponse, ApiResponseStatusCode, Patient, PatientRepository, PatientRequest, convertToDecimal } from '../core';
import { PatientForm } from '../models';


/**
 * This Service handles patient data.
 */
@Injectable()
export class PatientService {

	private _patient: BehaviorSubject<{ patGuid: string; userGuid: string }> = new BehaviorSubject(null);
	private _isPatientReadonly: BehaviorSubject<boolean> = new BehaviorSubject(null);

	constructor(private patRepo: PatientRepository) { }

	/**
	* Get current patient
	*/
	getCurrentPatient(): Observable<{ patGuid: string; userGuid: string }> {
		return this._patient.asObservable();
	}

	/**
	* Set current patient
	*/
	setCurrentPatient(patGuid: string, userGuid: string): void {
		this._patient.next({ patGuid: patGuid, userGuid: userGuid });
	}

	/**
	* Check if current patient is readonly
	*/
	isPatientReadonly(): Observable<boolean> {
		return this._isPatientReadonly.asObservable();
	}

	/**
	* Set current patient readonly
	*/
	setPatientReadonly(status: boolean): void {
		this._isPatientReadonly.next(status);
	}

	/**
	* Map patient request from patient form
	*/
	createPatientRequest(form: PatientForm): PatientRequest {
		return {
			number: form.patientId,
			initials: form.patientInitials,
			age: form.patientAge?.toString() ? Number(form.patientAge) : null,
			gender: form.patientGender,
			weight: form.patientWeightKg != null ? convertToDecimal(form.patientWeightKg.toString()) : null,
			heigth: form.patientHeightCm != null ? convertToDecimal(form.patientHeightCm.toString()) : null,
		};
	}

	/**
	* Map patient form from patient model
	*/
	createPatientForm(pat: Patient): PatientForm {
		return {
			patientId: pat.number,
			patientInitials: pat.initials,
			patientAge: pat.age,
			patientInfant: pat.age == 0,
			patientGender: pat.gender,
			patientWeightKg: pat.weightKG,
			patientHeightCm: pat.heigthCM,
		};
	}

	/**
	* Map patient model from patient form
	*/
	updatePatientModel(pat: Patient, patForm: PatientForm): Patient {
		const patRequest: PatientRequest = this.createPatientRequest(patForm);
		return {
			number: patRequest.number,
			initials: patRequest.initials,
			age: patRequest.age,
			gender: patRequest.gender,
			weightKG: patRequest.weight,
			heigthCM: patRequest.heigth,
			isReceived: pat.isReceived,
			notes: pat.notes,
			id: pat.id,
			userGuid: pat.userGuid
		};
	}

	/**
	* Get patient by patient guid
	 * @param {string} patGuid Patient guid
	*/
	getPatient(patGuid: string): Observable<Patient> {
		return this.patRepo.getPatient(patGuid).pipe(
			map(res => this.handlePatientResponse(res))
		);
	}

	/**
	* Save patient by patient request
	*/
	savePatient(request: PatientRequest): Observable<Patient> {
		return this.patRepo.savePatient(request).pipe(
			map(res => this.handlePatientResponse(res)),
		);
	}

	/**
	* Save patient by patient form
	*/
	savePatientForm(patForm: PatientForm): Observable<Patient> {
		const patRequest: PatientRequest = this.createPatientRequest(patForm);
		return this.savePatient(patRequest);
	}

	/**
	* Update patient with patient form data
	*/
	updatePatient(pat: Patient, patForm: PatientForm): Observable<any> {
		const patient: Patient = this.updatePatientModel(pat, patForm);
		return this.patRepo.updatePatient(patient).pipe(
			map(res => this.handlePatientResponse(res)),
		);
	}

	/**
	* Delete patient by patient guid
	*/
	deletePatient(patientGuid: string): Observable<any> {
		return this.patRepo.deletePatient(patientGuid);
	}

	private handlePatientResponse(response: ApiResponse<Patient>) {
		switch (response.statusCode) {
			case ApiResponseStatusCode.Success: return response.result;
			case ApiResponseStatusCode.PatientNumberNotUnique: throw new Error("PatientNumberNotUnique");
			default: throw new Error("Generic error");
		}
	}

}
