import { Pipe, PipeTransform } from '@angular/core';
import { convertToLocalDate } from '../../core';


/**
 * Pipe to convert Date in local format string
 */
@Pipe({
	name: 'convertDate'
})
export class ConvertDatePipe implements PipeTransform {

	transform(date: Date, UTCMode = true): string {
		return date ? convertToLocalDate(date, UTCMode) : null;
	}

}
