import { Component } from '@angular/core';
import { LanguageService } from '../../core/language.service';


/**
* Base component to share common features in every component
*/
@Component({
  selector: 'base-component',
  templateUrl: './base.component.html'
})
export class BaseComponent {

  private _labels: any = {};
  private _isMobile: boolean;

  constructor(private languageSrv: LanguageService) {
    this._labels = languageSrv.getLabels();
    this._isMobile = languageSrv.isMobileDevice;
  }

  /**
  * Get labels
  */
  get labels() {
    return this._labels;
  }

  /**
  * Get mobile device flag
  */
  get isMobile(): boolean {
    return this._isMobile;
  }

}
