import { Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../environments/environment';
import { LanguageService, ToastService } from '../../core';
import { AttachmentService } from '../../services';
import { BaseComponent } from '../../shared';
import { ImageGarageComponent } from '../image-garage';



/**
 * Component to upload image(s) by drag&drop, file selection and copy&paste
 */
@Component({
	selector: 'attachment-uploader',
	templateUrl: './attachment-uploader.component.html'
})
export class AttachmentUploaderComponent extends BaseComponent {

	@Input() patientGuid: string;
	@Input() isUploadDisabled: boolean;

	validMIMETypes: string[] = environment.validMIMETypes;
	isMsgVisible: boolean = true;

	@ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;

	/**
	* Drag over event listener.
	*/
	@HostListener('dragover', ['$event']) dragOver(event) {
		if (!!this.patientGuid && !this.isUploadDisabled) {
			event.preventDefault();
			event.stopPropagation();
			this.isMsgVisible = false;
		}
	}

	/**
	* Drag leave event listener.
	*/
	@HostListener('dragleave', ['$event']) dragLeave(event) {
		if (!!this.patientGuid && !this.isUploadDisabled) {
			event.preventDefault();
			event.stopPropagation();
			this.isMsgVisible = true;
		}
	}

	/**
	* Drop event listener.
	*/
	@HostListener('drop', ['$event']) drop(event) {
		if (!!this.patientGuid && !this.isUploadDisabled) {
			event.preventDefault();
			event.stopPropagation();
			const fileList: FileList = event.dataTransfer.files;
			this.isMsgVisible = true;
			const valid = this.checkImageValidation(fileList);
			valid && this.openImageGarageModal(fileList);
		}
	}

	/**
	* Paste event listener.
	*/
	@HostListener('paste', ['$event']) paste(event: ClipboardEvent): void {
		if (!!this.patientGuid && !this.isUploadDisabled) {
			const fileList: FileList = event.clipboardData.files;
			const valid = this.checkImageValidation(fileList);
			valid && this.openImageGarageModal(fileList);
		}
	}

	constructor(
		private langSrv: LanguageService,
		private attachSrv: AttachmentService,
		private modalSrv: NgbModal,
		private toastSrv: ToastService
	) {
		super(langSrv);
	}

	/**
	* Upload images from file system.
	*/
	chooseFile(event) {
		if (!!this.patientGuid && !this.isUploadDisabled && event.target.files.length > 0) {
			const fileList: FileList = event.target.files;
			const valid = this.checkImageValidation(fileList);
			valid && this.openImageGarageModal(fileList);
		}
		this.fileInput.nativeElement.value = '';
	}

	/**
	 * Open modal to handle image categorization.
	 */
	private openImageGarageModal(fileList: FileList): void {
		const list = this.attachSrv.initUploadImageList(this.patientGuid, fileList);
		const modalRef: NgbModalRef = this.modalSrv.open(ImageGarageComponent, {
			centered: true, backdrop: 'static', windowClass: 'modal-image-garage font-size-bigger', size: 'xl'
		});
		(modalRef.componentInstance as ImageGarageComponent).imageList = list;
	}

	/**
	 * Check image MIME type and size.
	 */
	private checkImageValidation(fileList: FileList): boolean {
		let check = true;
		const maxImageSize: number = environment.maxAttachmentSize;
		const validMIMETypes: string[] = environment.validMIMETypes || [];
		for (let i = 0; i < fileList?.length; i++) {
			const img = fileList.item(i);
			if (img.size > maxImageSize) {
				this.toastSrv.showWarning(`${this.labels['ATTACHMENT_UPLOADER_COMPONENT_UPLOAD_SIZE_ERROR']} (${img.name})`);
				check = false;
			}
			if (!validMIMETypes.includes(img.type)) {
				this.toastSrv.showWarning(`${this.labels['ATTACHMENT_UPLOADER_COMPONENT_UPLOAD_TYPE_ERROR']} (${img.name})`);
				check = false;
			}
		}
		return check;
	}

}
