<input hidden
       ngbDatepicker
       [placement]="placement"
       [positionTarget]="positionTarget"
       (dateSelect)="onDateSelection($event)"
       [displayMonths]="1"
       outsideDays="hidden"
       [minDate]="MIN_DATE"
       [maxDate]="MAX_DATE"
       [startDate]="ngbDate">


