<!-- Modal -->
<div class="modal-content">
	<div class="modal-header">
		<h5 data-testid="SUBSYS2_CLONEDLIST_TITLE_TXT" class="modal-title">
			{{ labels.CLONED_LIST_MODAL_COMPONENT_TITLE }} &nbsp; {{ originalPlanId | replaceLongLeg }}
		</h5>
		<button data-testid="SUBSYS2_CLONEDLIST_CLOSE_BTN" type="button" class="close" data-dismiss="modal"
			aria-label="Close" style="outline: none" (click)="activeModal.close()" ngbAutoFocus>
			<i class="material-icons">clear</i>
		</button>
	</div>
	<div class="modal-body">

		<div class="row" *ngIf="!clonedList || clonedList.length === 0; else clonedTable">
			<div class="col-12">
				<span data-testid="SUBSYS2_CLONEDLIST_EMPTY_TXT">
					{{ labels.CLONED_LIST_MODAL_COMPONENT_EMPTY_LIST_MESSAGE }}
				</span>
			</div>
		</div>

		<ng-template #clonedTable>

			<div class="row">
				<div class="col-5">
					<label data-testid="SUBSYS2_CLONEDLIST_HEADER_PATID">
						{{ labels.CLONED_LIST_MODAL_COMPONENT_PATIENT_ID }}
					</label>
				</div>
				<div class="col-5">
					<label data-testid="SUBSYS2_CLONEDLIST_HEADER_PLANID">
						{{ labels.CLONED_LIST_MODAL_COMPONENT_PLAN_ID }}
					</label>
				</div>
				<div class="col-2">
				</div>
			</div>

			<hr />

			<div class="list">

				<ng-container *ngFor="let item of clonedList">

					<div class="row">
						<div class="col-5">
							<a data-testid="SUBSYS2_CLONEDLIST_PATID_BTN" href="#" onclick="return false;"
								(click)="editPatient(item.patientId)" title="{{ labels.CLONED_LIST_MODAL_COMPONENT_EDIT_PATIENT }}">
								{{ item.patientNumber }}
							</a>
						</div>
						<div class="col-5" title="{{ labels.CLONED_LIST_MODAL_COMPONENT_EDIT_PLAN }}">
							<a data-testid="SUBSYS2_CLONEDLIST_PLANID_BTN" href="#" onclick="return false;"
								(click)="editPlan(item.planId)">
								{{ item.planNumber }}
							</a>
						</div>
						<div class="col-2 action">
							<i data-testid="SUBSYS2_CLONEDLIST_ANALYSIS_BTN" class="material-icons"
								*ngIf="!!item.caseProducts?.length" title="{{ labels.CLONED_LIST_MODAL_COMPONENT_ANALYSIS }}"
								(click)="navigateToApp(item)">square_foot</i>
						</div>
					</div>

					<hr />

				</ng-container>

			</div>

		</ng-template>

	</div>
	<div class="modal-footer" *ngIf="clonedList && clonedList.length > -1">
		<span class="result">{{ clonedList.length }} {{ labels.CLONED_LIST_MODAL_COMPONENT_RESULTS }}</span>
	</div>
</div>