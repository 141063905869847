import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LanguageService } from '../../core';
import { PatientService } from '../../services';
import { BaseComponent } from '../../shared';


/**
* Patient create/edit page component 
*/
@Component({
	selector: 'patient-creation',
	templateUrl: './patient-creation.component.html'
})
export class PatientCreationComponent extends BaseComponent implements OnInit, OnDestroy {

	isEdit: boolean;
	isPatReadonly: boolean;

	private _patSub$: Subscription;

	constructor(
		private langSrv: LanguageService,
		private router: Router,
		public route: ActivatedRoute,
		private patSrv: PatientService
	) {
		super(langSrv);
	}

	ngOnInit() {
		this.route.queryParamMap.subscribe(params => this.isEdit = params.get('patientGuid') != null);
		this._patSub$ = this.patSrv.isPatientReadonly().subscribe(res => this.isPatReadonly = res);
	}

	/**
	* Navigate to patient list page
	*/
	returnToPreviousPage() {
		this.router.navigateByUrl('/list');
	}


	ngOnDestroy(): void {
		this._patSub$?.unsubscribe();
	}
}
