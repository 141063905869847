/**
* Model that represents the researching options
*/
export interface SearchOptions {
	/**
	* Page number zero base
	*/
	page: number;

	/**
	* Top items per page to return
	*/
	top: number;

	/**
	* Filter list
	*/
	filters?: FilterOption[];

	/**
	* Sort criterion
	*/
	sortOrder?: SearchSortOptions;

	/**
	* Full text search
	*/
	fullText?: string;

	/**
	* Flag that indicates whether to return only Patients or Cases
	*/
	patientOnly: boolean;

	/**
	* Flag that indicates whether to return deleted patients or plans also (only customer care)
	*/
	deleted?: boolean;
}

export interface SearchSortOptions {
	/**
	* Field name to order
	*/
	name: string;

	/**
	* Order mode
	*/
	desc: boolean;
}

export enum ExpressionOperator {
	/**
	* Equal
	*/
	Eq,

	/**
	* Not Equal
	*/
	Ne,

	/**
	* Less Than
	*/
	Lt,

	/**
	* Greater Than
	*/
	Gt,

	/**
	* Less Equal
	*/
	Le,

	/**
	* Greater Equal
	*/
	Ge,

	/**
	* In Values
	*/
	In,

	/**
	* String contains specified substring
	*/
	Contains,
}

export interface FilterOptionBase {
	/**
	* Field name to search
	*/
	name: string;

	/**
	* Value to compare
	*/
	value: any;
}


export interface FilterOption extends FilterOptionBase {
	/**
	* Expression operator
	*/
	operator: ExpressionOperator;
}

/**
* Fields of search view
*/
export enum SearchFields {
	patientId = 'PatientId',
	userGuid = 'UserGuid',
	patGender = 'Gender',
	patNumber = 'PatientNumber',
	patInitials = 'PatientInitials',
	patIsReceived = 'PatIsReceived',
	patCreatedDate = 'PatCreatedDate',
	patModifiedDate = 'PatModifiedDate',
	patNotes = 'Patnotes',
	patAge = 'Age',
	patHeight = 'HeigthCM',
	patWeight = 'WeightKG',
	patGuid = 'PatientGuid',
	patTimestamp = 'PatTimestamp',
	planId = 'CaseId',
	boneType = 'BonesType',
	planVersion = 'CasesVersion',
	planNumber = 'CaseNumber',
	planName = 'CaseName',
	planCreatedDate = 'CaseCreatedDate',
	planModifiedDate = 'CaseModifiedDate',
	planUserGuid = 'Caseuserguid',
	planType = 'CasesType',
	planGuid = 'CaseGuid',
	referenceType = 'ReferenceType',
	side = 'Side',
	isPostOperative = "IsPostOperative",
	apImagesGuidao = 'APImagesGuid',
	ltImagesGuid = 'LTImagesGuid',
	planTimestamp = 'CaseTimestamp',
	planNotes = 'CaseNotes',
	planSurgeryDate = 'SurgeryDate',
	planIsReceived = 'CaseIsReceived',
	planFrameId = 'FrameID',
	planDaysLeft = 'CaseDaysLeft',
	originalCaseGuid = 'OriginalCaseGuid',
	originalUserGuid = 'OriginalUserGuid',
	originalUserName = 'OriginalUserName'
}
