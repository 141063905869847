import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { AiRepository, ImageRepository } from '../core';
import { AiService, AttachmentService, CanvasService } from '../services';
import { SharedModule } from '../shared';
import { AttachmentComponent } from './attachment';
import { AttachmentPreviewComponent } from './attachment-preview';
import { AttachmentUploaderComponent } from './attachment-uploader';
import { ImageCalibrationComponent } from './image-calibration';
import { ImageCategorizationComponent } from './image-categorization';
import { ImageCategorizationFormComponent } from './image-categorization-form';
import { ImageEditingToolsComponent } from './image-editing-tools';
import { ImageGarageComponent } from './image-garage';



@NgModule({
	declarations: [
		AttachmentComponent,
		AttachmentPreviewComponent,
		AttachmentUploaderComponent,
		ImageGarageComponent,
		ImageCategorizationComponent,
		ImageCategorizationFormComponent,
		ImageCalibrationComponent,
		ImageEditingToolsComponent
	],
	exports: [
		AttachmentComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		RxReactiveFormsModule,
		DragDropModule,
		SharedModule
	],
	providers: [
		ImageRepository,
		AttachmentService,
		CanvasService,
		AiRepository,
		AiService
	]
})
export class GalleryModule { }
