<!-- First row wrap Breadcrumb + Title + Alert -->
<div class="row row-header">
	<div class="col-12 col-xl-3 d-block">

		<!-- Breadcrumbs -->
		<div class="breadcrumbs">
			<a data-testid="SUBSYS2_PLANFORM_BACK_BTN" href="#" onclick="return false;"
				(click)="planFormSrv.returnToPatientList()">
				<i class="material-icons">arrow_back</i>
				{{ labels.PLAN_CREATION_COMPONENT_PREVIOUS_PAGE }}
			</a>
		</div>

		<!-- Page title -->
		<h1 data-testid="SUBSYS2_PLANFORM_TITLE_TXT" class="mb-4">
			{{ isEdit ? labels.PLAN_CREATION_COMPONENT_TITLE_EDIT : labels.PLAN_CREATION_COMPONENT_TITLE_CREATE }} </h1>
		<h3 data-testid="SUBSYS2_PLANFORM_PATIENT_TXT" class="wrapper-title-span">
			{{ labels.PLAN_CREATION_COMPONENT_TITLE_PATIENT_ID }}:
			<span>
				<a data-testid="SUBSYS2_PLANFORM_PATIENT_BTN" href="#" onclick="return false;"
					(click)="planFormSrv.returnToPatient()">
					{{ patientId }}
				</a>
			</span>
		</h3>

	</div>

	<div class="col-12 col-xl-9">
		<div class="panel mb-0 p-0 bg-transparent">
			<div class="panel alert flex-grow-0 mb-0">
				<div class="state-icon"><i class="material-icons md-18">report_problem</i></div>
				<p data-testid="SUBSYS2_PLANFORM_LIABILITY_TXT">{{ labels.PLAN_FORM_COMPONENT_LIABILITY_MESSAGE }}</p>
			</div>
		</div>
	</div>
</div>

<!-- Second row wrap all contents and columns -->
<div class="row row-contents justify-content-center">

	<div class="col-xl-3 mb-5">
		<plan-data></plan-data>
	</div>

	<div class="col-xl-6 mb-5">
		<plan-bone></plan-bone>
	</div>

	<div class="col-xl-3 mb-5">
		<plan-image dropApId="apImage" dropLateralId="lateralImage"></plan-image>
	</div>

	<div class="bottom-bar-save bottom-bar-save-plan">
		<button data-testid="SUBSYS2_PLANFORM_CANCEL_BTN" type="button" class="btn btn-secondary"
			(click)="planFormSrv.openConfirmModal()">
			{{ labels.PLAN_FORM_COMPONENT_ANATOMICAL_CANCEL_BUTTON }}
		</button>

		<button data-testid="SUBSYS2_PLANFORM_SAVE_BTN" type="button" class="btn btn-primary" [disabled]="isSaveDisabled"
			(click)="planFormSrv.openResetAnalysisModal(false)">
			{{ labels.PLAN_FORM_COMPONENT_ANATOMICAL_SAVE_BUTTON }}
		</button>
		<button data-testid="SUBSYS2_PLANFORM_START_BTN" type="button" class="btn btn-primary ml-3"
			[disabled]="isStartAnalysisDisabled" (click)="planFormSrv.openResetAnalysisModal(true)">
			{{ labels.PLAN_FORM_COMPONENT_ANATOMICAL_SAVE_AND_START_BUTTON }}
		</button>
	</div>

</div>