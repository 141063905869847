import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { RxFormGroup } from '@rxweb/reactive-form-validators';
import { Subscription } from 'rxjs';
import { LanguageService } from '../../core';
import { AnatomicalSiteEnum, PlanForm } from '../../models';
import { PlanFormService, UserService, getAnatomicalSide, getBoneType } from '../../services';
import { BaseComponent } from '../../shared';


/**
 * Plan bone section component.
 */
@Component({
	selector: 'plan-bone',
	templateUrl: './plan-bone.component.html'
})
export class PlanBoneComponent extends BaseComponent implements OnInit, OnDestroy {

	form: RxFormGroup;
	isCheckUp: boolean;
	isSkeletonLoading: boolean;
	isAnotherUser: boolean;

	private _resizeEvt: NodeJS.Timeout;
	private _usrSub$: Subscription;

	constructor(
		private langSrv: LanguageService,
		private planFormSrv: PlanFormService,
		private userSrv: UserService
	) {
		super(langSrv);
	}

	ngOnInit(): void {
		this.planFormSrv.getForm().subscribe(form => {
			this.form = form;
			this.isCheckUp = this.planFormSrv.plan?.isCheckUp;
			if (this.planFormSrv.isEdit) {
				this._usrSub$ = this.userSrv.isCustomerCareOtherUser(this.planFormSrv.plan?.userGuid).subscribe(res => this.isAnotherUser = res);
			}
		});
		window.addEventListener('resize', this.skeletonLoadingEvent);
	}

	private skeletonLoadingEvent = () => {
		clearTimeout(this._resizeEvt);
		this.isSkeletonLoading = true;
		this._resizeEvt = setTimeout(() => this.isSkeletonLoading = false, 200);
	};

	get formValue(): PlanForm { return this.form?.value };

	get formCtrl(): { [key in keyof PlanForm]: AbstractControl } {
		return this.form?.controls as { [key in keyof PlanForm]: AbstractControl };
	}

	get isBoxDisabled(): boolean { return this.planFormSrv.isBoneBoxDisabled; }

	get isImageUpload(): boolean { return this.formValue.apImageGuid != null || this.formValue.ltImageGuid != null; }

	/**
	* Update plan form according anatomical site selection
	*/
	updateAnatomicalSite(site: AnatomicalSiteEnum): void {
		if (!this.isCheckUp) {
			this.formCtrl.anatomicalSite.setValue(site);
			this.formCtrl.side.setValue(getAnatomicalSide(site));
			this.formCtrl.boneType.setValue(getBoneType(site));
			this.planFormSrv.updateProductList();
		}
	}


	ngOnDestroy(): void {
		window.removeEventListener('resize', this.skeletonLoadingEvent);
		this._usrSub$?.unsubscribe();
	}
}
